import { useTranslation } from "react-i18next";

export const DataZone = () => {

    const { t } = useTranslation();
    
    const columns = [
        {
            title: t('Zone.zone'),
            dataIndex: 'name',
            field: t('Zone.zone'),
            key:0,
            flex:1,
            renderCell:((record:any)=>{
                return(
                    <p>{record?.row?.name}</p>
                )
            }),
            sorter: (a: any, b: any) => {
                let fa = a.name.toLowerCase();
                let fb = b.name.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
            },
            sortDirections: ['descend', 'ascend', 'descend',],
            align: "left",
            
        },
        {
            title: t('Zone.ipStart'),
            dataIndex: 'ipStart',
            field:t('Zone.ipStart'),
            key:1,
            flex:1,
            renderCell:((record:any)=>{
                return(
                    <p>{record?.row?.ipStart}</p>
                )
            }),
            sorter: (a: any, b: any) => {
                let fa = a.ipStart.toLowerCase();
                let fb = b.ipStart.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
            },
            sortDirections: ['descend', 'ascend', 'descend',],
            align: "left",
        },
        {
            title: t('Zone.ipEnd'),
            dataIndex: 'ipEnd',
            field:t('Zone.ipEnd'),
            key:2,
            flex:1,
            renderCell:((record:any)=>{
                return(
                    <p>{record?.row?.ipEnd}</p>
                )
            }),

            sorter: (a: any, b: any) => {
                let fa = a.ipEnd.toLowerCase();
                let fb = b.ipEnd.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
            },
            sortDirections: ['descend', 'ascend', 'descend',],
            align: "left",
        },
        {
            title: t('Zone.rmbox'),
            dataIndex: 'rmbox',
            
            className: "contentcolumn",
            field: t('Zone.rmbox'),
            key:3,
            flex:1,
            renderCell:(record:any)=>(       
                    <p>{record?.row?.rmbox?.name}</p>
                
            ),
            sorter: (a: any, b: any) => {
                let fa = a.rmbox?.name.toLowerCase();
                let fb = b.rmbox?.name.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
            },
            sortDirections: ['descend', 'ascend', 'descend',],
            align: "left",
        },
        {
            title: t('Zone.bandwidthLimit'),
            dataIndex: 'bandwidthLimit',
            field: t('Zone.bandwidthLimit'),
            key:4,
            flex:1,
            renderCell:(record:any)=>{
                return(<p>{record?.row?.bandwidthLimit}</p>)
                
            },
            sorter: (a: any, b: any) => {
                let fa = a.bandwidthLimit
                let fb = b.bandwidthLimit

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
            },
            sortDirections: ['descend', 'ascend', 'descend',],
            align: "center",
        },
        {
            title: t('Zone.zonelimit'),
            dataIndex: 'zonelimit',
            field: t('Zone.zonelimit'),
            key:5,
            flex:1,
            renderCell:((record:any)=>{
                return(
                    <p>{record?.row?.zonelimit}</p>
                )
            }),
            sorter: (a: any, b: any) => {
                let fa = a.zonelimit
                let fb = b.zonelimit
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
            },
            sortDirections: ['descend', 'ascend', 'descend',],
            align: "center",
        },


    ];

    const listrmbox = [{
        id: 1,
        name: "test1"
    },
    {
        id: 2,
        name: "test2"
    },
    {
        id: 3,
        name: "testing"
    }]

    return ({
        columns,
        listrmbox

    })

}
