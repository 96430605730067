import { useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import {
    setAddInterventionActions, setFilterInterventionActions, setshowDivsConditionsIntervention,
    setDataInterventionActions,
} from "../store/interventionActions"
import { Form, message } from "antd";
import { useAppSelector } from "../../../Redux/hooks";
import {
    GetData,
    PostData,

    DeleteData,
} from "../../../APIcalls";
import moment from 'moment';
import { useTranslation } from "react-i18next";
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
const Hooks = () => {

    const { t } = useTranslation();

    const dispatch = useDispatch()
    const [formAddIntervention] = Form.useForm()

    // use Selector redux
    const values = useAppSelector((state) => state.InterventionReducer.addInterventionConstraintsData)
    const Interventioncondition = useAppSelector((state) => state.InterventionReducer.showDivsConditionsIntervention)
    const RmBoxSelected = useAppSelector((state) => state.RmboxReducer.showDivsConditionsRmbox.elementSelected)
    const [currentPage]=useState(1)
    const [pageSize]=useState(10)
    const [searchWord,setSearchWord]=useState('')
    useEffect(() => {
        formAddIntervention.setFieldsValue({
            id: values.id,
            remarque: values.remarque,
            receptionDate: values.receptionDate,
            sendDate: values.sendDate,
            oldMac: values.oldMac,
            firstStartedDate: values.firstStartedDate,
            created_at: values.created_at,
            newMac: values.newMac,
            rmbox_name: values.rmbox_name,
            rmbox: values.rmbox,
            rmbox_id: values.rmbox_id,
            rmbox_ip: values.rmbox_ip,
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])





    /////********* show add edit Intervention ****************/////
    const handleAddIntervention = () => {
        formAddIntervention.setFieldsValue({
            id: "",
            cle: "",
            value: "",
            created_at: "",
            updated_at: "",

        })

        dispatch(
            setshowDivsConditionsIntervention({
                showDivsConditionsName: "addIntervention",
                showDivsConditionsValue: true,
            })
        );

    }



    const onResetIntervention = async () => {
        /**edit item list */
        dispatch(
            setshowDivsConditionsIntervention({
                showDivsConditionsName: "editIntervention",
                showDivsConditionsValue: false,
            })
        );
        dispatch(
            setshowDivsConditionsIntervention({
                showDivsConditionsName: "addIntervention",
                showDivsConditionsValue: false,
            })
        );
        await dispatch(setAddInterventionActions({
            addInterventionConstraintsDataNameChange: "key",
            addInterventionConstraintsDataValueChange: ""
        }));
        await dispatch(setAddInterventionActions({
            addInterventionConstraintsDataNameChange: "value",
            addInterventionConstraintsDataValueChange: ""
        }));
        await dispatch(setAddInterventionActions({
            addInterventionConstraintsDataNameChange: "identifiant",
            addInterventionConstraintsDataValueChange: ""
        }));
        await dispatch(setAddInterventionActions({
            addInterventionConstraintsDataNameChange: "createddate",
            addInterventionConstraintsDataValueChange: ""
        }));
        await dispatch(setAddInterventionActions({
            addInterventionConstraintsDataNameChange: "publishdate",
            addInterventionConstraintsDataValueChange: ""
        }));




    }

    ////****************** function Filter *****************///////
    //*********************** search header **************///////
    const handleSearchRow = async (event: any) => {
            dispatch(setFilterInterventionActions({
                FilterInterventionNameChange: "search",
                FilterInterventionValueChange: event
            }));
        
    }


    /////**************** Form Add Intervention *******************/////////
    const onFinishAddIntervention = (values: any) => {
    
        if (Interventioncondition.addIntervention === true) {
            AddData(values)

        } else if (Interventioncondition.editIntervention === true) {

            ModifData(values, Interventioncondition.elementSelected)
        }

    }
    const onFinishFailedAddIntervention = (errorInfo: any) => {
       
    }
    const onValueFormUserchangeIntervention = async (val: any) => {
      
        if (val.title) {
            await dispatch(setAddInterventionActions({
                addInterventionConstraintsDataNameChange: "key",
                addInterventionConstraintsDataValueChange: val.key
            }));
        } else if (val.descIntervention) {
            await dispatch(setAddInterventionActions({
                addInterventionConstraintsDataNameChange: "value",
                addInterventionConstraintsDataValueChange: val.value
            }));
        }
    }
    const disabledsubmitbutton= async(type:any) =>{
    
        
        
        if(type === "disabled"){
            await dispatch(setshowDivsConditionsIntervention({
                showDivsConditionsName: "disablesubmitform",
                showDivsConditionsValue: false
            }));
        }else if(type === "notdisabled"){
            await dispatch(setshowDivsConditionsIntervention({
                showDivsConditionsName: "disablesubmitform",
                showDivsConditionsValue: true
            }));
        }
        
    }
    ////////****** Edit Intervention ***************////
    const handleEditIntervention = async (record: any) => {
  
        await dispatch(setshowDivsConditionsIntervention({
            showDivsConditionsName: "elementSelected",
            showDivsConditionsValue: record?.id
        }));

        await dispatch(setAddInterventionActions({
            addInterventionConstraintsDataNameChange: "id",
            addInterventionConstraintsDataValueChange: record?.id
        }));

        await dispatch(setAddInterventionActions({
            addInterventionConstraintsDataNameChange: "remarque",
            addInterventionConstraintsDataValueChange: record?.row?.remarque
        }));
        await dispatch(setAddInterventionActions({
            addInterventionConstraintsDataNameChange: "receptionDate",
            addInterventionConstraintsDataValueChange: moment(record?.row?.receptionDate, dateFormatList[0])
        }));
        await dispatch(setAddInterventionActions({
            addInterventionConstraintsDataNameChange: "sendDate",
            addInterventionConstraintsDataValueChange: moment(record?.row?.sendDate, dateFormatList[0])
        }));
        await dispatch(setAddInterventionActions({
            addInterventionConstraintsDataNameChange: "oldMac",
            addInterventionConstraintsDataValueChange: record?.row?.oldMac
        }));
        await dispatch(setAddInterventionActions({
            addInterventionConstraintsDataNameChange: "firstStartedDate",
            addInterventionConstraintsDataValueChange: moment(record?.row?.firstStartedDate, dateFormatList[0])
        }));
        await dispatch(setAddInterventionActions({
            addInterventionConstraintsDataNameChange: "created_at",
            addInterventionConstraintsDataValueChange: record?.row?.created_at
        }));
        await dispatch(setAddInterventionActions({
            addInterventionConstraintsDataNameChange: "newMac",
            addInterventionConstraintsDataValueChange: record?.row?.newMac
        }));
        await dispatch(setAddInterventionActions({
            addInterventionConstraintsDataNameChange: "rmbox_name",
            addInterventionConstraintsDataValueChange: record?.row?.rmbox_name
        }));
        await dispatch(setAddInterventionActions({
            addInterventionConstraintsDataNameChange: "rmbox_id",
            addInterventionConstraintsDataValueChange: record?.row?.rmbox_id
        }));
        await dispatch(setAddInterventionActions({
            addInterventionConstraintsDataNameChange: "rmbox_ip",
            addInterventionConstraintsDataValueChange: record?.row?.rmbox_ip
        }));

        await dispatch(
            setshowDivsConditionsIntervention({
                showDivsConditionsName: "editIntervention",
                showDivsConditionsValue: true,
            })
        );
        await formAddIntervention.setFieldsValue({
            id: record.id,
            cle: record.cle,
            value: record.value,
            created_at: record.created_at,
            updated_at: record.updated_at,

        })
        // try {
        //     const res = await GetData(`/param/details/${record.id}/param`);
        //     if (res.status === 200) {
        //         console.log("data row", res.data)
        //         console.log(res.data.id)

               

        //     }

        // } catch (e) {
        //     // dispatch({
        //     //   type: ErrorActions.Message_ERROR,
        //     //   payload: {
        //     //     msg: e.response.data.message,
        //     //   },
        //     // });
        // }
    }
    /********************************** */
   
    const handelSearch = (word: string, tab: any) => {
        if (word.trim() === "") return tab;

        // eslint-disable-next-line array-callback-return
        const res = tab.filter((obj: any) => {
            if (obj?.remarque?.toLowerCase().includes(word.toLowerCase()) ||
            obj?.receptionDate?.toLowerCase().includes(word.toLowerCase()) ||
            obj?.sendDate?.toLowerCase().includes(word.toLowerCase()) ||
            obj?.newMac?.toLowerCase().includes(word.toLowerCase()) ||
            obj?.sendDate?.toLowerCase().includes(word.toLowerCase()) 
            ) return obj;
        });

        return res;
    };
    /*******************Crud *********************** */
    const getAllData = async (currentPage:any,pageSize:any,searchWord:any) => {

        try {
            const offset = (currentPage - 1) * pageSize;
            const res = await GetData(`/dataTable/intervention-rmbox-list?columns[0][orderable]=true&draw=${currentPage}$length=${pageSize}&idRmbox=${RmBoxSelected}&order[0][column]=0&order[0][dir]=asc&start=${offset}&search[value]=${searchWord}`);
           
            if (res.status === 200 && res.data.code === 200) {
                dispatch(
                    setshowDivsConditionsIntervention({
                        showDivsConditionsName: "loader",
                        showDivsConditionsValue: false,
                    }))
                dispatch(setDataInterventionActions(res.data.data));
                dispatch({type:"SET_RECORD",payload:res.data.recordsFiltered})
            }else if (res.status === 200 && res.data.code !== 200) {
              
                message.error({ content: t('Intervention.messageerroroperation'), duration: 2 })
            }

        } catch (e) {
            message.error({ content: t('Intervention.messageerroroperation'), duration: 2 })
        }
    }
    const AddData = async (data: any) => {
        try {
            const body = {
                "empConfigurationRmboxInterventionForm": {
                    "remarque": data.remarque,
                    "receptionDate": data.receptionDate,
                    "sendDate": data.sendDate, "oldMac": data.oldMac,
                    "newMac": data.newMac, "firstStartedDate": data.firstStartedDate,
                    "rmbox": RmBoxSelected
                }
            }
            const res = await PostData(`/rmboxintervention/add`, body);
           
            if (res.status === 200 && res.data.code === 200) {
                await dispatch(
                    setshowDivsConditionsIntervention({
                        showDivsConditionsName: "addIntervention",
                        showDivsConditionsValue: false,
                    })
                );
                message.success({ content: t('Intervention.successadd'), duration: 2 })

            }else if (res.status === 200 && res.data.code !== 200) {
                message.error({ content: t('Intervention.messageerroroperation'), duration: 2 })
            }
        } catch (e) {
            message.error({ content: t('Intervention.messageerroroperation'), duration: 2 })

        }
    }
    const ModifData = async (data: any, id: any) => {
        try {
            const body = {
                "id": id, "empConfigurationRmboxInterventionForm": {
                    "remarque": data.remarque,
                    "receptionDate": data.receptionDate,
                    "sendDate": data.sendDate, "oldMac": data.oldMac,
                    "newMac": data.newMac, "firstStartedDate": data.firstStartedDate,
                    "rmbox": RmBoxSelected
                }
            }
            const res = await PostData(`/rmboxintervention/edit/${id}`, body);
      
            if (res.status === 200 && res.data.code === 200) {
                await dispatch(
                    setshowDivsConditionsIntervention({
                        showDivsConditionsName: "editIntervention",
                        showDivsConditionsValue: false,
                    })
                );
                message.success({ content: t('Intervention.successedit'), duration: 2 })

               
            } else if (res.status === 200 && res.data.code !== 200) {
                message.error({ content: t('Intervention.messageerroroperation'), duration: 2 })
            }
        } catch (e) {
            message.error({ content: t('Intervention.messageerroroperation'), duration: 2 })
        }
    }
    const Deleteselecteditem = async (id: any) => {
        try {
            const res = await DeleteData(`/rmboxintervention/delete/${id}`);
            
            if (res.status === 200 && res.data.code === 200) {
                getAllData(currentPage,pageSize,searchWord)
                message.success({ content: t('Intervention.successdelete'), duration: 2 })
            } else if (res.status === 200 && res.data.code !== 200) {
                message.error({ content: t('Intervention.messageerroroperation'), duration: 2 })
            }
        } catch (e) {
            message.error({ content: t('Intervention.messageerroroperation'), duration: 2 })
        }
    }






    return ({
        handleAddIntervention,
        onResetIntervention,
        formAddIntervention,
        handleSearchRow,

        onFinishAddIntervention,
        onFinishFailedAddIntervention,
        onValueFormUserchangeIntervention,

        handleEditIntervention,
        values,

        handelSearch,
        getAllData,
        Deleteselecteditem,
        disabledsubmitbutton

    })
}
export default Hooks