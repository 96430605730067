import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Form, message } from "antd";

import {
  setAddGroupeRmBoxActions,
  setFilterGroupeRmBoxActions,
  setPaginationPropsGroupeRmBox,
  setGroupeRmBoxDataActions,
  setshowDivsConditionsGroupeRmBox,
  setOpenDeleteSpace
} from "../store/GroupeRmBoxActions";
import { GroupeRmBoxList } from "../store/GroupeRmBox";
import { useAppSelector } from "../../../Redux/hooks";
import { DeleteData, GetData, PostData } from "../../../APIcalls";
import moment from "moment";
import { useTranslation } from "react-i18next";

const Hooks = () => {
  //Modal Details
  const { t } = useTranslation();
  const [visible, setVisible] = useState<any>(false);

  //Modal Program schedule:
  const [visiblePgm, setVisiblePgm] = useState<any>(false);
  const dispatch = useDispatch();
  const { GroupeRmBoxTypes } = GroupeRmBoxList();
  const [formAddGroupeRmBox] = Form.useForm();
  const [ProgramSchedule, setProgramSchedule] = useState([]);
  const [currentPage]=useState(1)
  const [pageSize]= useState(10)
  const [searchWord]=useState('')
  // use Selector redux
  const values = useAppSelector(
    (state: any) => state.GroupeRmBoxReducer.addGroupeRmBoxConstraintsData
  );
  const { editGroupeRmBox, addGroupeRmBox } = useAppSelector(
    (state: any) => state.GroupeRmBoxReducer.showDivsConditionsGroupeRmBox
  );
  const selectedIds = useAppSelector((state)=>state.GroupeRmBoxReducer.selectedCase)

  const convertTime = (time: any) => {
    const dd = new Date(time);
    const tt = time ? moment(dd, "HH:mm:ss") : null;
    return tt;
  };
  useEffect(() => {
    ///
    if (editGroupeRmBox) {
      formAddGroupeRmBox.setFieldsValue({
        id: values.id,
        name: values.name,
        bandwidthLimit: values.bandwidthLimit,
        programs: values.programs,
        schedule: values.schedule,
        syncTime: convertTime(values.syncTime),
      });
    }
    if (addGroupeRmBox) {
      formAddGroupeRmBox.resetFields();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editGroupeRmBox, addGroupeRmBox]);

  useEffect(() => {
    if (visible) {
      formAddGroupeRmBox.setFieldsValue({
        id: values.id,
        name: values.name,
        bandwidthLimit: values.bandwidthLimit,
        programs: values.programs,
        schedule: values.schedule,
        syncTime: values.syncTime,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  ////*************** show Modal Function ******************/////////
  const handleShowModal = async (id: number) => {
    await getOneGroupeRmBox(id);
    setVisible(true);
  };
  const handleShowProgramScheduleModal = async () => {
    await getListProgramSchedule();
    setVisiblePgm(true);
  };
  const handleCancel = () => {
    setVisiblePgm(false);
    setVisible(false);
    setProgramSchedule([]);
    dispatch(
      setGroupeRmBoxDataActions({ msg: "", err: false, GroupeRmBox: {} })
    );
  };
  ///**** */

  /////********* show add GroupeRmBox ****************/////
  const handleAddGroupeRmBox = () => {
    /*     formAddGroupeRmBox.setFieldsValue({
      id: "",
      name: "",
      bandwidthLimit: "",
      syncTime: "",
      schedule: "",
      programs: [],
    }); */
    formAddGroupeRmBox.resetFields();
    dispatch(
      setshowDivsConditionsGroupeRmBox({
        showDivsConditionsName: "addGroupeRmBox",
        showDivsConditionsValue: true,
      })
    );
  };

  const onResetGroupeRmBox = async () => {
    /**edit item list */
    formAddGroupeRmBox.setFieldsValue({
      id: "",
      name: "",
      bandwidthLimit: "",
      syncTime: moment(null, "HH:mm:ss"),
      schedule: "",
      programs: [],
    });
    dispatch(
      setshowDivsConditionsGroupeRmBox({
        showDivsConditionsName: "editGroupeRmBox",
        showDivsConditionsValue: false,
      })
    );
    dispatch(
      setshowDivsConditionsGroupeRmBox({
        showDivsConditionsName: "addGroupeRmBox",
        showDivsConditionsValue: false,
      })
    );
    dispatch(
      setAddGroupeRmBoxActions({
        addGroupeRmBoxConstraintsDataNameChange: "id",
        addGroupeRmBoxConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddGroupeRmBoxActions({
        addGroupeRmBoxConstraintsDataNameChange: "name",
        addGroupeRmBoxConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddGroupeRmBoxActions({
        addGroupeRmBoxConstraintsDataNameChange: "syncTime",
        addGroupeRmBoxConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddGroupeRmBoxActions({
        addGroupeRmBoxConstraintsDataNameChange: "bandwidthLimit",
        addGroupeRmBoxConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddGroupeRmBoxActions({
        addGroupeRmBoxConstraintsDataNameChange: "schedule",
        addGroupeRmBoxConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddGroupeRmBoxActions({
        addGroupeRmBoxConstraintsDataNameChange: "programs",
        addGroupeRmBoxConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddGroupeRmBoxActions({
        addGroupeRmBoxConstraintsDataNameChange: "errors",
        addGroupeRmBoxConstraintsDataValueChange: {},
      })
    );


    dispatch(setOpenDeleteSpace(false))
  };

  ////////****** Edit GroupeRmBox ***************////
  const handleEditGroupeRmBox = async (record: any) => {
    formAddGroupeRmBox.setFieldsValue({
      id: record.id,
      bandwidthLimit: record.bandwidthLimit,
      name: record.name,
      syncTime: record.syncTime ? record.syncTime : "",
      schedule: record.schedule,
      programs: record.programs,
    });
    await dispatch(
      setAddGroupeRmBoxActions({
        addGroupeRmBoxConstraintsDataNameChange: "id",
        addGroupeRmBoxConstraintsDataValueChange: record?.row?.id,
      })
    );
    await dispatch(
      setAddGroupeRmBoxActions({
        addGroupeRmBoxConstraintsDataNameChange: "name",
        addGroupeRmBoxConstraintsDataValueChange: record?.row?.name,
      })
    );
    await dispatch(
      setAddGroupeRmBoxActions({
        addGroupeRmBoxConstraintsDataNameChange: "syncTime",
        addGroupeRmBoxConstraintsDataValueChange: record?.row?.syncTime,
      })
    );
    await dispatch(
      setAddGroupeRmBoxActions({
        addGroupeRmBoxConstraintsDataNameChange: "bandwidthLimit",
        addGroupeRmBoxConstraintsDataValueChange: record?.row?.bandwidthLimit,
      })
    );
    await dispatch(
      setAddGroupeRmBoxActions({
        addGroupeRmBoxConstraintsDataNameChange: "schedule",
        addGroupeRmBoxConstraintsDataValueChange: record?.row?.schedule,
      })
    );
    await dispatch(
      setAddGroupeRmBoxActions({
        addGroupeRmBoxConstraintsDataNameChange: "programs",
        addGroupeRmBoxConstraintsDataValueChange:  record?.row?.programs,
      })
    );
    dispatch(
      setGroupeRmBoxDataActions({
        GroupeRmBox: record,
      })
    );
    await dispatch(
      setshowDivsConditionsGroupeRmBox({
        showDivsConditionsName: "editGroupeRmBox",
        showDivsConditionsValue: true,
      })
    );
  };

  const handleProgramScheduleChange = (data: any) => {
    setProgramSchedule(data);
  };
  /********************************** */
  ////****************** function Filter *****************///////
  //*********************** search header **************///////
  const handleSearchRow = async (event: any) => {
      dispatch(
        setFilterGroupeRmBoxActions({
          FilterGroupeRmBoxNameChange: "search",
          FilterGroupeRmBoxValueChange: event,
        })
      );
    
  };

  const handelSearch = (word: string, tab: any) => {
    if (word.trim() === "") return tab;
    // eslint-disable-next-line array-callback-return
    const res = tab.filter((obj: any) => {
      if (obj?.id) return obj;
      if (obj.name?.toLowerCase().includes(word.toLowerCase())) return obj;
      if (obj.bandwidthLimit?.toLowerCase().includes(word.toLowerCase()))
        return obj;
      const time = moment(obj.syncTime).format("HH:mm:ss");
      if (time.includes(word)) return obj;
      if (obj.schedule?.toLowerCase().includes(word.toLowerCase())) return obj;
    });
    return res;
  };
  ///**** filter table ******/
  const handelFilter = (type: any, tab: any) => {
    function smallval(item: any) {
      if (type === "All") {
        return item;
      } else if (type !== "") {
        return item.type === type;
      } else {
        return item;
      }
    }

    const res = tab.filter(smallval);

    return res;
  };
  /////**************** Form Add GroupeRmBox *******************/////////
  const onFinishAddGroupeRmBox = (values: any) => {
    if (validator()) {
      if (!values.id) {
        AddGroupeRmBox(values);
      } else {
        UpdateGroupeRmBox(values);
      }
    }
  };
  const onFinishFailedAddGroupeRmBox = (errorInfo: any) => {

  };
  const onValueFormUserchangeGroupeRmBox = async (val: any) => {
    const key = Object.keys(val);
    await dispatch(
      setAddGroupeRmBoxActions({
        addGroupeRmBoxConstraintsDataNameChange: key[0],
        addGroupeRmBoxConstraintsDataValueChange: val[key[0]],
      })
    );
    resetErrors(key[0]);
    /*     if (val.name) {
      await dispatch(
        setAddGroupeRmBoxActions({
          addGroupeRmBoxConstraintsDataNameChange: "name",
          addGroupeRmBoxConstraintsDataValueChange: val.name,
        })
      );
      resetErrors("name");
    } else if (val.syncTime) {
      await dispatch(
        setAddGroupeRmBoxActions({
          addGroupeRmBoxConstraintsDataNameChange: "syncTime",
          addGroupeRmBoxConstraintsDataValueChange: val.syncTime,
        })
      );
      resetErrors("syncTime");
    } else if (val.bandwidthLimit) {
      await dispatch(
        setAddGroupeRmBoxActions({
          addGroupeRmBoxConstraintsDataNameChange: "bandwidthLimit",
          addGroupeRmBoxConstraintsDataValueChange: val.bandwidthLimit,
        })
      );
      resetErrors("bandwidthLimit");
    } else if (val.schedule) {
      await dispatch(
        setAddGroupeRmBoxActions({
          addGroupeRmBoxConstraintsDataNameChange: "schedule",
          addGroupeRmBoxConstraintsDataValueChange: val.schedule,
        })
      );
      resetErrors("schedule");
    } else if (val.programs) {
      await dispatch(
        setAddGroupeRmBoxActions({
          addGroupeRmBoxConstraintsDataNameChange: "programs",
          addGroupeRmBoxConstraintsDataValueChange: val.programs,
        })
      );
      resetErrors("programs");
    } */
  };
  /// validation of ther Form
  const validator = () => {
    let errors = {};
    if (values.name.trim() === "")
      errors = {
        ...errors,
        name:
          t("boitier.messageerror") +
          " '" +
          t("boitier.Rmboxgroup.Header.name") +
          "'!",
      };
    if (isNaN(values.bandwidthLimit))
      errors = {
        ...errors,
        bandwidthLimit:
          "" +
          t("boitier.Rmboxgroup.Header.bandwidth") +
          " " +
          t("boitier.requiredNumber"), //" must be a number",
      };
    if (values.bandwidthLimit.trim() === "")
      errors = {
        ...errors,
        bandwidthLimit:
          t("boitier.messageerror") +
          " '" +
          t("boitier.Rmboxgroup.Header.bandwidth") +
          "'!",
      };
    if (!values.syncTime)
      errors = {
        ...errors,
        syncTime:
          t("boitier.messageerror") +
          " '" +
          t("boitier.Rmboxgroup.Header.Synchro_schedule2") +
          "'!",
      };
    dispatch(
      setAddGroupeRmBoxActions({
        addGroupeRmBoxConstraintsDataNameChange: "errors",
        addGroupeRmBoxConstraintsDataValueChange: errors,
      })
    );
    if (JSON.stringify(errors) === "{}") {
      return true;
    } else {
      return false;
    }
  };

  // check if the required fields is valid
  const requiredFields = () => {
    return (
      values.name.trim() === "" &&
      values.bandwidthLimit.trim() === "" &&
      !values.syncTime
    );
  };

  const resetErrors = (name: string) => {
    if (values.errors) {
      let err = values.errors;
      delete err[name];
      dispatch(
        setAddGroupeRmBoxActions({
          addGroupeRmBoxConstraintsDataNameChange: "errors",
          addGroupeRmBoxConstraintsDataValueChange: err,
        })
      );
    }
  };

  ///******** delete  ********///
  const handleDeleteGroupeRmBox = (ids: any) => {
    let list = "";
    // eslint-disable-next-line array-callback-return
    ids.map((v: any) => {
      list += v + ",";
    });
    // deleteMultiGroupeRmBox(list.slice(0, -1));
  };

  ///******* handle Api *******//

  //*  Get List
  const getListGroupeRmBox = async (currentPage:any,pageSize:any,searchWord:any) => {
    const offset = (currentPage - 1) * pageSize;
    dispatch({
      type: GroupeRmBoxTypes.LOADING,
    });
    await GetData(
      `/dataTable/rmboxgroups?columns%5B0%5D%5Borderable%5D=true&draw=${currentPage}&length=${pageSize}&order%5B0%5D%5Bcolumn%5D=0&order%5B0%5D%5Bdir%5D=asc&start=${offset}&search[value]=${searchWord}`
    )
      .then((res: any) => {
        if (res.status === 200) {
          dispatch(
            setshowDivsConditionsGroupeRmBox({
              showDivsConditionsName: "loader",
              showDivsConditionsValue: false,
            })
          )
          dispatch(
            setGroupeRmBoxDataActions({
              List: res.data.data,
              msg: "",
              error: false,
            })
          );
          dispatch({type:"SET_RECORD",payload:res.data.recordsFiltered})
        } else {
          const msg = HandleFaildApiMessage(res.data.code, res.data?.message);
          dispatch(setGroupeRmBoxDataActions({ msg, err: true, list: [] }));
         // message.error(`${t("boitier.addmsg")} Group RmBox ${t("boitier.succss")}`);
        }
      })
      .catch((err) => {
        const msg = HandleFaildApiMessage(
          err.response.data.code,
          err.response.data?.message
        );
        dispatch(setGroupeRmBoxDataActions({ msg, err: true, list: [] }));
        message.error(t("boitier.failed_ADD"));
      });
  };

  // Get One GroupeRmBox

  const getOneGroupeRmBox = async (id: number) => {
    dispatch({
      type: GroupeRmBoxTypes.LOADING,
    });
    await GetData(`/rmboxgroup/${id}`)
      .then((res: any) => {
        if (res?.status === 200) {
          dispatch(
            setGroupeRmBoxDataActions({
              GroupeRmBox: res?.data,
              msg: "",
              error: false,
            })
          );
        } else {
          const msg = HandleFaildApiMessage(res?.data?.code, res?.data?.message);
          dispatch(
            setGroupeRmBoxDataActions({ msg, err: true, GroupeRmBox: null })
          );
      //    message.error(`${t("boitier.addmsg")} ${t("boitier.succss")}`);
        }
      })
      .catch((err) => {
        const msg = HandleFaildApiMessage(
          err.response.data.code,
          err.response.data?.message
        );
        dispatch(
          setGroupeRmBoxDataActions({ msg, err: true, GroupeRmBox: null })
        );
      });
  };

  //*  Get Program Schedule List
  const getListProgramSchedule = async () => {
    dispatch({
      type: GroupeRmBoxTypes.LOADING,
    });
    await GetData("/programDisplay/list-programs")
      .then((res: any) => {
        if (res.status === 200) {
          dispatch(
            setGroupeRmBoxDataActions({
              programs: res.data.data.programs,
              msg: "",
              error: false,
            })
          );
        } else {
          const msg = HandleFaildApiMessage(res.data.code, res.data?.message);
          dispatch(setGroupeRmBoxDataActions({ msg, err: true, programs: [] }));
      //    message.error(`${t("boitier.addmsg")} ${t("boitier.succss")}`);
        }
      })
      .catch((err) => {
        const msg = HandleFaildApiMessage(
          err.response.data.code,
          err.response.data?.message
        );
     //   message.error(`${t("boitier.addmsg")} ${t("boitier.succss")}`);
        dispatch(setGroupeRmBoxDataActions({ msg, err: true, programs: [] }));
      });
  };

  // add
  const AddGroupeRmBox = async (data: any) => {
    //const tt = { data };

    const date = data.syncTime.toString();
    const empConfigurationRmboxgroupForm = {
      ...data,
      syncTime: date,
    };
    dispatch({
      type: GroupeRmBoxTypes.LOADING,
    });

    await PostData("/rmboxgroup/add", { empConfigurationRmboxgroupForm })
      .then(({ data }: any) => {
        if (data.code === 200) {
          dispatch(setGroupeRmBoxDataActions({ msg: "", error: false }));
          getListGroupeRmBox(currentPage,pageSize,searchWord);
          onResetGroupeRmBox();

          message.success(t("boitier.Rmboxgroup.success_ADD"));
        } else {
          const msg = HandleFaildApiMessage(data.code, data?.message);
          dispatch(
            setGroupeRmBoxDataActions({ msg, error: true, errors: data.errors })
          );
        }
      })
      .catch((err) => {
        const msg = HandleFaildApiMessage(
          err.response.data.code,
          err.response.data?.message
        );
        message.error(`${t("boitier.Rmboxgroup.failed_ADD")}`);
        dispatch(
          setGroupeRmBoxDataActions({
            msg,
            error: true,
            list: [],
            errors: data.errors,
          })
        );
      });
  };
  // Association Program Schedule:

  const AddProgramSchedule = async (data: any) => {
    //const tt = { data };
    if (ProgramSchedule.length === 0) return;
    
    const empConfigurationRmboxgroupForm = {
      idRmboxgroup: data.id,
      ids: data.ids,
    };
    dispatch({
      type: GroupeRmBoxTypes.LOADING,
    });

    await PostData(
      "/rmboxgroup/associate-program-to-rmboxgroup",
      empConfigurationRmboxgroupForm
    )
      .then(({ data }: any) => {
        if (data.code === 200) {
          dispatch(setGroupeRmBoxDataActions({ msg: "", error: false }));
          getListGroupeRmBox(currentPage,pageSize,searchWord);
          onResetGroupeRmBox();
        } else {
          const msg = HandleFaildApiMessage(data.code, data?.message);
          dispatch(
            setGroupeRmBoxDataActions({ msg, error: true, errors: data.errors })
          );
          message.error(`${t("boitier.Rmboxgroup.succss")}`);
        }
      })
      .catch((err) => {
        const msg = HandleFaildApiMessage(
          err.response.data.code,
          err.response.data?.message
        );
        dispatch(
          setGroupeRmBoxDataActions({
            msg,
            error: true,
            list: [],
            errors: data.errors,
          })
        );
        message.error(` ${t("boitier.Rmboxgroup.succss")}`);
      });
  };
  // update

  const UpdateGroupeRmBox = async (data: any) => {
    dispatch({
      type: GroupeRmBoxTypes.LOADING,
    });
    const empConfigurationRmboxgroupForm = {
      name: data.name,
      bandwidthLimit: data.bandwidthLimit,
      syncTime: data.syncTime.toString(),
    };

    await PostData("/rmboxgroup/edit/" + data.id, {
      id: data.id,
      empConfigurationRmboxgroupForm,
    })
      .then((res: any) => {
        if (res.status === 200) {
          dispatch(setGroupeRmBoxDataActions({ msg: "", error: false }));
          formAddGroupeRmBox.resetFields();
          getListGroupeRmBox(currentPage,pageSize,searchWord);
          onResetGroupeRmBox();
          message.success(`${t("boitier.Rmboxgroup.success_Edit")}`);
        } else {
          const msg = res.data
            ? HandleFaildApiMessage(res.data.code, res.data?.message)
            : "serveur erreur";
          dispatch(setGroupeRmBoxDataActions({ msg, error: true }));
          message.error(`${t("boitier.Rmboxgroup.failed_Edit")}`);
        }
      })
      .catch((err) => {
        const msg = err.response?.data
          ? HandleFaildApiMessage(
              err.response.data.code,
              err.response.data?.message
            )
          : "serveur erreur";
        dispatch(setGroupeRmBoxDataActions({ msg, error: true }));
        message.error(`${t("boitier.Rmboxgroup.failed_Edit")}`);
      });
  };

  const deleteGroupeRmBox = async (id: number) => {
    dispatch({
      type: GroupeRmBoxTypes.LOADING,
    });

    await DeleteData("/rmboxgroup/delete/" + id)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch(setGroupeRmBoxDataActions({ msg: "", error: false }));
          getListGroupeRmBox(currentPage,pageSize,searchWord);
          message.success(`${t("boitier.Rmboxgroup.success_Delete")}`);
        } else {
          const msg = HandleFaildApiMessage(res.data.code, res.data?.message);
          dispatch(setGroupeRmBoxDataActions({ msg, error: true }));
          message.error(` ${t("boitier.Rmboxgroup.failed_Delete")}`);
        }
      })
      .catch((err) => {
        const msg = HandleFaildApiMessage(
          err.response.data.code,
          err.response.data?.message
        );
        message.error(`${t("boitier.Rmboxgroup.failed_Delete")}`);
        dispatch(setGroupeRmBoxDataActions({ msg, error: true, list: [] }));
      });
  };
  // delete multiple
  const deleteMultiGroupeRmBox = async () => {
    const ids = [selectedIds.join()];
    dispatch({
      type: GroupeRmBoxTypes.LOADING,
    });
    await DeleteData("/multiDelete/rmboxgroups/" + ids)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch(setGroupeRmBoxDataActions({ msg: "", error: false }));
          dispatch(
            setPaginationPropsGroupeRmBox({
              PaginationPropsNameChange: "id",
              PaginationPropsValueChange: [],
            })
          );
          dispatch(
            setshowDivsConditionsGroupeRmBox({
              showDivsConditionsName: "elementSelected",
              showDivsConditionsValue: 0,
            })
          );
          getListGroupeRmBox(currentPage,pageSize,searchWord);
          message.success(`${t("boitier.Rmboxgroup.success_Delete")}`);
        } else {
          message.error(` ${t("boitier.Rmboxgroup.failed_Delete")}`);
          dispatch(setGroupeRmBoxDataActions({ msg: "error", error: true }));
        }
      })
      .catch((err) => {
        const msg =
          err.response && err.response.status === 404 ? "server  down" : err;
        dispatch(setGroupeRmBoxDataActions({ msg, error: true }));
        message.error(`${t("boitier.Rmboxgroup.failed_Delete")}`);
      });
  };

  const handleAdd = ()=>{
    AddGroupeRmBox(values)
  }
  /*****Add disabled button form ****** */


  const disabledButton = (type: any) => {
    if (type === "disabled") {
      dispatch(
        setshowDivsConditionsGroupeRmBox({
          showDivsConditionsName: "disabledButton",
          showDivsConditionsValue: false,
        })
      );
    } else if (type === "enabled") {
      dispatch(
        setshowDivsConditionsGroupeRmBox({
          showDivsConditionsName: "disabledButton",
          showDivsConditionsValue: true,
        })
      );
    }
  };


  // Faild message

  const HandleFaildApiMessage = (code: any, msg: string) => {
    switch (code) {
      case 404:
        return "page not found";
      case 401:
        return "you are not authorized";
      case 500:
        return "serveur error";

      default:
        return msg;
    }
  };

  ///******* end handle Api *******/

  return {
    handleAddGroupeRmBox,
    onResetGroupeRmBox,
    formAddGroupeRmBox,
    handleSearchRow,
    handleShowModal,
    visible,
    visiblePgm,
    handleCancel,
    onFinishAddGroupeRmBox,
    onFinishFailedAddGroupeRmBox,
    onValueFormUserchangeGroupeRmBox,
    handleEditGroupeRmBox,
    values,
    handelSearch,
    handelFilter,
    getListGroupeRmBox,
    deleteMultiGroupeRmBox,
    // getOneGroupeRmBox,
    deleteGroupeRmBox,
    handleDeleteGroupeRmBox,
    handleShowProgramScheduleModal,
    handleProgramScheduleChange, //select handle Program Schedule
    ProgramSchedule,
    AddProgramSchedule,
    requiredFields,
    handleAdd,
    convertTime,
    disabledButton,
    UpdateGroupeRmBox
  };
};
export default Hooks;
