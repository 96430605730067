import { ZoneList } from "./zoneConstants";
const { showDivsConditionsZone, AddZoneConstraintsData, paginationPropsZone, FilterZone, DataZone, DetailZone } = ZoneList()

const ZoneListINITIALSTATE = {
    showDivsConditionsZone: showDivsConditionsZone(),
    paginationPropsZone: paginationPropsZone(),
    addZoneConstraintsData: AddZoneConstraintsData(),
    filterZone: FilterZone(),
    dataZone: DataZone(),
    detailZone: DetailZone(),
    listrmbox: [],
    ErrorMessage: "",
    totalRecord:0,
    selectedCase:[],
    showDeleteSpace:false,
    showDelModal:false

}

export const ZoneReducer = (state = ZoneListINITIALSTATE, action: any) => {

    switch (action.type) {

        case "SET_FilterZone":
            const { FilterZoneNameChange, FilterZoneValueChange } = action.payload
            return {
                ...state,
                filterZone: { ...state.filterZone, [FilterZoneNameChange]: FilterZoneValueChange }
            }
        case "SET_PaginationPropsZone":
            const { PaginationPropsNameChange, PaginationPropsValueChange } = action.payload
            return {
                ...state,
                paginationPropsZone: { ...state.paginationPropsZone, [PaginationPropsNameChange]: PaginationPropsValueChange }
            }
        case "SET_showDivsConditionsZone":
            const { showDivsConditionsName, showDivsConditionsValue } = action.payload
            const showDivsConditionsObj = { ...state.showDivsConditionsZone, [showDivsConditionsName]: showDivsConditionsValue }
            return {
                ...state,
                showDivsConditionsZone: showDivsConditionsObj
            }
        case "SET_AddZoneActions":
            const { addZoneConstraintsDataNameChange, addZoneConstraintsDataValueChange } = action.payload
            return {
                ...state,
                addZoneConstraintsData: { ...state.addZoneConstraintsData, [addZoneConstraintsDataNameChange]: addZoneConstraintsDataValueChange }
            }

        case "SET_DataZoneActions":

            return {
                ...state,
                dataZone: action.payload
            }
        case "SET_RECORDS":
            return{
                ...state,
                totalRecord:action.payload
            }       
        case "SET_ListRmboxZoneActions":
          
            return {
                ...state,
                listrmbox: action.payload
            }
        case "SET_DetailZoneActions":

            return {
                ...state,
                detailZone: action.payload
            }
        case "SET_ErrorrMessage":
     
            return {
                ...state,
                ErrorMessage: action.payload
            }
            case "SET_DELETESPACE":
                return{
                    ...state,
                    showDeleteSpace:action.payload
                } 
                case "SET_IDSELECTED":
                    return{
                        ...state,
                        selectedCase:action.payload
                    }   
              case "SET_MODALDEL":
            return{
                ...state,
                showDelModal:action.payload
            }    

        default: {
            return state
        }

    }

}

