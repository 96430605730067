import { TYPES } from "./types"
import axios from "axios"
import { setshowDivsConditionsZone } from "../MenuBoitier/Zone/store/zoneActions";
import { setshowDivsConditionsAuthorizedIP } from "../MenuBoitier/AuthorizedIp/store/AuthorizedIPActions";
import { setshowDivsConditionsGroupeRmBox } from "../MenuBoitier/GroupeRmBox/store/GroupeRmBoxActions";
import { setshowDivsConditionsIntervention } from "../MenuBoitier/Interventions/store/interventionActions";
import { setshowDivsConditionsRmbox } from "../MenuBoitier/RmBox/store/rmboxActions";


const search = window.location.search;
const token = new URLSearchParams(search).get("token");
const headers = {
    Authorization: `Bearer ${token}`,
  }
  const url = "https:"+ window.process.env.API_HOST_GLOBAL
  export const getUserData = () => (dispatch) =>{
     axios
     .get(`${url}/core/rights/get-rights-by-module`, {headers})
    .then((res) => { 
         if(res.status === 200) {
     dispatch(setshowDivsConditionsZone({
        showDivsConditionsName:"Authorized",
        showDivsConditionsValue:true
     }))       
     dispatch(setshowDivsConditionsAuthorizedIP({
        showDivsConditionsName:"Authorized",
        showDivsConditionsValue:true
     }))
     dispatch(setshowDivsConditionsGroupeRmBox({
        showDivsConditionsName:"Authorized",
        showDivsConditionsValue:true
     }))
     dispatch(setshowDivsConditionsRmbox({
        showDivsConditionsName:"Authorized",
        showDivsConditionsValue:true
     }))
     dispatch(setshowDivsConditionsIntervention({
        showDivsConditionsName:"Authorized",
        showDivsConditionsValue:true
     }))  
    dispatch({ type: TYPES.GET_USER_DATA, payload: res.data })}})
  .then(() => dispatch({ type: TYPES.ON_LOADING_SCREEN, payload: false }))
  .catch(()=>dispatch({type:TYPES.UNAUTHORIZED}))
};
