
import React from 'react';
import {

    EditOutlined,
    DeleteOutlined,
    
} from '@ant-design/icons';

import { Tooltip, } from 'antd';
import { useTranslation } from "react-i18next";
import Hooks from "../../utils/hooks";
import '../../intervention.scss'

function useActionMenu({ record }: any) {

    //const history = useNavigate();
    const { t } = useTranslation();
    const { handleEditIntervention, Deleteselecteditem } = Hooks()
    

    return (
        <div>
            <div className="action">
                <div><Tooltip placement="top" title={t("Intervention.edit")}><EditOutlined className={"icon_action"} onClick={() => handleEditIntervention(record)} /></Tooltip></div>
                <div><Tooltip placement="top" title={t("Intervention.delete")}><DeleteOutlined className={"icon_action"} onClick={() => Deleteselecteditem(record.id)} /></Tooltip></div>
            </div>
        </div>
    );
}

export default useActionMenu;
