import React,{useState,useEffect} from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../Redux/hooks";
import { Button, Modal, Select } from "antd";
import Hooks from "../utils/hooks";

import { ModalInputs } from "@empreinte/components.modal-inputs";
const { Option } = Select;
//const ListFieldType = ["test", "test"];
export default function ProgramSchedule({ visible, handleCancel }: Visible) {
  const { t } = useTranslation();
  const { programs} = useAppSelector((state) => state.GroupeRmBoxReducer);
  const [ListFieldType,setListFieldType] = useState([])
  const {handleProgramScheduleChange,AddProgramSchedule}=Hooks()
  useEffect(() => {
   setListFieldType(programs)
  }, [programs])
  return (
    <>
      <ModalInputs
      className="modal"
      open={visible}
      title={t("boitier.Rmboxgroup.pgmschedule")}
        onCancel={handleCancel}
        onClose={handleCancel}
        CancelBtnName={t("boitier.close")}
        ConfirmBtnName={t("boitier.btnValidate")}
        onConfirm={AddProgramSchedule}
        style={{
          paddingRight:"24px",
              paddingLeft:"24px",
              paddingTop:"5px",
              paddingBottom:"8px",
              marginBlockStart:"24px",
              zIndex:"22",
        }}
        children={
          <div className={"detailsGroupeRmBoxDivGlobal"}>
          <div className={"detailsGroupeRmBoxDiv"}>
            <label className={"detailsGroupeRmBoxSpan1"}>
              {t("boitier.Rmboxgroup.Header.Program_schedule")}
            </label>
            <Select
              showSearch
             // className="selectFilter"
              placeholder={t("boitier.Rmboxgroup.choose schedule")}
              optionFilterProp="children"
              onChange={handleProgramScheduleChange}
              style={{width:"100%"}}
            >
              {Array.isArray(ListFieldType) && ListFieldType?.map((item, key) => {
                return (
                  <Option name={item} value={item} key={key}>
                    {item}
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
        }

      />
    </>
  );
}
interface Visible {
  visible: boolean;
  handleCancel: any;
}
