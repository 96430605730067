
export function setPaginationPropsRmbox(e:any){
    return{
        type:"SET_PaginationPropsRmbox",
        payload:e,
    }
}

export function setshowDivsConditionsRmbox(e:any){
    return {
        type : "SET_showDivsConditionsRmbox",
        payload:e
    }
}

export function setAddRmboxActions(e:any){
    return{
        type:"SET_AddRmboxActions",
        payload:e,
    }
}

export function setFilterRmboxActions(e:any){

    return{
        type:"SET_FilterRmbox",
        payload:e,
    }

}

export function setEditRmboxActions(e:any){
    return{
        type:"SET_EditRmboxActions",
        payload:e,
    }
}

export function setDataRmboxActions(e:any){
    return{
        type:"SET_DataRmboxActions",
        payload:e,
    }
}

export function setDetailRmboxActions(e:any){
    return{
        type:"SET_DetailRmboxActions",
        payload:e,
    }
}


export function setReportsRmboxActions(e:any){
    return{
        type:"SET_ReportsRmboxActions",
        payload:e,
    }
}
export function setListGroupRmboxActions(e:any){
    return{
        type:"SET_ListGroupRmboxActions",
        payload:e,
    }
}


export function setSuccessRmboxActions(e:any){
    return{
        type:"SET_SuccessRmboxActions",
        payload:e,
    }
}

export function setErrorrMessageRmboxActions(e:any){
    return{
        type:"SET_ErrorrMessageRmboxActions",
        payload:e,
    }
}
export function setSelected(e:any){
    const listSelected = e.map((e:any)=>e);
    return {
        type:"SET_IDSELECTED",
        payload:listSelected,
    }
  }
  
  export function setOpenDeleteSpace(e:any){
    return{
        type:"SET_DELETESPACE",
        payload:e
    }
  }
  export function setOpenModalDel(e:any){
    return{
      type: "SET_MODALDEL",
      payload:e
    }
  }
  export function deleteStatus(e:any){
    return{
        type:"SET_STATUS_DELETE",
        payload:e
    }
  }
