import React,{useState} from "react";
import {Tooltip} from "antd";
import useDataTableMedia from "./DataTable";
import {useAppSelector} from "../../../../Redux/hooks";
import {setOpenModalDel} from "../../store/zoneActions";
import {useDispatch} from "react-redux";
import {DataZone} from "../../utils/Data";
import {useTranslation} from "react-i18next";
import Hooks from "../../utils/hooks";
import '../../zone.scss'
import { SearchBar } from "@empreinte/components.search-bar";
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from "@empreinte/components.button";
import {ModalConfirm} from "@empreinte/components.modal-confirm";


const ZoneTable = () =>{

    const dispatch = useDispatch()
    const {t} = useTranslation();
    // use Selector redux
    const valuePagination = useAppSelector((state) => state.ZoneReducer.paginationPropsZone)
    const isSelected = useAppSelector((state)=>state.ZoneReducer.selectedCase)
    const numberOfselectedRows = isSelected.length;
    const {columns} = DataZone()
    const data = useAppSelector((state) => state.ZoneReducer.dataZone)
    const {handleSearchRow , Deletemultipleselecteditem,getAllData} = Hooks()
    const openDel = useAppSelector((state)=>state.ZoneReducer.showDeleteSpace);
    const [openModal,setOpenModal] = useState(false)
    const searchWord = useAppSelector((state)=>state.ZoneReducer.filterZone.type)
    const [serachText,setSearchText]=useState('')
    const [currentPage,setCurrentPage] = useState(1);
    const [pageSize,] = useState(10);
    const values = useAppSelector((state) => state.ZoneReducer.filterZone)
    // fontion pour afficher le tableau de n'interface
    const {
        DataTable,
    } = useDataTableMedia({
        columns: columns,
        dataSource: data,
        updateEntityPath: 'update-product',
    });
    const onSearch = (e:any) => {
        setSearchText(e.target.value);
        handleSearchRow(e.target.value)
        getAllData(currentPage,pageSize,e.target.value)
        setCurrentPage(1)
}

    return(
        <div >

            <div className="Filter">


               {!openDel?
                        null
                        :
                        <div className="delete_number">
                            <Tooltip title={t('Zone.delete')}>
                                <Button style={{border: "1px solid rgba(0 , 0 , 0 , 0.15)"}} color="inherit" startIcon={<DeleteIcon/>} onClick={() =>{
                                dispatch(setOpenModalDel(true));
                                setOpenModal(true)
                             }}/>
                            </Tooltip>
                            <p><span>{numberOfselectedRows || ""}</span> <span id={"text_selection"}>{t('Zone.selectedItem')}</span></p>
                        </div>
                }
                {openModal && (    
                    <ModalConfirm
                        open={openModal}
                        onClose={()=>setOpenModal(false)}
                        title={t('Zone.Delete')}
                        content={numberOfselectedRows === 1 ?t('Zone.DeleteConfirmationOneDelete'):t('Zone.DeleteConfirmationMultiDelete')}
                        fullWidth={true}
                        CancelBtnName={t('boitier.No')}
                        ConfirmBtnName={t('boitier.Yes')}
                        onCancel={()=>setOpenModal(false)}
                        onConfirm={() => 
                        {
                            Deletemultipleselecteditem()
                            dispatch(setOpenModalDel(false))
                            setOpenModal(false)
                        }}
                    />)}
           
                <div className={"div_rect"}>
                    <div className="rect_filter">
                      <SearchBar name={"search"}
                       className="rect_filter_search"
                                placeholder={t('Zone.search')}
                            setSearchText={onSearch}
                            value={serachText}
                           />

                    </div>
                </div>
                
            </div>

            <div className={"dataTable"}>
                <DataTable/>
            </div>


        </div>
    )
}

export default ZoneTable
