import React from 'react'
import { useAppSelector } from '../../../Redux/hooks'
import { Modal, Button, Input } from "antd";
import { useTranslation } from 'react-i18next';
import moment from "moment";
import { ModalInputs } from '@empreinte/components.modal-inputs';
import { InputText } from '@empreinte/components.input-text';
import { Label } from '@empreinte/components.label';

const Detail = ({ visible, handleCancel }: Visible) => {
  const { t } = useTranslation();
  //const {handleCancel}= Hooks();
  const { GroupeRmBox } = useAppSelector(state => state.GroupeRmBoxReducer)
  const converTime = (time: any) => {
    return moment(time).format("yyyy-mm-DD HH:mm:ss")
  }

  return (
    <>
      <ModalInputs
      className="modal"
      open={visible}
      title={t("boitier.Rmboxgroup.showModalTitle") + `" ${GroupeRmBox?.name} "`}
        onCancel={handleCancel}
        onClose={handleCancel}
        CancelBtnName={t("boitier.close")}
        headerStyle={{
          color:"white"
        }}
        style={{
          paddingRight:"24px",
              paddingLeft:"24px",
              paddingTop:"5px",
              paddingBottom:"8px",
              marginBlockStart:"24px",
              zIndex:"22",
        }}
        children={
          <div className={"detailsGroupeRmBoxDivGlobal"}>
          <div className={"detailsGroupeRmBoxDiv"}>
            <Label className={"detailsGroupeRmBoxSpan1"}>
              {t("boitier.Rmboxgroup.Header.id")}
            </Label>
            <InputText
             fullWidth={true}
              className={"detailsGroupeRmBoxSpan2"}
              placeholder="Basic usage"
              value={GroupeRmBox?.id}
            />
          </div>
          <div className={"detailsGroupeRmBoxDiv"}>
            <Label className={"detailsGroupeRmBoxSpan1"}>
              {t("boitier.Rmboxgroup.Header.name")}
            </Label>
            <InputText
             fullWidth={true}
              className={"detailsGroupeRmBoxSpan2"}
              placeholder="Basic usage"
              value={GroupeRmBox?.name}
            />
          </div>
          <div className={"detailsGroupeRmBoxDiv"}>
            <Label className={"detailsGroupeRmBoxSpan1"}>
              {t("boitier.Rmboxgroup.Header.bandwidth")}
            </Label>
            <InputText
             fullWidth={true}
              className={"detailsGroupeRmBoxSpan2"}
              placeholder="Basic usage"
              value={GroupeRmBox?.bandwidthLimit}
            />
          </div>
          <div className={"detailsGroupeRmBoxDiv"}>
            <Label className={"detailsGroupeRmBoxSpan1"}>
              {t("boitier.Rmboxgroup.Header.Synchro_schedule3")}
            </Label>
            <InputText
             fullWidth={true}
              className={"detailsGroupeRmBoxSpan2"}
              placeholder=""
              value={converTime(GroupeRmBox?.syncTime)}
            />
          </div>

        </div>
        }
        />
    </>
  )
}


interface Visible {
  visible: boolean;
  handleCancel: any
}
export default Detail
