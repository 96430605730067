import React,{useEffect,useState} from "react";
import { Tooltip, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import useDataTableMedia from "./DataTable";
// import * as DataUser from '../../utils/Data';
import { useAppSelector } from "../../../../Redux/hooks";
import { setFilterGroupeRmBoxActions ,setOpenDelModal} from "../../store/GroupeRmBoxActions";
import { useDispatch } from "react-redux";
import { DataGroupeRmBox } from "../../utils/Data";
import { useTranslation } from "react-i18next";
import Hooks from "../../utils/hooks";
import "../../GroupeRmBox.scss";
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from "@empreinte/components.button";
import { ModalConfirm } from "@empreinte/components.modal-confirm";
import { SearchBar } from "@empreinte/components.search-bar";
const { Search } = Input;
const GroupeRmBoxTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const valuePagination = useAppSelector(
    (state) => state.GroupeRmBoxReducer.paginationPropsGroupeRmBox
  );
  const { columns } = DataGroupeRmBox();

  const { handleSearchRow ,deleteMultiGroupeRmBox,getListGroupeRmBox} = Hooks();
const [openModal,setOpenModal]=useState(false)
const [currentPage,setCurrentPage]=useState(1);
const [pageSize,setPageSize]=useState(10)
const isSelected = useAppSelector((state)=>state.GroupeRmBoxReducer.selectedCase)
const openDel = useAppSelector((state)=>state.GroupeRmBoxReducer.openSpaceDel);
const numberOfselectedRows = isSelected.length;
const [searchText,setSearchText]=useState('');
  // fontion pour afficher le tableau de n'interface
  const { DataTable } = useDataTableMedia({
    columns: columns,
    updateEntityPath: "update-product",
  });

  //  get data from db

  const onSearch = (e:any) => {
    setSearchText(e.target.value);
    handleSearchRow(e.target.value)
    getListGroupeRmBox(currentPage,pageSize,e.target.value)
    setCurrentPage(1)
}

  return (
    <div>
      <div className="Filter">
        {!openDel ? null : (
          <div className="delete_number">
            <Tooltip title={t('boitier.delete')}>
                    <Button style={{border: "1px solid rgba(0 , 0 , 0 , 0.15)"}} 
                    color="inherit" 
                    startIcon={<DeleteIcon/>} 
                      onClick={()=>{
                      dispatch(setOpenDelModal(true));
                      setOpenModal(true)
                    }
                  }
                  />
                </Tooltip>
            <p>
              <span>{numberOfselectedRows || ""}</span>{" "}
              <span id={"text_selection"}>{t("boitier.selectedItem")}</span>
            </p>
          </div>
        )}
        {openModal && (
          <ModalConfirm
          open={openModal}
          onClose={()=>setOpenModal(false)}
          title={t('boitier.delete')}
          content={numberOfselectedRows === 1 ?t('Zone.DeleteConfirmationOneDelete'):t('Zone.DeleteConfirmationMultiDelete')}
          fullWidth={true}
          CancelBtnName={t('boitier.No')}
          ConfirmBtnName={t('boitier.Yes')}
          onCancel={()=>setOpenModal(false)}
          onConfirm={() => 
          {
            deleteMultiGroupeRmBox()
            dispatch(setOpenDelModal(false))
            setOpenModal(false)
          }}
          />
        )}
        <div className={"div_rect"}>
          <div className="rect_filter">
          <SearchBar
             name={"search"}
             placeholder={t('boitier.AuthorizedIP.search')}
            //  onKeyDown={(e) => handleSearchRow(e)}
             setSearchText={onSearch}
             className="rect_filter_search"
             value={searchText}
            />
          </div>
        </div>
      </div>
      {/*./Filter*/}

      <div className={"dataTable"}>
        <DataTable />
      </div>
      {/*./dataTable*/}
    </div>
  );
};

export default GroupeRmBoxTable;
