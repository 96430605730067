import React from 'react'
import { Breadcrumb, 
    // Button,
     Form } from "antd";
import { PlusSquareOutlined, CheckCircleOutlined, CheckOutlined, CloseOutlined, HomeOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../Redux/hooks";
import Hooks from "../utils/hooks";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const ZoneHeader = () => {
    // const navigate=useNavigate()
    const { t } = useTranslation();

    const { handleAddZone, onResetZone } = Hooks()
    const showDivCondition = useAppSelector((state) => state.ZoneReducer.showDivsConditionsZone)
    const disablesubmitform =  useAppSelector((state) => state.ZoneReducer.showDivsConditionsZone.disablesubmitform)

    return (
        <div>
            <div className={"add_div"}>

                <div>
                    {
                        !showDivCondition.addZone && !showDivCondition.editZone
                            ?
                            <span className={"profile_title"}>{t('Zone.zone')}</span>
                            : showDivCondition.addZone ?
                            <> <ArrowLeftOutlined className='arrowleft' onClick={onResetZone}  /> <span className={"profile_title"}>{t('Zone.addzone')}</span></>  :
                                showDivCondition.editZone ?
                                   <> <ArrowLeftOutlined className='arrowleft' onClick={onResetZone}  /><span className={"profile_title"}>{t('Zone.modifzone')}</span></>  : ""

                    }

                </div>{/*DIVTITLEHEADER*/}

                <div className={"div_btn_add_cancel"}>

                    {
                        !showDivCondition.addZone && !showDivCondition.editZone
                            ?<Button className="btnSubmit" variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddZone}>
                            <span className="spn_add">{t('Zone.add')}</span>
                            </Button>
                            :
                            <div className={"div_btn_add_cancel"}>
                                
                                <Button className="btnCancel" variant="contained" color="inherit" startIcon={<CloseIcon />} onClick={onResetZone}>
                                        <span className="spn_cancel">{t('Zone.cancel')}</span>
                                    </Button>

                        <Button
                            className="btnSubmit"
                            variant="contained"
                            color="primary"
                            startIcon={<CheckIcon />}
                            type="submit"
                            name="submit"
                            disabled={disablesubmitform}
                        >
                            <span className="spn_add">{t('Zone.validate')}</span>
                        </Button>
                                                                
                
                            </div>
                    }
                </div>

            </div>
        </div>
    )
}

export default ZoneHeader
