
export const InterventionList = () => {

    const FilterIntervention = () => {
        return (
            {
                search: "",
               
            }
        )
    }

    const paginationPropsIntervention = () => {
        return (
            {
                order: 'descend',
                pageSize: 10,
                columnKey: 0,
                current: 1,
                id: [],
                idLive: 0,
            }
        )
    }

    const showDivsConditionsIntervention = () => {
        return ({
            Authorized:false,
            Loading:true,
            loader:false,
            elementSelected: 0,
            addIntervention: false,
            editIntervention: false,
            disablesubmitform: true,
        })
    }

    const AddInterventionConstraintsData = () => {
        return (
            {
                id: "",
                remarque: "",
                receptionDate: "",
                sendDate: "",
                oldMac: "",
                firstStartedDate: "",
                created_at: "",
                newMac: "",
                rmbox_name: "",
                rmbox:"",
                rmbox_id: "",
                rmbox_ip: "",
            }
        )
    }

    const DataIntervention = () => {
        return []
    }
    const DetailIntervention = () => {
        return []
    }



    return ({
        paginationPropsIntervention,
        showDivsConditionsIntervention,
        AddInterventionConstraintsData,
        FilterIntervention,
        DataIntervention,
        DetailIntervention
    })
}
