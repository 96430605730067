import React, { useEffect, useState } from "react";
import { Alert } from 'antd';
import { useAppSelector } from "../../../Redux/hooks";
import { setErrorrMessageRmboxActions, setSuccessRmboxActions } from "../store/rmboxActions"
import { useDispatch } from "react-redux";
const DisplayAlert = (props: any) => {
    const dispatch = useDispatch()
    const [showalert, setShowalert] = useState(false)
    const [showalertsuccess, setShowalertsuccess] = useState(false)
    const errormessage = useAppSelector((state) => state.RmboxReducer.errormessage)
    const successmessage = useAppSelector((state) => state.RmboxReducer.successmessage)
    useEffect(() => {
        if (errormessage !== "") {
            setShowalert(true)
        }
        if(successmessage !== ""){
            setShowalertsuccess(true)
        }
        setTimeout(function () {
            setShowalert(false)
            setShowalertsuccess(false)
            dispatch(setErrorrMessageRmboxActions(""));
            dispatch(setSuccessRmboxActions(""));

        }, 4000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errormessage, successmessage]);

    const handleClose = () => {
        setShowalert(false);
      };
      const handleClosesuccess = () => {
        setShowalert(false);
      };
    return (
        <div>
            {showalert ? <Alert message={errormessage} type="error" closable afterClose={handleClose} /> : null}
            {showalertsuccess ? <Alert message={successmessage} type="success" closable afterClose={handleClosesuccess} /> : null}
        </div>
    )
}

export default DisplayAlert
