import { useEffect } from "react";
import HeaderGroup from "./components/Header"
import './GroupeRmBox.scss'
import GroupeRmBoxTable from "./components/List/GroupeRmBoxTable";
import Hooks from "./utils/hooks";
import { Form } from "antd";
import AddEditGroupeRmBox from "./components/AddEdit/AddEditGroupeRmBox";
import { useAppSelector } from "../../Redux/hooks";
import LoadingScreen from "../LoadingScreen";
import NotFound from "../NotFound";
import { getUserData } from "../../userData/action";
import { useDispatch } from "react-redux";
import { Loader } from "@empreinte/components.loader";
const GroupeRmBox = () => {
  const {onFinishAddGroupeRmBox,onFinishFailedAddGroupeRmBox,onValueFormUserchangeGroupeRmBox,convertTime,formAddGroupeRmBox,values}=Hooks()
const showDivCondition =useAppSelector((state:any) =>state.GroupeRmBoxReducer.showDivsConditionsGroupeRmBox)
const loadingscreen = useAppSelector((state)=>state.ReducerUserData.loading_screen)
const unauthorizedUser = useAppSelector((state)=>state.ReducerUserData.unauthorizedUser)
const loader = showDivCondition.loader
const dispatch = useDispatch()

useEffect(() => {
  dispatch(getUserData());
}, []);
useEffect(()=>{

},[values])

useEffect(()=>{
  if (showDivCondition.editGroupeRmBox) {
    formAddGroupeRmBox.setFieldsValue({
      id: values.id,
      name: values.name,
      bandwidthLimit: values.bandwidthLimit,
      programs: values.programs,
      schedule: values.schedule,
      syncTime: values.syncTime,
    });
  }
  if (showDivCondition.addGroupeRmBox) {
    formAddGroupeRmBox.resetFields();
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [showDivCondition.editGroupeRmBox, showDivCondition.addGroupeRmBox,unauthorizedUser]);

if (loadingscreen) {
  return <LoadingScreen/>
} 
else if (unauthorizedUser) {
  return <NotFound/>
}

  return (
    <div className={"divGlobalTable_GrpRmBox"}>
     <Loader isOpen={loader}/>
  {!showDivCondition.addGroupeRmBox && !showDivCondition.editGroupeRmBox ? (
    <div>
       <HeaderGroup/>
      <GroupeRmBoxTable />
    </div>
  ) : (
    <div>
      
      <form onSubmit={onFinishAddGroupeRmBox}>
        <HeaderGroup/>
        <AddEditGroupeRmBox />
        </form>
        
     </div>
  )
}
  
  </div>

  )
}
 
export default GroupeRmBox
