
import { useAppSelector } from '../../../Redux/hooks'
import {Modal, Button,Input } from "antd";
import { useTranslation } from 'react-i18next';
import { ModalInputs } from '@empreinte/components.modal-inputs';

const Detail = ({visible,handleCancel}:Visible) => {
    const { t } = useTranslation();
//const {handleCancel}= Hooks();
  const {AuthorizedIP}= useAppSelector(state=>state.AuthorizedIPReducer)
  return (
    <>

        <ModalInputs
                className={'modal'}
                open={visible}
                title={t("boitier.AuthorizedIP.showModalTitle") + `"${AuthorizedIP?.ip}"`}
                onCancel={handleCancel}
                  dividers
        
                  onClose={handleCancel}
                  CancelBtnName=  {t("boitier.close")}
                  headerStyle={{
                    color:"white"
                  }}
                  style={{
                    paddingRight:"24px",
                        paddingLeft:"24px",
                        paddingTop:"5px",
                        paddingBottom:"8px",
                        marginBlockStart:"24px"
                  }}
                  children={  
                    <div className={"detailsAuthorizedIPDivGlobal"}>
                    <div className={"detailsAuthorizedIPDiv"}>
                      <label className={"detailsAuthorizedIPSpan1"}>
                        {t("boitier.AuthorizedIP.Header.id")}
                      </label>
                      <Input
                        className={"detailsAuthorizedIPSpan2"}
                        placeholder="Basic usage"
                        value={AuthorizedIP?.id !== null ? AuthorizedIP?.id : null}
                        readOnly={true}
                      />
                    </div>
                    <div className={"detailsAuthorizedIPDiv"}>
                      <label className={"detailsAuthorizedIPSpan1"}>
                        {t("boitier.AuthorizedIP.Header.ip")}
                      </label>
                      <Input
                        className={"detailsAuthorizedIPSpan2"}
                        placeholder=""
                        value={AuthorizedIP?.ip !== null ? AuthorizedIP?.ip : null}
                        readOnly={true}
                      />
                    </div>
                    <div className={"detailsAuthorizedIPDiv"}>
                      <label className={"detailsAuthorizedIPSpan1"}>
                        {t("boitier.AuthorizedIP.Header.description")}
                      </label>
                      <Input
                        className={"detailsAuthorizedIPSpan2"}
                        placeholder=""
                        value={AuthorizedIP?.description !== null ? AuthorizedIP?.description : null}
                        readOnly={true}
                      />
                    </div>
                   
                  </div>

                     }
                  />
    </>
  )
}


interface Visible {
  visible: boolean;
  handleCancel:any
}
export default Detail
