
import './App.css';
import "./i18n";
import AuthorizedIP from './MenuBoitier/AuthorizedIp/AuthorizedIP';
import GroupeRmBox from './MenuBoitier/GroupeRmBox/GroupeRmBox';
import Gridvisualization from './MenuBoitier/RmBox/components/gridvisualization';
import Rmbox from './MenuBoitier/RmBox/rmbox';
import Zone from './MenuBoitier/Zone/zone';
import NotFound from './MenuBoitier/NotFound';
import {

  Routes,
  Route,


} from "react-router-dom";
import i18next from 'i18next';
import { useEffect } from 'react';

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const submenu = urlParams.get('submenu');

  useEffect(()=>{

  const lang = urlParams.get('lang')

    if(lang==="en" || lang==="fr"){
      i18next.changeLanguage(lang)
    }
  },[urlParams.get('lang')])
  
  return (
      <div className="App">
          <Routes>
            <Route path="boitiers/ip_boitier" element ={ <AuthorizedIP />}/>
            <Route path="boitiers/groupeRmBox_boitier" element ={ <GroupeRmBox />}/>
            <Route path="boitiers/zone_boitier" element ={ <Zone />}/>
            <Route path="boitiers/rmbox_boitier" element ={ <Rmbox />}/>
            <Route path="boitiers/gridvisualisation" element={<Gridvisualization />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
        </div>
        
      

  );
}

export default App;
