import { useEffect, useState ,useLayoutEffect} from 'react'
import ZoneHeader from './components/zoneHeader';
import ZoneTable from "./components/List/zoneTable";
import AddEditZone from "./components/AddEdit/addEdit";
import './zone.scss'
import { useAppSelector } from "../../Redux/hooks";
import Hooks from "./utils/hooks";
import { Form, ConfigProvider } from "antd";
import frFR from 'antd/lib/locale/fr_FR';
import { getUserData } from '../../userData/action';
import { useDispatch } from 'react-redux';
import LoadingScreen from '../LoadingScreen';
import NotFound from '../NotFound';
import { Loader } from '@empreinte/components.loader';

const Zone = () => {
    const dispatch = useDispatch()
    const [locale] = useState<any>(frFR);

    const { formAddZone, onFinishAddZone, onFinishFailedAddZone, onValueFormUserchangeZone, getAllData, getlistrmbox } = Hooks()
    const showDivCondition = useAppSelector((state) => state.ZoneReducer.showDivsConditionsZone)

    const values = useAppSelector((state) => state.ZoneReducer.addZoneConstraintsData)
    const currentPage = useAppSelector((state)=>state.ZoneReducer.paginationPropsZone.current)
    const pageSize = useAppSelector((state)=>state.ZoneReducer.paginationPropsZone.pageSize)
    const loader = useAppSelector((state)=>state.ZoneReducer.showDivsConditionsZone.loader)
    const [searchWord,setSearchWord]= useState('')
    const loadingscreen = useAppSelector(
        (state) => state.ReducerUserData.loading_screen
      );
      const unauthorizedUser = useAppSelector(
        (state) => state.ReducerUserData.unauthorizedUser
      );
 
      useEffect(() => {
        dispatch(getUserData());
      }, []);
      useEffect(()=>{
        if (!showDivCondition.editZone && !showDivCondition.addZone) {
            getAllData(currentPage,pageSize,searchWord)
           
            
        }
      },[currentPage,pageSize,searchWord,unauthorizedUser])
 
      useEffect(() => {
       
        if (showDivCondition.editZone) {
            
            getlistrmbox()
            formAddZone.setFieldsValue({
                id: values.id,
                name: values.name,
                path: values.path,
                ipStart: values.ipStart,
                ipEnd: values.ipEnd,
                bandwidthLimit: values.bandwidthLimit,
                zonelimit: values.zonelimit,
                usersimultane: values.usersimultane,
                bandwidthDisponible: values.bandwidthDisponible,
                rmbox: values.rmbox,
                "rmboxselected": values.rmbox,
            })
        }

        if (showDivCondition.addZone) {
            getlistrmbox()
            formAddZone.setFieldsValue({
                id: "",
                name: "",
                path: "",
                ipStart: "",
                ipEnd: "",

                bandwidthLimit: "",
                zonelimit: "",
                usersimultane: "",
                bandwidthDisponible: "",
                rmbox: undefined,
                rmboxselected: undefined,

            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDivCondition.editZone, showDivCondition.addZone])


    if (loadingscreen) {
        return <LoadingScreen/>
      } 
      else if (unauthorizedUser) {
        return <NotFound/>
    }
    
    return (
        <ConfigProvider locale={locale}>
        <div className={"divGlobalTable"}>
        <Loader isOpen={loader}/>
       
            {
                !showDivCondition.addZone && !showDivCondition.editZone
                    ?
                    <div>
                        <ZoneHeader />
                        <ZoneTable />
                    </div>
                    :
                    <div>
                        <Form
                            name="basic"
                            layout="vertical"
                            initialValues={{ remember: true }}
                            onFinish={onFinishAddZone}
                            onFinishFailed={onFinishFailedAddZone}
                            onValuesChange={onValueFormUserchangeZone}
                            autoComplete="off"
                            className={"ProfileForm"}
                            form={formAddZone}
                        >
                            <ZoneHeader />
                            <AddEditZone />
                        </Form>
                    </div>
            }

        </div>
        </ConfigProvider>
    )
}

export default Zone
