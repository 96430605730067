import React,{useEffect,useState} from "react";
import { Tooltip,Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import useDataTableMedia from "./DataTable";
import { useAppSelector } from "../../../../Redux/hooks";
import { setFilterAuthorizedIPActions ,setOpenDelete} from "../../store/AuthorizedIPActions";
import { useDispatch } from "react-redux";
import { DataAuthorizedIP } from "../../utils/Data";
import { useTranslation } from "react-i18next";
import Hooks from "../../utils/hooks";
import { SearchBar } from "@empreinte/components.search-bar";
import { Button } from "@empreinte/components.button";
import {ModalConfirm} from "@empreinte/components.modal-confirm"
import DeleteIcon from '@mui/icons-material/Delete';
import "../../AuthorizedIP.scss";
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 1;
const { Search } = Input;
const AuthorizedIPTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const valuePagination = useAppSelector(
    (state) => state.AuthorizedIPReducer.paginationPropsAuthorizedIP
  );
  const { columns } = DataAuthorizedIP();

  const { handleSearchRow ,getListAuthorizedIP,deleteMultiAuthorizedIP} = Hooks();
  const data = useAppSelector((state)=>state.AuthorizedIPReducer.List)
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [openModal,setOpenModal] = useState(false)
  const openDel = useAppSelector((state)=>state.AuthorizedIPReducer.showDeleteSpace);
  const isSelected = useAppSelector((state)=>state.ZoneReducer.selectedCase)
  const numberOfselectedRows = isSelected.length;
  const searchWord = useAppSelector((state)=>state.AuthorizedIPReducer.filterAuthorizedIP.type)
  const [searchKeyword, setSearchKeyword] = useState("");

  // fontion pour afficher le tableau de n'interface
  const { DataTable } = useDataTableMedia({
    columns: columns,
    updateEntityPath: "update-product",
    dataSource: data,
  });

  //  get data from db

  const onSearch = (e: any) => {
    setSearchKeyword(e.target.value)
    handleSearchRow(e)
    getListAuthorizedIP(currentPage,pageSize,e.target.value)
    setCurrentPage(1)
  };
  return (
    <div>
      <div className="Filter">
        {!openDel 
        ? null : (
          <div className="delete_number">
            <Tooltip title={t('Zone.delete')}>
                                <Button style={{display: "flex", alignItems:"center",border: "1px solid rgba(0 , 0 , 0 , 0.15)"}} color="inherit" startIcon={<DeleteIcon/>} onClick={() =>{
                                  dispatch(setOpenDelete(true));
                                  setOpenModal(true)
                             }}/>
                            </Tooltip>
            <p>
              <span>{numberOfselectedRows || ""}</span>{" "}
              <span id={"text_selection"}>{t("boitier.selectedItem")}</span>
            </p>
          </div>

        )}
            {openModal && (    
        <ModalConfirm
            open={openModal}
            onClose={()=>setOpenModal(false)}
            title={t('boitier.AuthorizedIP.Delete')}
            content={numberOfselectedRows === 1 ?t('boitier.AuthorizedIP.DeleteConfirmationOneDelete'):t('boitier.AuthorizedIP.DeleteConfirmationMultiDelete')}
            fullWidth={true}
            CancelBtnName={t('boitier.No')}
            ConfirmBtnName={t('boitier.Yes')}
            onCancel={()=>setOpenModal(false)}
            onConfirm={() => 
            {
              deleteMultiAuthorizedIP()
                dispatch(setOpenDelete(false))
                setOpenModal(false)
            }}
        />)}
        <div className={"div_rect"}>
          <div className="rect_filter">
            <SearchBar
             name={"search"}
             placeholder={t('boitier.AuthorizedIP.search')}
             onKeyDown={(e) => handleSearchRow(e)}
             setSearchText={onSearch}
             className="rect_filter_search"
            />
          </div>
        </div>
      </div>
      <div className={"dataTable"}>
        <DataTable />
      </div>
    </div>
  );
};

export default AuthorizedIPTable;
