import React,{useState} from "react";

import { Tooltip} from "antd";
import { useTranslation } from "react-i18next";
import { Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  BorderColor as BorderColorIcon } from '@mui/icons-material';
import { setOpenDelete } from "../../store/AuthorizedIPActions";
import {ModalConfirm} from "@empreinte/components.modal-confirm"
import { useDispatch } from "react-redux";
import Hooks from "../../utils/hooks";

import "../../AuthorizedIP.scss";
import Detail from "../../Modal/Detail.modal";

function useActionMenu({ record }: any) {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const { handleShowModal, handleEditAuthorizedIP,visible ,handleCancel,deleteAuthorizedIP} = Hooks(); 
  const [openModal,setOpenModal] = useState(false)

  return (
    <div>
      <div className="action">
        <div>
          <Tooltip placement="top" title={t("boitier.details")}>
          <VisibilityIcon className={"icon_action"} onClick={()=>handleShowModal(record?.id)} />
          </Tooltip>
        </div>
        <div>
          <Tooltip placement="top" title={t("boitier.edit")}>
            <BorderColorIcon
              className={"icon_action"}
              onClick={() => { handleEditAuthorizedIP(record?.row)
              }}
            />
          </Tooltip>
        </div>
        <div>
          <Tooltip placement="top" title={t("boitier.delete")}>
            <DeleteIcon className={"icon_action"}   onClick={()=>setOpenModal(true)}/>
            <DeleteIcon className={"icon_action"}   onClick={()=>setOpenModal(true)}/>
          </Tooltip>
        </div>
        {/*Modal Details*/ }
        <Detail visible={visible} handleCancel={handleCancel}/>
          {/* Show delete confirmation modal*/}       
      {openModal && (    
            <ModalConfirm
                open={openModal}
                onClose={()=>setOpenModal(false)}
                title={t('boitier.AuthorizedIP.AuthorizedIP.Delete')}
                content={t('boitier.AuthorizedIP.DeleteConfirmationOneDelete')}
                fullWidth={true}
                CancelBtnName={t('boitier.No')}
                ConfirmBtnName={t('boitier.Yes')}
                onCancel={()=>setOpenModal(false)}
                onConfirm={() => 
                {
                    deleteAuthorizedIP(record.id)
                    dispatch(setOpenDelete(false))
                    setOpenModal(false)
                }}
            />)}
          {/* Show delete confirmation modal*/}       
      {openModal && (    
            <ModalConfirm
                open={openModal}
                onClose={()=>setOpenModal(false)}
                title={t('boitier.AuthorizedIP.AuthorizedIP.Delete')}
                content={t('boitier.AuthorizedIP.DeleteConfirmationOneDelete')}
                fullWidth={true}
                CancelBtnName={t('No')}
                ConfirmBtnName={t('Yes')}
                onCancel={()=>setOpenModal(false)}
                onConfirm={() => 
                {
                    deleteAuthorizedIP(record.id)
                    dispatch(setOpenDelete(false))
                    setOpenModal(false)
                }}
            />)}
      </div>
    </div>
  );
}

export default useActionMenu;
