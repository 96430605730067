import { useTranslation } from "react-i18next";
import { AlignType } from "rc-table/lib/interface";
import  moment  from "moment";

export const DataGroupeRmBox = () => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("boitier.Rmboxgroup.Header.id"),
      dataIndex: "id",
      field:t("boitier.Rmboxgroup.Header.id"),
      key:0,
      flex:1,
      sorter: (a: any, b: any) => {
        let fa = parseInt(a.id,10);
        let fb = parseInt(b.id,10);

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
      },
      renderCell:(record:any)=>{
        return(
          <label>{record?.id}</label>
        )
      },
      sortDirections: ["descend", "ascend", "descend"],

    },
    {
      title: t("boitier.Rmboxgroup.Header.name"),
      dataIndex: "name",
      field:t("boitier.Rmboxgroup.Header.name"),
      key:1,
      flex:1,
      align: "center" as AlignType,
      renderCell: (text: any) => {
        return <div className="tableTextAligne" >{text.row?.name}</div>
      },
      sorter: (a: any, b: any) => {
        let fa = a.name.toLowerCase();
        let fb = b.name.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
      },
      sortDirections: ["descend", "ascend", "descend"],
      //sortOrder:paginationProps.columnKey === "0" &&  paginationProps.order,
    },
    {
      title: t("boitier.Rmboxgroup.Header.bandwidth"),
      dataIndex: "bandwidthLimit",
      field:t("boitier.Rmboxgroup.Header.bandwidth"),
      key:2,
      flex:1,
      align: "center" as AlignType,
      renderCell: (text: any) => {
         return <div className="tableTextAligne" >{text?.row?.bandwidthLimit}</div>
      },
      sorter: (a: any, b: any) => {
        let fa = parseInt(a.bandwidthLimit,10);
        let fb = parseInt(b.bandwidthLimit,10);

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
      },
      sortDirections: ["descend", "ascend", "descend"],
      //sortOrder:paginationProps.columnKey === "2" &&  paginationProps.order,
    },
    {
      title: t("boitier.Rmboxgroup.Header.Synchro_schedule"),
      dataIndex: "syncTime",
      field:t("boitier.Rmboxgroup.Header.Synchro_schedule"),
      key:3,
      flex:1,
      align: "center" as AlignType,
      renderCell: (text: any) => {
        return <div className="tableTextAligne">{moment(text?.row?.syncTime).format("HH:mm:ss")}</div>
      },
      sorter: (a: any, b: any) => {
        let fa = moment(a.syncTime).format("HH:mm:ss");
        let fb = moment(b.syncTime).format("HH:mm:ss");
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
      },
      sortDirections: ["descend", "ascend", "descend"],
      //sortOrder:paginationProps.columnKey === "3" &&  paginationProps.order,
    },
    {
      title: t("boitier.Rmboxgroup.Header.Program_schedule"),
      dataIndex: "schedule",
      field:t("boitier.Rmboxgroup.Header.Program_schedule"),
      key:4,
      flex:1,
      align: "center" as AlignType,
      renderCell: (text: any) => {
        return <div className="tableTextAligne" >{text?.row?.schedule}</div>
      },
      sorter: (a: any, b: any) => {
        let fa = parseInt(a.schedule,10);
        let fb = parseInt(b.schedule,10);

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
      },
      sortDirections: ["descend", "ascend", "descend"],
      //sortOrder:paginationProps.columnKey === "3" &&  paginationProps.order,
    },
  ];

  return {
    columns,
  };
};
