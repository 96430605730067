import { AuthorizedIPList } from "./AuthorizedIP";
const {
  showDivsConditionsAuthorizedIP,
  AddAuthorizedIPConstraintsData,
  paginationPropsAuthorizedIP,
  FilterAuthorizedIP,
  AuthorizedIPTypes
} = AuthorizedIPList();

const AuthorizedIPListINITIALSTATE = {
  showDivsConditionsAuthorizedIP: showDivsConditionsAuthorizedIP(),
  paginationPropsAuthorizedIP: paginationPropsAuthorizedIP(),
  addAuthorizedIPConstraintsData: AddAuthorizedIPConstraintsData(),
  filterAuthorizedIP: FilterAuthorizedIP(),
  List: [],
  loading: false,
  msg: "",
  error: false,
  erros:[],
  AuthorizedIP:{},
  totalRecord:0,
  selectedCase:[],
  showDeleteSpace:false
};

export const AuthorizedIPReducer = (state = AuthorizedIPListINITIALSTATE, action: any) => {
  switch (action.type) {
    case "SET_FilterAuthorizedIP":
      const { FilterAuthorizedIPNameChange, FilterAuthorizedIPValueChange } =
        action.payload;
      return {
        ...state,
        filterAuthorizedIP: {
          ...state.filterAuthorizedIP,
          [FilterAuthorizedIPNameChange]: FilterAuthorizedIPValueChange,
        },
      };
    case "SET_PaginationPropsAuthorizedIP":
      const { PaginationPropsNameChange, PaginationPropsValueChange } =
        action.payload;
      return {
        ...state,
        paginationPropsAuthorizedIP: {
          ...state.paginationPropsAuthorizedIP,
          [PaginationPropsNameChange]: PaginationPropsValueChange,
        },
      };
    case "SET_showDivsConditionsAuthorizedIP":
      const { showDivsConditionsName, showDivsConditionsValue } =
        action.payload;
      const showDivsConditionsObj = {
        ...state.showDivsConditionsAuthorizedIP,
        [showDivsConditionsName]: showDivsConditionsValue,
      };
      return {
        ...state,
        showDivsConditionsAuthorizedIP: showDivsConditionsObj,
      };
    case "SET_AddAuthorizedIPActions":
      const {
        addAuthorizedIPConstraintsDataNameChange,
        addAuthorizedIPConstraintsDataValueChange,
      } = action.payload;
      return {
        ...state,
        addAuthorizedIPConstraintsData: {
          ...state.addAuthorizedIPConstraintsData,
          [addAuthorizedIPConstraintsDataNameChange]:
            addAuthorizedIPConstraintsDataValueChange,
        },
      };
    case AuthorizedIPTypes.LOADING:
      return {
        ...state,
        loading: true,
      };
    case AuthorizedIPTypes.SET_AuthorizedIP_DATA:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
      case "SET_RECORDS":
        return{
          ...state,
          totalRecord:action.payload
        }
      case "SET_OPENDEL":
          return{
              ...state,
              showDeleteSpace:action.payload
          }           
      case "SET_IDSELECTED":
        return{
            ...state,
            selectedCase:action.payload
        }        
    default: {
      return state;
    }
  }
};
