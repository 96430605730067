import { useEffect } from "react";
import { Form } from "antd";

import AddEditAuthorizedIP from "./components/AddEdit/AddEditAuthorizedIP";
import AuthorizedIPTable from "./components/List/AuthorizedIPTable";
import { useAppSelector } from "../../Redux/hooks";
import Header from "./components/Header"
import Hooks from "./utils/hooks";
import LoadingScreen from "../LoadingScreen";
import NotFound from "../NotFound";
import { getUserData } from "../../userData/action";
import { useDispatch } from "react-redux";
import './AuthorizedIP.scss'
import { Loader } from "@empreinte/components.loader";

const AuthorizedIP = () => {
  const {onFinishAddAuthorizedIP,onFinishFailedAddAuthorizedIP,onValueFormUserchangeAuthorizedIP,formAddAuthorizedIP}=Hooks()
const showDivCondition =useAppSelector((state:any) =>state.AuthorizedIPReducer.showDivsConditionsAuthorizedIP)
const loadingscreen = useAppSelector((state)=>state.ReducerUserData.loading_screen)
const unauthorizedUser = useAppSelector((state)=>state.ReducerUserData.unauthorizedUser)
const loader = showDivCondition.loader
const dispatch = useDispatch()

useEffect(() => {
  dispatch(getUserData());
}, []);

if (loadingscreen) {
  return <LoadingScreen/>
} 
else if (unauthorizedUser) {
  return <NotFound/>
}

  return (
     <div className={"divGlobalTable_AuthorizedIp"}>
      <Loader isOpen={loader}/>
  {!showDivCondition.addAuthorizedIP && !showDivCondition.editAuthorizedIP ? (
    <div>
      <Header isDisabled={true}/>
      <AuthorizedIPTable />
    </div>
  ) : (
    <div>
      <Form
        name="basic"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinishAddAuthorizedIP}
        onFinishFailed={onFinishFailedAddAuthorizedIP}
        onValuesChange={onValueFormUserchangeAuthorizedIP}
        autoComplete="off"
        className={"ProfileForm"}
        form={formAddAuthorizedIP}
     >
        <Header isDisabled={formAddAuthorizedIP.isFieldsTouched(true) ||
                  !!formAddAuthorizedIP.getFieldsError().filter(({ errors }) => errors.length).length}/>
        <AddEditAuthorizedIP />
     </Form>
     </div>
  )
}
  
  </div>
  )
}
export default AuthorizedIP
