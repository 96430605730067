
import { useTranslation } from "react-i18next";

export const DataIntervention = () => {

    const { t } = useTranslation();

    const columns = [
        {
            title: <label className="titlecolumn">{t('Intervention.remarque')}</label>,
            dataIndex: 'remarque',
            field:t('Intervention.remarque'),
            key:0,
            flex:1,
            sorter: (a: any, b: any) => {
                let fa = a.remarque.toLowerCase();
                let fb = b.remarque.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
            },
            renderCell:(record:any)=>{
                return(
                    <label>{record?.row?.remarque}</label>
                )
            },
            sortDirections: ['descend', 'ascend', 'descend',],
            className:"contentcolumn"
           
        },
       
        {
            title: <label className="titlecolumn">{t('Intervention.receptionDate')}</label>,
            field:t('Intervention.receptionDate'),
            key:1,
            flex:1,
            dataIndex: 'receptionDate',
            sorter: (a: any, b: any) => {
                let fa = a.receptionDate.toLowerCase();
                let fb = b.receptionDate.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
            },
            renderCell:(record:any)=>{
                return(
                    <label>{record?.row?.receptionDate}</label>
                )
            },
            sortDirections: ['descend', 'ascend', 'descend',],
            className:"contentcolumn"
            
        },

        {
            title: <label className="titlecolumn">{t('Intervention.sendDate')}</label>,
            field:t('Intervention.sendDate'),
            key:2,
            flex:1,
            dataIndex: 'sendDate',
            sorter: (a: any, b: any) => {
                let fa = a.sendDate.toLowerCase();
                let fb = b.sendDate.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
            },
            renderCell:(record:any)=>{
                return(<label>{record?.row?.sendDate}</label>)
                
            },
            sortDirections: ['descend', 'ascend', 'descend',],
            className:"contentcolumn"
            
        },

        {
            title: <label className="titlecolumn">{t('Intervention.mac')}</label>,
            field:t('Intervention.mac'),
            key:3,
            flex:1,
            dataIndex: 'newMac',
            sorter: (a: any, b: any) => {
                let fa = a.newMac.toLowerCase();
                let fb = b.newMac.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
            },
            renderCell:(record:any)=>{
                return(
                    <label>{record?.row?.newMac}</label>
                )
            },
            sortDirections: ['descend', 'ascend', 'descend',],
            className:"contentcolumn"
            
        },

        {
            title: <label className="titlecolumn">{t('Intervention.firstStartedDate')}</label>,
            field:t('Intervention.firstStartedDate'),
            key:4,
            flex:1,
            dataIndex: 'firstStartedDate',
            sorter: (a: any, b: any) => {
                let fa = a.firstStartedDate.toLowerCase();
                let fb = b.firstStartedDate.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
            },
            renderCell:(record:any)=>{
                return(
                    <label>{record?.row?.firstStartedDate}</label>
                )
            },
            sortDirections: ['descend', 'ascend', 'descend',],
            className:"contentcolumn"
        },
     

    ];
    
 
   
    return ({
        columns
    })

}
