import React, {  useEffect, useState } from 'react';
import { Table, ConfigProvider, } from 'antd';


// redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../Redux/hooks";
import { setPaginationPropsRmbox, setshowDivsConditionsRmbox,setSelected,setOpenDeleteSpace,setFilterRmboxActions,setDataRmboxActions} from "../../store/rmboxActions";
import UseActionMenu from "./actionUserTable";
import frFR from 'antd/lib/locale/fr_FR';
import { Datagrid } from '@empreinte/components.datagrid';
import { useTranslation } from "react-i18next";
import {GridNorows} from '@empreinte/components.grid-norows';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { useMediaQuery } from '@mui/material';
import Hooks from "../../utils/hooks"; 
moment.locale('en');


const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 1;

function useDataTableMedia({ columns, dataSource, updateEntityPath }: any) {
    const { t } = useTranslation();

    const { handelSearch,getAllData,refreshRmbox } = Hooks()
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

    const [selectedRow] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState<any>(DEFAULT_PAGE_NUMBER);
    const [pageSize,setPageSize] = useState<any>(DEFAULT_PAGE_SIZE);
   const totalength = useAppSelector((state)=>state.RmboxReducer.totalRecord)
    const [locale] = useState<any>(frFR);
    const tabletColumn = useMediaQuery('(min-width:780px)');
    const mobileColumn = useMediaQuery('(min-width:550px)');
    //const [actionColumnView] = useActionMenuTable({ selectedRow, updateEntityPath , record });

    // use Selector redux
    const valuePagination = useAppSelector((state) => state.RmboxReducer.paginationPropsRmbox)
    /// i add values 
    const values = useAppSelector((state) => state.RmboxReducer.filterRmbox)
    const searchWord= values.search
    const statusDel = useAppSelector((state)=>state.RmboxReducer.statusDel)

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        dispatch(
            setshowDivsConditionsRmbox({
                showDivsConditionsName: "loader",
                showDivsConditionsValue: true,
            })
        )
       
      return () => { 
        dispatch(setFilterRmboxActions({
            FilterRmboxNameChange: "search",
            FilterRmboxValueChange: ''
    }));
        dispatch(setDataRmboxActions([]))
        }
        }, [])
        useEffect(() => {
     
            // Check if there is no data on the current page
            if (currentPage > 1 && dataSource.length === 0) {
              setCurrentPage(1); // Assuming you have a state variable 'setCurrentPage' to update the page
            }
          }, [currentPage, dataSource]);

          
        useEffect(()=>{
        if(searchWord)
        // setCurrentPage(1)
        refreshRmbox()
        },[searchWord])

        useEffect(()=>{
            if(statusDel){
                setCurrentPage(1)
            }else{
                getAllData(currentPage,pageSize,searchWord)
            }
         
         },[currentPage,pageSize,totalength,statusDel])

    //const history = useNavigate();
    const dispatch = useDispatch();

    const onSelectChange = (selectedRowKeys: any, record: any) => {

        //uncheck checkbox
        let filter: Array<any>
        filter = [];
        filter = dataSource
            .filter((item: any) => {
                return !selectedRowKeys.includes(item.id);
            })
            .map((ele: any) => ele.id);
        //concatenate state and selectedcheckboxes
        selectedRowKeys = [...valuePagination.id, ...selectedRowKeys];

        //remove some items
        let uniqItems: Array<any>
        let uniqItemsFilter: Array<any>
        uniqItems = Array.from(new Set(selectedRowKeys))
        uniqItemsFilter = uniqItems.filter((item) => {
            return !filter.includes(item);
        });

        dispatch(
            setPaginationPropsRmbox({
                PaginationPropsNameChange: "id",
                PaginationPropsValueChange: uniqItemsFilter,
            })
        );
        dispatch(
            setshowDivsConditionsRmbox({
                showDivsConditionsName: "elementSelected",
                showDivsConditionsValue: uniqItemsFilter.length,
            })
        );

        setSelectedRowKeys(uniqItemsFilter);
    };

    const rowSelection = {
        selectedRowKeys: valuePagination.id,
        getCheckboxProps: (record: any) => ({
            disabled: record.owner === false, // Column configuration not to be checked
        }),
        onChange: onSelectChange,
    };

    const updatedColumns = [
        ...columns,
        {
            title: <label className="titlecolumn">{t('Rmbox.actions')}</label>,
            // key: 'action',
            key:4,
            flex:1,
            field:t('Rmbox.actions'),
            renderCell: (action: any, record: any) => {
                return (
                    <UseActionMenu record={action} />
                )
            },
            className:"contentcolumn",
            width:"35%",
            align: "start",

        },
    ];
    const filteredevents = handelSearch(values.search, dataSource)
    

   

    const resetPagination = () => {
        setCurrentPage(DEFAULT_PAGE_NUMBER);
    };

    const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        
        setCurrentPage(pagination.current - 1);
    };

    function getFilteredEvents(filteredEvents:any, e:any) {
        let tab = filteredEvents.filter((item:any) => {
          return e.includes(item.id);
        });
        let newArray = Array.from(tab, (item:any) => {
          return item.id;
        });
        return newArray;
      }

    const DataTable = () => (
        <div className="DataTable">
            <ConfigProvider locale={locale}>
            {!totalength || !filteredevents.length?
                 <GridNorows content={t('Zone.noRowsFound')} />
                :
                <Datagrid
                className={"DataTable__custom-row"}
                height={"calc(90vh - 100px)"}
                selectionModel={selectedRowKeys}
                totalEntities={totalength}
                checkboxSelect={e => {
                    let newArray = getFilteredEvents(filteredevents, e);
                    setSelectedRowKeys(e);
                    dispatch(setSelected(newArray));
                    dispatch(setOpenDeleteSpace(newArray.length > 0));
 
                    dispatch(setSelected(newArray));
                }}
                columns={
                    tabletColumn
                      ? updatedColumns
                      : mobileColumn
                        ?  
                          updatedColumns.filter(
                            column =>
                              column.dataIndex !== 'group'
                          ):
                           updatedColumns.filter(
                            column =>
                              column.dataIndex !== 'group' &&
                              column.dataIndex !== 'programs'
                          ).filter(
                            column =>
                              column.dataIndex !== 'group' &&
                              column.dataIndex !== 'programs' &&
                              column.dataIndex !== 'ip'
                          )
                  }
                // columns={updatedColumns}
                rows={dataSource && filteredevents}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setPageSize={setPageSize}
                pageSize={pageSize}
                ShowName={t('boitier.AuthorizedIP.rows')}
                EntriesName={t('boitier.AuthorizedIP.element')}
                OfPageName={t('boitier.AuthorizedIP.of')}
                />}
            </ConfigProvider>
        </div>
    );

    return {
        DataTable,

        selectedRow,
        selectedRowKeys,
        currentPage,
        pageSize,
        resetPagination,
    };
}

export default useDataTableMedia;
