import { useState } from 'react';
import { Tooltip,  Button, Modal } from 'antd';
import { Delete as DeleteIcon,
        Visibility as VisibilityIcon,
        BorderColor as BorderColorIcon, 
        InsertChart as InsertChartIcon } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import { setOpenModalDel } from '../../store/zoneActions';
import Hooks from "../../utils/hooks";
import '../../zone.scss'
import { useAppSelector } from "../../../../Redux/hooks"
import { ModalInputs } from '@empreinte/components.modal-inputs';
import {ModalConfirm} from "@empreinte/components.modal-confirm"
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';


function useActionMenu({ record }: any) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { handleShowModal, visible, handleCancel, handleEditZone, Deleteselecteditem } = Hooks()
    const datarecord = useAppSelector((state) => state.ZoneReducer.detailZone)
    const [openModal,setOpenModal] = useState(false)


    return (
        <div>
            <div className="action">
                <div><Tooltip placement="top" title={t("Zone.details")}><VisibilityIcon className={"icon_action"} onClick={() => handleShowModal(record.id)} /></Tooltip></div>
                <div><Tooltip placement="top" title={t("Zone.edit")}><BorderColorIcon className={"icon_action"} onClick={() => handleEditZone(record)} /></Tooltip></div>
                <div><Tooltip placement="top" title={t("Zone.delete")}><DeleteIcon className={"icon_action"} onClick={() =>{ dispatch(setOpenModalDel(true));
                                setOpenModal(true)}}/></Tooltip></div>
                <div><Tooltip placement="top" title={t("Zone.stats")}><InsertChartIcon className={"icon_action"}
                onClick={() =>{
                    handleEditZone(record);
                    navigate(`/stat/zone_by_id/${record.id}/audience`)
                }}
                /></Tooltip></div>


                {openModal && (    
                    <ModalConfirm
                        open={openModal}
                        onClose={()=>setOpenModal(false)}
                        title={t('Zone.Delete')}
                        content={t('Zone.DeleteConfirmationOneDelete')}
                        fullWidth={true}
                        CancelBtnName={t('boitier.No')}
                        ConfirmBtnName={t('boitier.Yes')}
                        onCancel={()=>setOpenModal(false)}
                        onConfirm={() => 
                        {
                            Deleteselecteditem(record?.id)
                            dispatch(setOpenModalDel(false))
                            setOpenModal(false)
                        }}
                    />)}



                {/*Modal Details*/}
                <ModalInputs
                className={'modal'}
                headerStyle={{
                    color:"white"
                  }}
                  open={visible}
                  dividers
                  title={`${t("Zone.modaledittitle")} "${datarecord.name}"`}
                  onClose={handleCancel}
                  CancelBtnName= {t("Rmbox.close")}
                  onCancel={handleCancel}
                  style={{
                    paddingRight:"24px",
                        paddingLeft:"24px",
                        paddingTop:"5px",
                        paddingBottom:"8px",
                  }}
                  children={     
                  <div className={"detailsUserDivGlobal"}>
                  <div className={"detailsUserDivconfig"}>
                      <label className={"detailsUserDiv_lbl1config"}>{t("Zone.identifiant")}</label>
                      <label className={"detailsUserDiv_lbl2config"}>{datarecord.id}</label>

                  </div>
                  <div className={"detailsUserDivconfig"}>
                      <label className={"detailsUserDiv_lbl1config"}>{t("Zone.name")}</label>
                      <label className={"detailsUserDiv_lbl2config"}>{datarecord.name}</label>

                  </div>
                  <div className={"detailsUserDivconfig"}>
                      <label className={"detailsUserDiv_lbl1config"}>{t("Zone.cheminUrl")}</label>
                      <label className={"detailsUserDiv_lbl2config"}>URL{/* {datarecord.value} */}</label>

                  </div>

                  <div className={"detailsUserDivconfig"}>
                      <label className={"detailsUserDiv_lbl1config"}>{t("Zone.adressIpStart")}</label>
                      <label className={"detailsUserDiv_lbl2config"}>{datarecord.ipStart}</label>

                  </div>
                  <div className={"detailsUserDivconfig"}>
                      <label className={"detailsUserDiv_lbl1config"}>{t("Zone.adressIpEnd")}</label>
                      <label className={"detailsUserDiv_lbl2config"}>{datarecord.ipEnd}</label>
                  </div>
                  <div className={"detailsUserDivconfig"}>
                      <label className={"detailsUserDiv_lbl1config"}>{t("Zone.rmbox")}</label>
                      {datarecord.rmbox !== null ? <label className={"detailsUserDiv_lbl2config"}>{datarecord.rmbox.name}</label>
                          : <label className={"detailsUserDiv_lbl2config"}>{datarecord.rmbox}</label>
                      }

                  </div>
                  <div className={"detailsUserDivconfig"}>
                      <label className={"detailsUserDiv_lbl1config"}>{t("Zone.bandwidthLimit")}</label>
                      <label className={"detailsUserDiv_lbl2config"}>{datarecord.bandwidthLimit}</label>
                  </div>
                  <div className={"detailsUserDivconfig"}>
                      <label className={"detailsUserDiv_lbl1config"}>{t("Zone.zonelimit")}</label>
                      <label className={"detailsUserDiv_lbl2config"}>{datarecord.zonelimit}</label>
                  </div>
                  <div className={"detailsUserDivconfig"}>
                      <label className={"detailsUserDiv_lbl1config"}>{t("Zone.usersimultane")}</label>
                      <label className={"detailsUserDiv_lbl2config"}>{datarecord.usersimultane}</label>
                  </div>
                  <div className={"detailsUserDivconfiglast"}>
                      <label className={"detailsUserDiv_lbl1config"}>{t("Zone.bandwidthDisponible")}</label>
                      <label className={"detailsUserDiv_lbl2config"}>{datarecord.bandwidthDisponible}</label>
                  </div>

              </div>}/>
                {/*Modal Details*/}
            </div>
        </div>
    );
}

export default useActionMenu;
