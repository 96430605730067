import {GroupeRmBoxList} from "./GroupeRmBox"
const {GroupeRmBoxTypes}=GroupeRmBoxList()
export function setPaginationPropsGroupeRmBox(e:any){
    return{
        type:"SET_PaginationPropsGroupeRmBox",
        payload:e,
    }
}

export function setshowDivsConditionsGroupeRmBox(e:any){
    return {
        type : "SET_showDivsConditionsGroupeRmBox",
        payload:e
    }
}

export function setAddGroupeRmBoxActions(e:any){
    return{
        type:"SET_AddGroupeRmBoxActions",
        payload:e,
    }
}

export function setFilterGroupeRmBoxActions(e:any){

    return{
        type:"SET_FilterGroupeRmBox",
        payload:e,
    }

}

export function setEditGroupeRmBoxActions(e:any){
    return{
        type:"SET_EditGroupeRmBoxActions",
        payload:e,
    }
}


export function setGroupeRmBoxDataActions(e:any){
    return{
        type:GroupeRmBoxTypes.SET_GroupeRmBox_DATA,
        payload:e,
    }
}
export function setOpenDelModal(e:any){
    return{
        type:"SET_OPEN_MODAL",
        payload:e
    }
}
export function setOpenDeleteSpace(e:any){
    return{
        type:"SET_OPENSPACE",
        payload:e
    }
}
export function setSelected(e:any){
    const listSelected = e.map((e:any)=>e);
    return {
        type:"SET_IDSELECTED",
        payload:listSelected,
    }
  }