import { useTranslation } from "react-i18next";

export const DataAuthorizedIP = () => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("boitier.AuthorizedIP.Header.id"),
      dataIndex: "id",
      field:t("boitier.AuthorizedIP.Header.id"),
      key:0,
      flex:1,
      renderCell:((record:any)=>{
          return(
              <p>{record?.id}</p>
          )
      }),
      sorter: (a: any, b: any) => {
        let fa = parseInt(a.id,10);
        let fb = parseInt(b.id,10);

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
      },
    },
    {
      title: t("boitier.AuthorizedIP.Header.ip"),
      dataIndex: "ip",
      field:t("boitier.AuthorizedIP.Header.ip"),
      key:1,
      flex:1,
      renderCell:((record:any)=>{
          return(
              <p>{record?.row?.ip}</p>
          )
      }),
      sorter: (a: any, b: any) => {
        let fa = a.ip.toLowerCase();
        let fb = b.ip.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
      },
      sortDirections: ["descend", "ascend"],
      //sortOrder:paginationProps.columnKey === "3" &&  paginationProps.order,
    },
    {
      title: t("boitier.AuthorizedIP.Header.description"),
      dataIndex: "description",
      field:t("boitier.AuthorizedIP.Header.description"),
      key:2,
      flex:1,
      renderCell:((record:any)=>{
          return(
              <p>{record?.row?.description}</p>
          )
      }),
      sorter: (a: any, b: any) => {
        let fa = parseInt(a.description,10);
        let fb = parseInt(b.description,10);

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
      },
      sortDirections: ["descend", "ascend", "descend"],
      //sortOrder:paginationProps.columnKey === "3" &&  paginationProps.order,
    },
  ];

  return {
    columns,
  };
};
