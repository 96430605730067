import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import moment from 'moment';

import { Table, ConfigProvider, } from 'antd';
import 'moment/locale/zh-cn';
import { useTranslation } from 'react-i18next';
import { setPaginationPropsAuthorizedIP, setshowDivsConditionsAuthorizedIP,setSelected ,setOpenDelete,setFilterAuthorizedIPActions,setAuthorizedIPDataActions} from "../../store/AuthorizedIPActions";
import { useAppSelector } from "../../../../Redux/hooks";
import UseActionMenu from "./actionsUserTable";
import Hooks from "../../utils/hooks"; 
import { useMediaQuery } from '@mui/material';
import { Datagrid } from '@empreinte/components.datagrid';
import frFR from 'antd/lib/locale/fr_FR';
import { GridNorows } from '@empreinte/components.grid-norows';
import { DialpadSharp } from '@mui/icons-material';

moment.locale('en');
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 1;

function useDataTableMedia({ columns,  updateEntityPath }: any) {

    const { t } = useTranslation();
    const { handelFilter, handelSearch ,getListAuthorizedIP} = Hooks()

    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
    const [selectedRow] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState<any>(DEFAULT_PAGE_NUMBER);
    const [pageSize,setPageSize] = useState<any>(DEFAULT_PAGE_SIZE);
    const [locale] = useState<any>(frFR);
    const tabletColumn = useMediaQuery('(min-width:780px)');
    const mobileColumn = useMediaQuery('(min-width:550px)');
    // use Selector redux
    const valuePagination = useAppSelector((state) => state.AuthorizedIPReducer.paginationPropsAuthorizedIP)
    const List = useAppSelector((state) => state.AuthorizedIPReducer.List)
    /// add values 
    const values = useAppSelector((state) => state.AuthorizedIPReducer.filterAuthorizedIP)
    const unauthorizedUser = useAppSelector((state)=>state.ReducerUserData.unauthorizedUser)
  const totalength=useAppSelector((state)=>state.AuthorizedIPReducer.totalRecord)
  const searchWord = values.search
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(
            setshowDivsConditionsAuthorizedIP({
                showDivsConditionsName: "loader",
                showDivsConditionsValue: true,
             
            })
          )
        return ()=>{
            dispatch(
                setFilterAuthorizedIPActions({
                  FilterAuthorizedIPNameChange: "search",
                  FilterAuthorizedIPValueChange:''
                }) );
         dispatch(setAuthorizedIPDataActions([]))       
}
    },[])

    useEffect(()=>{
        getListAuthorizedIP(currentPage,pageSize,searchWord)
    },[currentPage,pageSize,totalength,unauthorizedUser])


    useEffect(()=>{
        if(searchWord)
        setCurrentPage(1)
    
        },[searchWord])
    useEffect(()=>{
        dispatch(
            setshowDivsConditionsAuthorizedIP({
                showDivsConditionsName: "loader",
                showDivsConditionsValue: true,
             
            })
          )
        return ()=>{
            dispatch(
                setFilterAuthorizedIPActions({
                  FilterAuthorizedIPNameChange: "search",
                  FilterAuthorizedIPValueChange:''
                }) );
         dispatch(setAuthorizedIPDataActions([]))       
}
    },[])

    useEffect(()=>{
        getListAuthorizedIP(currentPage,pageSize,searchWord)
    },[currentPage,pageSize,totalength,unauthorizedUser])


    useEffect(()=>{
        if(searchWord)
        setCurrentPage(1)
    
        },[searchWord])
    const onSelectChange = (selectedRowKeys: any, record: any) => {
    
        //uncheck checkbox
        let filter: Array<any>
        filter = [];
        filter = List
            .filter((item: any) => {
                return !selectedRowKeys.includes(item.id);
            })
            .map((ele: any) => ele.id);
        //concatenate state and selectedcheckboxes
        selectedRowKeys = [...valuePagination.id, ...selectedRowKeys];

        //remove some items
        let uniqItems: Array<any>
        let uniqItemsFilter: Array<any>
        uniqItems = Array.from(new Set(selectedRowKeys))
        uniqItemsFilter = uniqItems.filter((item) => {
            return !filter.includes(item);
        });

        dispatch(
            setPaginationPropsAuthorizedIP({
                PaginationPropsNameChange: "id",
                PaginationPropsValueChange: uniqItemsFilter,
            })
        );
        dispatch(
            setshowDivsConditionsAuthorizedIP({
                showDivsConditionsName: "elementSelected",
                showDivsConditionsValue: uniqItemsFilter.length,
            })
        );

        setSelectedRowKeys(uniqItemsFilter);
    };

    const rowSelection = {
        selectedRowKeys: valuePagination.id,
        getCheckboxProps: (record: any) => ({
            disabled: record.owner === false, // Column configuration not to be checked
        }),
        onChange: onSelectChange,
    };

    const updatedColumns = [
        ...columns,
        {
            field:'Action',
            dataIndex:'action',
            key:3,
            flex:1,
            renderCell: (action: any, record: any) => (
                <UseActionMenu  record={action} />    
            )
            ,
            align: "center",
            width:100 ,
            sortable:false,
            

        },
    ];
    const filteredeventsfilter = handelFilter(values.type, List)
    const filteredevents = handelSearch(values.search, filteredeventsfilter)
    
    const resetPagination = () => {
        setCurrentPage(DEFAULT_PAGE_NUMBER);
    };

    const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        setCurrentPage(pagination.current - 1);
    };
    function getFilteredEvents(filteredEvents:any, e:any) {
        let tab = filteredEvents.filter((item:any) => {
          return e.includes(item.id);
        });
        let newArray = Array.from(tab, (item:any) => {
          return item.id;
        });
        return newArray;
      }



    const DataTable = () => (
        <div className="DataTable">
            <ConfigProvider locale={locale}>
            {!filteredevents.length || !totalength ?
                <GridNorows  content={t('Zone.noRowsFound')} />
                :
                  <Datagrid
                className={"DataTable__custom-row"}
                height={"calc(90vh - 100px)"}
                columns={
                    tabletColumn
                      ? updatedColumns
                      : mobileColumn
                        ?  
                          updatedColumns.filter(
                            column =>
                              column.dataIndex !== 'ip' 
                          ):
                           updatedColumns.filter(
                            column =>
                              column.dataIndex !== 'ip' &&
                              column.dataIndex !== 'description'
                              
                          )
                  }
                totalEntities={totalength}
                selectionModel={selectedRowKeys}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setPageSize={setPageSize}
                pageSize={pageSize}
                ShowName={t('boitier.AuthorizedIP.rows')}
                EntriesName={t('boitier.AuthorizedIP.element')}
                OfPageName={t('boitier.AuthorizedIP.of')}
                rows={List && filteredevents}
                checkboxSelect={e => {
                    let newArray = getFilteredEvents(filteredevents, e);
                    setSelectedRowKeys(e);
                    dispatch(setSelected(newArray));
                    dispatch(setOpenDelete(newArray.length > 0));
 
                    dispatch(setSelected(newArray));
                }}
                />
                }
            </ConfigProvider>
        </div>
    );

    return {
        DataTable,
        selectedRow,
        selectedRowKeys,
        currentPage,
        pageSize,
        resetPagination,
    };
}

export default useDataTableMedia;
