import React,{useState} from "react";
import { Button, Input, Tooltip } from "antd";
import useDataTableMedia from "./DataTable";
// import * as DataUser from '../../utils/Data';
import { useAppSelector } from "../../../../Redux/hooks";
import { setFilterInterventionActions } from "../../store/interventionActions";
import { useDispatch } from "react-redux";
import { DataIntervention } from "../../utils/Data";
import { useTranslation } from "react-i18next";
import Hooks from "../../utils/hooks"
import { DeleteOutlined } from "@ant-design/icons";
import '../../intervention.scss'
import { SearchBar } from "@empreinte/components.search-bar";

const { Search } = Input;

const InterventionTable = () => {

    const dispatch = useDispatch()
    const { t } = useTranslation();
    // use Selector redux

    const { columns } = DataIntervention()
    const data = useAppSelector((state) => state.InterventionReducer.dataIntervention)
    const valuePagination = useAppSelector((state) => state.InterventionReducer.paginationPropsIntervention)
    const { getAllData,handleSearchRow ,Deleteselecteditem} = Hooks()
    const [searchText,setSearchText]=useState('')
    const [currentPage,setCurrentPage]=useState(1)
    const [pageSize,setPageSize]=useState(10)
    // use Selector redux

    // fonction pour afficher le tableau de l'interface
    const {
        DataTable,
    } = useDataTableMedia({
        columns: columns,
        dataSource: data,
        updateEntityPath: 'update-product',
    });

    const onSearch = (e:any) => {
        setSearchText(e.target.value);
        handleSearchRow(e.target.value)
        getAllData(currentPage,pageSize,e.target.value)
        setCurrentPage(1)
}


    return (
        <div >

            <div className="Filter">
                {valuePagination.id.length === 0 ? null : (
                    <div className="delete_number">
                        <Tooltip title="Supprimer">
                            <Button
                                style={{ border: "1px solid rgba(0 , 0 , 0 , 0.15)" }}
                                icon={<DeleteOutlined />}
                            onClick={()=>Deleteselecteditem(valuePagination.id)}
                            />
                        </Tooltip>
                        <p>
                            <span>{valuePagination.id.length || ""}</span>{" "}
                            <span id={"text_selection"}>{t("boitier.selectedItem")}</span>
                        </p>
                    </div>
                )}
                <div className={"div_rect"}>
                    <div className="rect_filter">
                     <SearchBar name={"search"}
                       className="rect_filter_search"
                                placeholder={t('Intervention.search')}
                            setSearchText={onSearch}
                            value={searchText}
                           />


                    </div>
                </div>
            </div>
            <div className={"dataTable"}>
                <DataTable />
            </div>
        </div>
    )
}

export default InterventionTable
