import React, { useEffect, useState } from 'react';
import { Table, ConfigProvider, Pagination, } from 'antd';
// redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../Redux/hooks";
import { setPaginationPropsZone, setshowDivsConditionsZone ,setSelected,setOpenDeleteSpace, setFilterZoneActions, setDataZoneActions} from "../../store/zoneActions";
import UseActionMenu from "./actionUserTable";
import frFR from 'antd/lib/locale/fr_FR';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import 'moment/locale/zh-cn';
import Hooks from "../../utils/hooks";
import {Datagrid} from '@empreinte/components.datagrid';
import {GridNorows} from '@empreinte/components.grid-norows';
import { useMediaQuery } from '@mui/material';

moment.locale('en');


const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 1;

function useDataTableMedia({ columns, dataSource, updateEntityPath }: any) {

    const { t } = useTranslation();
    const {handelFilter, handelSearch,getAllData } = Hooks()
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
    const [selectedRow] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState<any>(DEFAULT_PAGE_NUMBER);
    const [pageSize,setPageSize] = useState<any>(DEFAULT_PAGE_SIZE);
    const [locale] = useState<any>(frFR);
    const totalLength = useAppSelector((state)=>state.ZoneReducer.totalRecord);
    const tabletColumn = useMediaQuery('(min-width:780px)');
    const mobileColumn = useMediaQuery('(min-width:550px)');


    // use Selector redux
    const valuePagination = useAppSelector((state) => state.ZoneReducer.paginationPropsZone)
    /// i add values 
    const values = useAppSelector((state) => state.ZoneReducer.filterZone)
    const searchWord = useAppSelector((state)=>state.ZoneReducer.filterZone.search)

    const dispatch = useDispatch();

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        dispatch(
            setshowDivsConditionsZone({
                showDivsConditionsName:"loader",
            showDivsConditionsValue: true,
            }))
      return () => { 
        dispatch(setFilterZoneActions({               
        FilterZoneNameChange: "search",
        FilterZoneValueChange: ''
    }));
 dispatch(setDataZoneActions([]))
}
    }, [])

    useEffect(()=>{
        getAllData(currentPage,pageSize,searchWord)
    },[currentPage,pageSize,totalLength])

    useEffect(()=>{
    if(searchWord)
    setCurrentPage(1)

    },[searchWord])



    const onSelectChange = (selectedRowKeys: any, record: any) => {

        //uncheck checkbox
        let filter: Array<any>
        filter = [];
        filter = dataSource
            .filter((item: any) => {
                return !selectedRowKeys.includes(item.id);
            })
            .map((ele: any) => ele.id);
        //concatenate state and selectedcheckboxes
        selectedRowKeys = [...valuePagination.id, ...selectedRowKeys];

        //remove some items
        let uniqItems: Array<any>
        let uniqItemsFilter: Array<any>
        uniqItems = Array.from(new Set(selectedRowKeys))
        uniqItemsFilter = uniqItems.filter((item) => {
            return !filter.includes(item);
        });

        dispatch(
            setPaginationPropsZone({
                PaginationPropsNameChange: "id",
                PaginationPropsValueChange: uniqItemsFilter,
            })
        );
        dispatch(
            setshowDivsConditionsZone({
                showDivsConditionsName: "elementSelected",
                showDivsConditionsValue: uniqItemsFilter?.length,
            })
        );

        setSelectedRowKeys(uniqItemsFilter);
    };

    const rowSelection = {
        selectedRowKeys: valuePagination.id,
        getCheckboxProps: (record: any) => ({
            disabled: record.owner === false, // Column configuration not to be checked
        }),
        onChange: onSelectChange,
    };

    function getFilteredEvents(filteredEvents:any, e:any) {
        let tab = filteredEvents.filter((item:any) => {
          return e.includes(item.id);
        });
        let newArray = Array.from(tab, (item:any) => {
          return item.id;
        });
        return newArray;
      }


    const updatedColumns = [
        ...columns,
        {
            title: t('Zone.actions'),
            dataIndex:'action',
            field:  t('Zone.actions'),
            key:6,
            flex:1,
            renderCell: (action: any, record: any) => (
                    <UseActionMenu  record={action} />    
                )
            ,
            align: "center",
            width:100 ,
            sortable:false,
        },
    ];
    const filteredeventsfilter = handelFilter(values.type, dataSource)

    const filteredevents = handelSearch(values.search, filteredeventsfilter)

 
    const resetPagination = () => {
        setCurrentPage(DEFAULT_PAGE_NUMBER);
    };


    const DataTable = () => (
        <div className="DataTable">
            <ConfigProvider locale={locale}>
            {!filteredevents.length || !totalLength ?
                <GridNorows  content={t('Zone.noRowsFound')} />
                :
                <Datagrid
                height={"calc(90vh - 100px)"}
                columns={
                    tabletColumn
                      ? updatedColumns
                      : mobileColumn
                        ?  
                          updatedColumns.filter(
                            column =>
                              column.dataIndex !== 'rmbox' &&
                              column.dataIndex !== 'bandwidthLimit'
                          ):
                           updatedColumns.filter(
                            column =>
                              column.dataIndex !== 'rmbox' &&
                              column.dataIndex !== 'bandwidthLimit' &&
                              column.dataIndex !== 'zonelimit'
                          ).filter(
                            column =>
                              column.dataIndex !== 'rmbox' &&
                              column.dataIndex !== 'bandwidthLimit' &&
                              column.dataIndex !== 'zonelimit' &&
                              column.dataIndex !== 'ipStart' &&
                              column.dataIndex !== 'ipEnd'
                          )
                  }
                totalEntities={totalLength}
                selectionModel={selectedRowKeys}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setPageSize={setPageSize}
                pageSize={pageSize}
                ShowName={t('Zone.rows')}
                EntriesName={t('Zone.element')}
                OfPageName={t('Zone.of')}
                rows={filteredevents || dataSource}
                // checkboxSelect={onSelectChange}
                checkboxSelect={e => {
                    let newArray = getFilteredEvents(filteredevents, e);
                    setSelectedRowKeys(e);
                    dispatch(setSelected(newArray));
                    dispatch(setOpenDeleteSpace(newArray.length > 0));
 
                    dispatch(setSelected(newArray));
                }}
                />

            }

            </ConfigProvider>
        </div>
    );

    return {
        DataTable,


        selectedRow,
        selectedRowKeys,
        currentPage,
        pageSize,
        resetPagination,
    };
}

export default useDataTableMedia;
