import { useTranslation } from "react-i18next";
import { Form, Input } from "antd";
import Hooks from "../../utils/hooks";

import "../../AuthorizedIP.scss";

const { TextArea } = Input;

const AddEditAuthorizedIP = () => {
  const { t } = useTranslation();
  const { values } = Hooks();

  return (
    <>
      <div className="addEditCard">
        <Form.Item name="id" style={{ display: "none" }}>
          <Input type={"hidden"} value={values.name} name={"id"} id={"id"} />
        </Form.Item>
        <Form.Item
          label={
            <div>
              {t("boitier.AuthorizedIP.Header.ip")}
              <div className="required">*</div>
            </div>
          }
          name="ip"
          validateStatus={values.errors?.ip ? "error" : ""}
          // rules={[{ required: true }]}
          help={values.errors?.ip}

          rules={[{
            required: true,
            message: t('boitier.AuthorizedIP.msgErrorRequired'),
        },
        {
            validator: (_, value) => {
                if (!value) {
                return Promise.resolve();
                } else if (/^(?!.*\.\.)(?!.*\.$)((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/gi.test(value)) {
                return Promise.resolve();
                } else {    
                return Promise.reject(new Error(t('boitier.AuthorizedIP.msgErrorIp')));
                }
            },
            },

        ]}
        >
          <Input
            value={values.ip}
            name={"ip"}
            id={"ip"}
            placeholder={t("boitier.AuthorizedIP.Header.ip")}
          />
        </Form.Item>
        <Form.Item
          label={<div>{t("boitier.AuthorizedIP.Header.description")}</div>}
          name="description"
          validateStatus={values.errors?.description ? "error" : ""}
          help={values.errors?.description}
        >
          <TextArea
            value={values.description}
            name={"description"}
            rows={4}
            id={"description"}
            placeholder={t("boitier.AuthorizedIP.Header.description")}
          />
        </Form.Item>
      </div>
    </>
  );
};
export default AddEditAuthorizedIP;
