import {combineReducers} from 'redux'
import {AuthorizedIPReducer} from '../MenuBoitier/AuthorizedIp/store/AuthorizedIPReducer'   
import { GroupeRmBoxReducer } from "../MenuBoitier/GroupeRmBox/store/GroupeRmBoxReducer";
import { ZoneReducer } from "../MenuBoitier/Zone/store/zoneReducer";
import { InterventionReducer } from "../MenuBoitier/Interventions/store/interventionReducer";
import { RmboxReducer } from "../MenuBoitier/RmBox/store/rmboxReducer";
import ReducerUserData from '../userData/reducer';

export const rootReducer = combineReducers({
    AuthorizedIPReducer,
    GroupeRmBoxReducer,
    ZoneReducer,
    InterventionReducer,
    RmboxReducer,
    ReducerUserData
})

