import React,{useState} from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import WifiIcon from '@mui/icons-material/Wifi';
import GridOnIcon from '@mui/icons-material/GridOn';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { ModalInputs } from '@empreinte/components.modal-inputs';

import moment from 'moment';

import { useNavigate } from 'react-router-dom';
import { Tooltip, Select, Button, Modal, Row, Col} from 'antd';
import { useTranslation } from "react-i18next";
import Hooks from "../../utils/hooks";
import '../../rmbox.scss'
import { useAppSelector } from "../../../../Redux/hooks"
import { ModalConfirm } from '@empreinte/components.modal-confirm';
import { useDispatch } from 'react-redux';
import { setOpenModalDel } from '../../store/rmboxActions';

function useActionMenu({ record }: any) {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { handleShowModal, visible, handleCancel, handleEditRmbox,
        Deleteselecteditem, refreshboitier, handleShowModalHistory, handleCancelHistory,
        visibleHistory, handleShowModalGrille, handleCancelGrille, visibleGrille, handleAssociateprogram,
        handleShowModalActivatePlaylist,
        handleCancelActivatePlaylist,
        visibleActivatePlaylist, handleActivatePlaylist,
        displayinterventions } = Hooks()
    const datarecord = useAppSelector((state) => state.RmboxReducer.detailRmbox)
    const [openModal,setOpenModal] = useState(false)
    const dispatch=useDispatch()
    return (
        <div>
            <div className="action">

                <div className="icons-space">
                <div><Tooltip placement="top"
                    title={t("Rmbox.refresh")}>
                    <RefreshIcon className={"icon_action"}
                        onClick={() => refreshboitier(record.id)} />
                </Tooltip></div>
                <div><Tooltip placement="top" title={t("Rmbox.details")}>
                    <RemoveRedEyeIcon className={"icon_action"} onClick={() => handleShowModal(record.id)} /></Tooltip></div>
                <div><Tooltip placement="top"
                    title={t("Rmbox.history")}>
                    <InsertDriveFileIcon className={"icon_action"}
                        onClick={() => handleShowModalHistory(record.id)} />
                </Tooltip></div>

                <div><Tooltip placement="top" title={t("Rmbox.edit")}><BorderColorIcon className={"icon_action"} onClick={() => handleEditRmbox(record)} /></Tooltip></div>
                <div><Tooltip placement="top" title={t("Rmbox.delete")}><DeleteIcon className={"icon_action"} onClick={() =>{ 
                    dispatch(setOpenModalDel(true));
                        setOpenModal(true)}
                    } /></Tooltip></div>

                <div><Tooltip placement="top" title={t("Rmbox.programscheduleassociatd")}><CalendarTodayIcon className={"icon_action"} onClick={() => handleShowModalGrille(record.id)} /></Tooltip></div>
                <div><Tooltip placement="top" title={t("Rmbox.changechain")}><WifiIcon className={"icon_action"} onClick={() => handleShowModalActivatePlaylist(record.id)} /></Tooltip></div>
                <div><Tooltip placement="top" title={t("Rmbox.gridvisualization")}><GridOnIcon className={"icon_action"} onClick={() => {
                    navigate('/boitiers/gridvisualisation');
                    window.location.reload();
                }} /></Tooltip></div>
                <div><Tooltip placement="top" title={t("Rmbox.visualizationinterventions")}><PersonOutlineIcon className={"icon_action"} onClick={() => displayinterventions(record.id)} /></Tooltip></div>
                </div>

                {openModal && (    
                    <ModalConfirm
                        open={openModal}
                        onClose={()=>setOpenModal(false)}
                        title={t('Zone.Delete')}
                        content={t('Zone.DeleteConfirmationOneDelete')}
                        fullWidth={true}
                        CancelBtnName={t('boitier.No')}
                        ConfirmBtnName={t('boitier.Yes')}
                        onCancel={()=>setOpenModal(false)}
                        onConfirm={() => 
                        {
                            Deleteselecteditem(record?.id)
                            dispatch(setOpenModalDel(false))
                            setOpenModal(false)
                        }}
                    />)}

                {/*Modal Details*/}
     

                <ModalInputs
                className='modal'
                 open={visible}
                 title={`${t("Rmbox.modaledittitle")} "${datarecord.name}"`}/* " ${record.title} " */
                 onCancel={handleCancel}
                onClose={handleCancel}
                CancelBtnName= {t("Rmbox.close")}
                style={{
                  paddingRight:"24px",
                      paddingLeft:"24px",
                      paddingTop:"5px",
                      paddingBottom:"8px",
                      marginBlockStart:"24px"
                }}
                children={  
                    <div className={"detailsUserDivGlobal"}>
                    <div className={"detailsUserDivconfig"}>
                        <label className={"detailsUserDiv_lbl1config"}>{t("Rmbox.identifiant")}</label>
                        <label className={"detailsUserDiv_lbl2config"}>{datarecord?.id}</label>

                    </div>
                    <div className={"detailsUserDivconfig"}>
                        <label className={"detailsUserDiv_lbl1config"}>{t("Rmbox.name")}</label>
                        <label className={"detailsUserDiv_lbl2config"}>{datarecord?.name}</label>

                    </div>
                    <div className={"detailsUserDivconfig"}>
                        <label className={"detailsUserDiv_lbl1config"}>{t("Rmbox.type")}</label>
                        <label className={"detailsUserDiv_lbl2config"}>{datarecord?.type}</label>

                    </div>

                    <div className={"detailsUserDivconfig"}>
                        <label className={"detailsUserDiv_lbl1config"}>{t("Rmbox.ip")}</label>
                        <label className={"detailsUserDiv_lbl2config"}>{datarecord?.ip}</label>

                    </div>
                    <div className={"detailsUserDivconfig"}>
                        <label className={"detailsUserDiv_lbl1config"}>{t("Rmbox.mac")}</label>
                        <label className={"detailsUserDiv_lbl2config"}>{datarecord?.mac}</label>

                    </div>
                    <div className={"detailsUserDivconfig"}>
                        <label className={"detailsUserDiv_lbl1config"}>{t("Rmbox.adresspostal")}</label>
                        <label className={"detailsUserDiv_lbl2config"}>{datarecord?.postal}</label>
                    </div>
                    <div className={"detailsUserDivconfig"}>
                        <label className={"detailsUserDiv_lbl1config"}>{t("Rmbox.grouprmbox")}</label>
                        {typeof datarecord?.group === "object" ? <label className={"detailsUserDiv_lbl2config"}>{datarecord?.group?.name}</label>
                            : <label className={"detailsUserDiv_lbl2config"}>{datarecord?.group}</label>
                        }

                    </div>
                    <div className={"detailsUserDivconfig"}>
                        <label className={"detailsUserDiv_lbl1config"}>{t("Rmbox.createdDate")}</label>
                        <label className={"detailsUserDiv_lbl2config"}>
                        {moment(datarecord?.created_at).format("L") === "Invalid date" ? "" : <>{moment(datarecord?.created_at).format("yyyy-mm-DD HH:mm:ss")}</>}
                        </label>
                    </div>
                    <div className={"detailsUserDivconfiglast"}>
                        <label className={"detailsUserDiv_lbl1config"}>{t("Rmbox.updatedDate")}</label>
                        <label className={"detailsUserDiv_lbl2config"}>
                        {moment(datarecord?.updated_at).format() === "Invalid date" ? "" : <>{moment(datarecord?.updated_at).format("yyyy-mm-DD HH:mm:ss")}</>}
                            </label>
                    </div>
                </div>

                }
            />
                {/*Modal Historiques reports*/}
                <ModalInputs
                className='modal'
                headerStyle={{
                    color:"white"
                  }}
                 open={visibleHistory}
                 title={`${t("Rmbox.history")}`}
                 onCancel={handleCancelHistory}
                onClose={handleCancelHistory}
                CancelBtnName= {t("Rmbox.close")}
                style={{
                  paddingRight:"24px",
                      paddingLeft:"24px",
                      paddingTop:"5px",
                      paddingBottom:"8px",
                      marginBlockStart:"24px"
                }}
                children={ 
                    <Row style={{ padding: "4px", boxShadow: "1px 1px 1px 1px #9E9E9E" }}>
                    <Col span={12} >
                        <label>Date</label>
                    </Col >
                    <Col span={12} >

                        <label>Rapports envoyés</label>
                    </Col>
                </Row>

                 }
                
                />

                {/*Modal Grille des programmes*/}
               <ModalInputs
                className='modal'
                 open={visibleGrille}
                 title={`${t("Rmbox.modalgrille")}`}
                 onCancel={handleCancelGrille}
                onClose={handleCancelGrille}
                CancelBtnName= {t("Rmbox.close")}
                ConfirmBtnName=  {t("Rmbox.validate")}
                onConfirm={handleAssociateprogram}
                style={{
                  paddingRight:"24px",
                      paddingLeft:"24px",
                      paddingTop:"5px",
                      paddingBottom:"8px",
                      marginBlockStart:"24px",
                      zIndex: 10,

                }}
                children={ 
                    <Row >
                    <label className='grilletitle'>{`${t("Rmbox.programschedule")}`}</label>
                    <Select

                        showSearch
                        style={{ width: "100%",zIndex:"7" }}
                        placeholder={t('Rmbox.programschedule')}
                        optionFilterProp="children"
                    >
                        {/* {listgroup.map((item: any, key: any) => {
                        return <Option value={item.id} key={key}>{item.name}</Option>
                    })} */}
                 </Select>
                </Row>

                 }
                
                /> 

                {/*Modal ActivatePlaylist*/}

                <ModalInputs
                className='modal'
                 open={visibleActivatePlaylist}
                 title={`${t("Rmbox.activateplaylist")}`}
                 onCancel={handleCancelActivatePlaylist}
                onClose={handleCancelActivatePlaylist}
                CancelBtnName= {t("Rmbox.close")}
                ConfirmBtnName=  {t("Rmbox.validate")}
                onConfirm={handleActivatePlaylist}
                style={{
                  paddingRight:"24px",
                      paddingLeft:"24px",
                      paddingTop:"5px",
                      paddingBottom:"8px",
                      marginBlockStart:"24px",
                      zIndex: 10,

                }}
                children={

                    <Row >
                        <label className='grilletitle'>{`${t("Rmbox.playlist")}`}</label>
                        <Select

                            showSearch
                            style={{ width: "100%" }}
                            placeholder={t('Rmbox.chooseplaylist')}
                            optionFilterProp="children"
                        >
                            {/* {listgroup.map((item: any, key: any) => {
                            return <Option value={item.id} key={key}>{item.name}</Option>
                        })} */}
                        </Select>

                    </Row>

                }
                />

               

            </div>
        </div>
    );
}

export default useActionMenu;
