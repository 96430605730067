import React,{useState,useEffect} from "react";
import { useTranslation } from "react-i18next";
import Hooks from "../../utils/hooks";
import "../../GroupeRmBox.scss";
import { InputText } from "@empreinte/components.input-text";
import { useDispatch } from "react-redux";
import { setAddGroupeRmBoxActions } from "../../store/GroupeRmBoxActions";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useAppSelector } from "../../../../Redux/hooks";
import { Label } from "@empreinte/components.label";
import dayjs from 'dayjs';

const AddEditGroupeRmBox = () => {
  const { t } = useTranslation();
  const { values,  disabledButton } = Hooks();
  const dispatch = useDispatch();
  const [band, setBand] = useState("");
  const [name, setName] = useState("");
  const [selectedTime, setSelectedTime] = useState<any | null>(dayjs(values?.syncTime))
  const showDivCondition = useAppSelector(
    (state: any) => state.GroupeRmBoxReducer.showDivsConditionsGroupeRmBox
  );

  const [requiredFields,setRequiredFields]=useState({
    name:false,
    bandwidthLimit:false,
    syncTime:false
  })


  const [errors, setErrors] = useState({
    band: "",
    name: "",
    syncTime: "",
  });

  const setError = (field: any, errorMessage: any) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: errorMessage,
    }));
  };

  const testFields = () => {
    let completed = true;
  
    Object.values(requiredFields).forEach((item) => {
      if (item === false) {
        completed = false;
        return; // Exit the loop early if a field is false
      }
    });
    if (completed) {
      disabledButton("enabled");
    } else {
      disabledButton("disabled");
    }
  };
  
 
  const testFieldsModif = () => {
    let completed = false
    // eslint-disable-next-line array-callback-return
    Object.values(requiredFields).map((item: any) => {
        if (item === true) {
            completed = true
        }
    })
    if (completed === false) {
      disabledButton("enabled")

    } else {
      disabledButton("disabled")
    }
}


  useEffect(() => {
    
    testFields();
    testFieldsModif();
  }, [values,requiredFields]);



  const handleFieldChange = (fieldName: string, value: any) => {
    let errorMessage = t("");
    switch (fieldName) {
      case "name":
        setName(value);
        if (value.trim() === "") {
          errorMessage = t('boitier.Rmboxgroup.msgErrorName');
          requiredFields.name = false;
          setRequiredFields(requiredFields);
        }else{
          requiredFields.name = true;
          setRequiredFields(requiredFields);
        }
        break;
      case "syncTime":
        setSelectedTime(value);
        if (value == null) {
          requiredFields.syncTime = false;
          setRequiredFields(requiredFields);
          errorMessage = "Sync time must be selected";
        } else {
          requiredFields.syncTime = true;
          setRequiredFields(requiredFields);
        }
        break;
      case "bandwidthLimit":
        setBand(value);
        const bandRegex = /^(\d+(\.\d+)?)(\s?(Kbps|Mbps|Gbps)?)?$/;
        const isBandValid = value.match(bandRegex);
        if (value.trim() === "") {
          requiredFields.bandwidthLimit = false;
          setRequiredFields(requiredFields);
          errorMessage = t('boitier.Rmboxgroup.msgErrorRquiredBandwidth') ;
        } else if (!isBandValid) {
          requiredFields.bandwidthLimit = false;
          setRequiredFields(requiredFields);
          errorMessage = t('boitier.Rmboxgroup.msgErrorBandwidth');
        } else {
          requiredFields.bandwidthLimit = true;
          setRequiredFields(requiredFields);
        }
        break;
      default:
        break;
    }
  
    setError(fieldName, errorMessage);
  
    if (showDivCondition.addGroupeRmBox) {
      testFields();
    } else {
      testFieldsModif();
    }
  
    dispatch(
      setAddGroupeRmBoxActions({
        addGroupeRmBoxConstraintsDataNameChange: fieldName,
        addGroupeRmBoxConstraintsDataValueChange: value,
      })
    );
  };
  

  return (
    <>
      <div className="addEditCard">
        <InputText
          style={{
            display: "none",
            width: "100%",
            marginLeft: "2%",
            marginBottom: "2%",
            marginTop: "2%",
          }}
          value={values.name}
          id={"id"}
          name="id"
        ></InputText>

        <InputText
          className="label"
          value={values?.name}
          name={"name"}
          id={"name"}
          handleChange={(e:any)=>handleFieldChange("name",e.target.value)}
          placeholder={t("boitier.Rmboxgroup.Header.name")}
          labelInput={t("boitier.Rmboxgroup.Header.name")}
          required
        ></InputText>
        {errors.name && <p className="errorMsg">{errors.name}</p>}

        <InputText
          className="label"
          value={values?.bandwidthLimit}
          name={"bandwidthLimit"}
          id={"bandwidthLimit"}
          handleChange={(e:any)=>handleFieldChange("bandwidthLimit",e.target.value)}
          placeholder={t("boitier.Rmboxgroup.Header.bandwidth")}
          labelInput={t("boitier.Rmboxgroup.Header.bandwidth")}
          type="number"
          required
        ></InputText>
        {errors.band && <p className="errorMsg">{errors.band}</p>}
        <>
          <Label required style={{ float: "left" }}>
            {t("boitier.Rmboxgroup.Header.Synchro_schedule2")}
          </Label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              views={["hours", "minutes", "seconds"]}
              ampm={false}
              value={selectedTime}
              onChange={(e:any)=>handleFieldChange("syncTime",e)}
            />
          </LocalizationProvider>
          {errors.syncTime && <p className="errorMsg">{errors.syncTime}</p>}
        </>
      </div>
    </>
  );
};
export default AddEditGroupeRmBox;
