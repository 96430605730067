
export const RmboxList = () => {

    const FilterRmbox = () => {
        return (
            {
                search: "",
            }
        )
    }

    const paginationPropsRmbox = () => {
        return (
            {
                order: 'descend',
                pageSize: 10,
                columnKey: 0,
                current: 1,
                id: [],
                idLive: 0,
            }
        )
    }

    const showDivsConditionsRmbox = () => {
        return ({
            Authorized:false,
            Loading:true,
            loader:false,
            elementSelected: 0,
            addRmbox: false,
            editRmbox: false,
            interventionRmbox: false,
            disablesubmitform: true,
          

        })
    }

    const AddRmboxConstraintsData = () => {
        return (
            {
                id: "",
                name: "",
                type: "",
                ip: "",
                mac: "",
                postal: "",
                created_at: "",
                updated_at: "",
                group: "",
                schedule: "",
                statut: "",
                espace_rest: "",
                date_debut_synch: "",
                duration_synch: "",
                statut_updated: "",
                count_videos: "",
                count_zones: "",
                count_playlists: "",
                programs: []
            }
        )
    }

    const DataRmbox = () => {
        return []
    }
    const DetailRmbox = () => {
        return []
    }



    return ({
        paginationPropsRmbox,
        showDivsConditionsRmbox,
        AddRmboxConstraintsData,
        FilterRmbox,
        DataRmbox,
        DetailRmbox
    })
}
