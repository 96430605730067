import {useEffect, useState} from 'react'
import InterventionHeader from './components/interventionHeader';
import InterventionTable from "./components/List/interventionTable";
import AddEditIntervention from "./components/AddEdit/addEdit";
import './intervention.scss'
import {useAppSelector} from "../../Redux/hooks";
import Hooks from "./utils/hooks";
import {Form, ConfigProvider} from "antd";
import frFR from 'antd/lib/locale/fr_FR';
import LoadingScreen from "../LoadingScreen";
import NotFound from "../NotFound";
import { Loader } from '@empreinte/components.loader';

const Intervention = () => {

    const {formAddIntervention , onFinishAddIntervention , onFinishFailedAddIntervention , onValueFormUserchangeIntervention, getAllData} = Hooks()
    const showDivCondition = useAppSelector((state) => state.InterventionReducer.showDivsConditionsIntervention)
    const loader = showDivCondition.loader
    const values = useAppSelector((state) => state.InterventionReducer.addInterventionConstraintsData)
    const [locale] = useState<any>(frFR);
    const [pageSize]=useState(10)
    const [currentPage]=useState(1)
    const loadingscreen = useAppSelector((state)=>state.ReducerUserData.loading_screen)
    const unauthorizedUser = useAppSelector((state)=>state.ReducerUserData.unauthorizedUser)

    useEffect(() => {
      
        // if(!showDivCondition.editIntervention && !showDivCondition.addIntervention){
        //     getAllData(currentPage,pageSize)
        // }
        if(showDivCondition.editIntervention){
            formAddIntervention.setFieldsValue({

                id: values.id,
                remarque: values.remarque,
                receptionDate: values.receptionDate,
                sendDate: values.sendDate,
                oldMac: values.oldMac,
                firstStartedDate: values.firstStartedDate,
                created_at: values.created_at,
                newMac: values.newMac,
                rmbox_name: values.rmbox_name,
                rmbox:values.rmbox,
                rmbox_id: values.rmbox_id,
                rmbox_ip: values.rmbox_ip,
            })
        }

        if(showDivCondition.addIntervention){
            formAddIntervention.setFieldsValue({
                id: "",
                remarque: "",
                receptionDate: "",
                sendDate: "",
                oldMac: "",
                firstStartedDate: "",
                created_at: "",
                newMac: null,
                rmbox_name: "",
                rmbox:"",
                rmbox_id: "",
                rmbox_ip: "",
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDivCondition.editIntervention, showDivCondition.addIntervention,currentPage,pageSize,unauthorizedUser])

    if (loadingscreen) {
        return <LoadingScreen/>
      } 
      else if (unauthorizedUser) {
        return <NotFound/>
      }
      
    return (
        <ConfigProvider locale={locale}>
        <div >
        <Loader isOpen={loader}/>
            {
                !showDivCondition.addIntervention && !showDivCondition.editIntervention
                    ?
                    <div>
                        <InterventionHeader/>
                        <InterventionTable/>
                    </div>
                    :
                    <div>
                        <Form
                            name="basic"
                            layout="vertical"
                            initialValues={{remember: true}}
                            onFinish={onFinishAddIntervention}
                            onFinishFailed={onFinishFailedAddIntervention}
                            onValuesChange={onValueFormUserchangeIntervention}
                            autoComplete="off"
                            className={"ProfileForm"}
                            form={formAddIntervention}
                        >
                        <InterventionHeader/>
                        <AddEditIntervention/>
                        </Form>
                    </div>
            }
            
        </div>
        </ConfigProvider>
    )
}

export default Intervention
