import { Row } from 'antd';
import React from 'react';

const Gridvisualization = () => {
  return <div>
      <Row justify='center'>
      <label>Visualisation de la grille</label>
      </Row>
      
        
  </div>;
};

export default Gridvisualization;
