
export function setPaginationPropsZone(e:any){
    return{
        type:"SET_PaginationPropsZone",
        payload:e,
    }
}

export function setshowDivsConditionsZone(e:any){
    return {
        type : "SET_showDivsConditionsZone",
        payload:e
    }
}

export function setAddZoneActions(e:any){
    return{
        type:"SET_AddZoneActions",
        payload:e,
    }
}

export function setFilterZoneActions(e:any){

    return{
        type:"SET_FilterZone",
        payload:e,
    }

}

export function setEditZoneActions(e:any){
    return{
        type:"SET_EditZoneActions",
        payload:e,
    }
}

export function setDataZoneActions(e:any){
    return{
        type:"SET_DataZoneActions",
        payload:e,
    }
}

export function setDetailZoneActions(e:any){
    return{
        type:"SET_DetailZoneActions",
        payload:e,
    }
}
export function setListRmboxZoneActions(e:any){
    return{
        type:"SET_ListRmboxZoneActions",
        payload:e,
    }
}
export function setErrorrMessageZoneActions(e:any){

    return{
        type:"SET_ErrorrMessage",
        payload:e,
    }

}
export function setSelected(e:any){
    const listSelected = e.map((e:any)=>e);
    return {
        type:"SET_IDSELECTED",
        payload:listSelected,
    }
  }
  
  export function setOpenDeleteSpace(e:any){
    return{
        type:"SET_DELETESPACE",
        payload:e
    }
  }
  export function setOpenModalDel(e:any){
    return{
      type: "SET_MODALDEL",
      payload:e
    }
  }