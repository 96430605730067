import {useEffect, useState} from 'react'
import RmboxHeader from './components/rmboxHeader';
import RmboxTable from "./components/List/rmboxTable";
import AddEditRmbox from "./components/AddEdit/addEdit";
import './rmbox.scss'
import {useAppSelector} from "../../Redux/hooks";
import Hooks from "./utils/hooks";
//import {Form} from "antd";
import { Form, ConfigProvider} from "antd";
import frFR from 'antd/lib/locale/fr_FR';
import DisplayAlert from './components/Alert';
import Intervention from '../Interventions/intervention';
import { getUserData } from '../../userData/action';
import { useDispatch } from 'react-redux';
import LoadingScreen from '../LoadingScreen';
import NotFound from '../NotFound';
import { Loader } from '@empreinte/components.loader';
const Rmbox = () => {

    const {formAddRmbox , onFinishAddRmbox , onFinishFailedAddRmbox , onValueFormUserchangeRmbox, getAllData,refreshRmbox} = Hooks()
    const showDivCondition = useAppSelector((state) => state.RmboxReducer.showDivsConditionsRmbox)
    const loader = showDivCondition.loader
    const values = useAppSelector((state) => state.RmboxReducer.addRmboxConstraintsData)
    const [locale] = useState<any>(frFR);
    const [pageSize]=useState(10)
    const [currentPage]=useState(1);
    const dispatch = useDispatch()
    const loadingscreen = useAppSelector(
        (state) => state.ReducerUserData.loading_screen
      );
      const unauthorizedUser = useAppSelector(
        (state) => state.ReducerUserData.unauthorizedUser
      );
 
      useEffect(() => {
        dispatch(getUserData());
      }, []);
    useEffect(() => {
        if(showDivCondition.editRmbox){
            formAddRmbox.setFieldsValue({
                id: values.id,
                name: values.name,
                type: values.type,
                ip: values.ip,
                mac: values.mac,
                postal: values.postal,
                created_at: values.created_at,
                updated_at: values.updated_at,
                group: values.group,
                schedule: values.schedule,
                statut: values.statut,
                espace_rest: values.espace_rest,
                date_debut_synch: values.date_debut_synch,
                duration_synch: values.duration_synch,
                statut_updated: values.statut_updated,
                count_videos: values.count_videos,
                count_zones: values.count_zones,
                count_playlists: values.count_playlists,
                programs: values.programs
            })
        }

        if(showDivCondition.addRmbox){
            formAddRmbox.setFieldsValue({
                id: "",
                name: "",
                type: undefined,
                ip: "",
                mac: "",
                postal: "",
                created_at: "",
                updated_at: "",
                group: undefined,
                schedule: "",
                statut: "",
                espace_rest: "",
                date_debut_synch: "",
                duration_synch: "",
                statut_updated: "",
                count_videos: "",
                count_zones: "",
                count_playlists: "",
                programs: []
            })
        }
        if(!showDivCondition.addRmbox && !showDivCondition.editRmbox ){
            refreshRmbox()
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDivCondition.editRmbox, showDivCondition.addRmbox,unauthorizedUser])

    if (loadingscreen) {
        return <LoadingScreen/>
      } 
      else if (unauthorizedUser) {
        return <NotFound/>
    }
    
    return (
        <ConfigProvider locale={locale}>
        <div className={"divGlobalTable"}>
        <Loader isOpen={loader}/>

            <DisplayAlert/>
            {
                !showDivCondition.addRmbox && !showDivCondition.editRmbox && !showDivCondition.interventionRmbox
                    ?
                    <div>
                        <RmboxHeader/>
                        <RmboxTable/>
                    </div>
                    : !showDivCondition.addRmbox && !showDivCondition.editRmbox && showDivCondition.interventionRmbox ? 
                    <div> <RmboxHeader/>
                    <Intervention/></div>
                   :

                    <div>
                        <Form
                            name="basic"
                            layout="vertical"
                            initialValues={{remember: true}}
                            onFinish={onFinishAddRmbox}
                            onFinishFailed={onFinishFailedAddRmbox}
                            onValuesChange={onValueFormUserchangeRmbox}
                            autoComplete="off"
                            className={"ProfileForm"}
                            form={formAddRmbox}
                        >
                        <RmboxHeader/>
                        <AddEditRmbox/>
                        </Form>
                    </div>
            }
            {}
          
        </div>
        </ConfigProvider>
    )
}

export default Rmbox
