import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Form, message } from "antd";
import { DeleteData, GetData, PostData } from "../../../APIcalls";
import { AuthorizedIPList } from "../store/AuthorizedIP";
import { useAppSelector } from "../../../Redux/hooks";
import { useTranslation } from "react-i18next";
import {
  setshowDivsConditionsAuthorizedIP,
  setPaginationPropsAuthorizedIP,
  setFilterAuthorizedIPActions,
  setAuthorizedIPDataActions,
  setAddAuthorizedIPActions,
  setRecord,
  setOpenDelete
} from "../store/AuthorizedIPActions";

const Hooks = () => {

  //Modal Details
  const { t } = useTranslation();
  const [visible, setVisible] = useState<any>(false);

  const dispatch = useDispatch();
  const { AuthorizedIPTypes } = AuthorizedIPList();
  const [formAddAuthorizedIP] = Form.useForm();
  const [pageSize,setPageSize]=useState(10)
  const [currentPage,setCurrentPage]=useState(1)
  // use Selector redux
  const values = useAppSelector(
    (bandwidthLimit) =>
      bandwidthLimit.AuthorizedIPReducer.addAuthorizedIPConstraintsData
  );
  const { editAuthorizedIP, addAuthorizedIP } = useAppSelector(
    (bandwidthLimit) =>
      bandwidthLimit.AuthorizedIPReducer.showDivsConditionsAuthorizedIP
  );
  const selectedItems = useAppSelector((state)=>state.AuthorizedIPReducer.selectedCase)  
  const [searchWord]=useState('')
  useEffect(() => {
    if (editAuthorizedIP) {
      formAddAuthorizedIP.setFieldsValue({
        id: values.id,
        ip: values.ip,
        description: values.description,
      });
    }
    if (addAuthorizedIP) {
      formAddAuthorizedIP.resetFields();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editAuthorizedIP, addAuthorizedIP]);

  useEffect(() => {
    if (visible) {
      formAddAuthorizedIP.setFieldsValue({
        id: values.id,
        ip: values.ip,
        description: values.description,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const requiredFields=()=>{
    return values.ip.trim() === ""
  }
  ////****************** function Filter *****************///////
  //*********************** search header **************///////
  const handleSearchRow = async (event: any) => {
    // if (event.key === "Enter") {
     dispatch(
        setFilterAuthorizedIPActions({
          FilterAuthorizedIPNameChange: "search",
          FilterAuthorizedIPValueChange: event?.target?.value,
        })
      );
    // }
  };

  const handelSearch = (word: string, tab: any) => {
    if (word.trim() === "") return tab;
    // eslint-disable-next-line array-callback-return
    const res = tab.filter((obj: any) => {
      if (obj?.id ||  obj.ip.toLowerCase().includes(word.toLowerCase())|| (obj.description && obj.description.toLowerCase().includes(word.toLowerCase()))) return obj;
    });
    return res;
  };

  ////*************** show Modal Function ******************/////////
  const handleShowModal = async (id: number) => {
    await getOneAuthorizedIP(id);
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    dispatch(
      setAuthorizedIPDataActions({ msg: "", err: false, AuthorizedIP: {} })
    );
  };

  /////********* show add AuthorizedIP interface ****************/////
  const handleAddAuthorizedIP = () => {
    formAddAuthorizedIP.setFieldsValue({
      id: "",
      ip: "",
      description: "",
    });
    dispatch(
      setshowDivsConditionsAuthorizedIP({
        showDivsConditionsName: "addAuthorizedIP",
        showDivsConditionsValue: true,
      })
    );
  };

  ////////****** Edit AuthorizedIP ***************////
  const handleEditAuthorizedIP = async (record: any) => {
    formAddAuthorizedIP.setFieldsValue({
      id: record.id,
      description: record.description,
      ip: record.ip,
    });
    // eslint-disable-next-line array-callback-return
    Object.keys(record).map((key:any)=>{
       dispatch(
        setAddAuthorizedIPActions({
          addAuthorizedIPConstraintsDataNameChange: key,
          addAuthorizedIPConstraintsDataValueChange: record[key],
        })
      );
    })
    await dispatch(
      setshowDivsConditionsAuthorizedIP({
        showDivsConditionsName: "editAuthorizedIP",
        showDivsConditionsValue: true,
      })
    ); 
   await dispatch(
      setAuthorizedIPDataActions({
        AuthorizedIP: record,
      })
    );
  };
  const onResetAuthorizedIP = async () => {
    /**edit item list */
    formAddAuthorizedIP.setFieldsValue({
      id: "",
      ip: "",
      description: "",
    });
    dispatch(
      setshowDivsConditionsAuthorizedIP({
        showDivsConditionsName: "editAuthorizedIP",
        showDivsConditionsValue: false,
      })
    );
    dispatch(
      setshowDivsConditionsAuthorizedIP({
        showDivsConditionsName: "addAuthorizedIP",
        showDivsConditionsValue: false,
      })
    );
    dispatch(
      setAddAuthorizedIPActions({
        addAuthorizedIPConstraintsDataNameChange: "id",
        addAuthorizedIPConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddAuthorizedIPActions({
        addAuthorizedIPConstraintsDataNameChange: "ip",
        addAuthorizedIPConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddAuthorizedIPActions({
        addAuthorizedIPConstraintsDataNameChange: "description",
        addAuthorizedIPConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddAuthorizedIPActions({
        addAuthorizedIPConstraintsDataNameChange: "errors",
        addAuthorizedIPConstraintsDataValueChange: {},
      })
      );
      dispatch(setOpenDelete(false))
  };


  /////**************** Form Add AuthorizedIP *******************/////////
  const onFinishAddAuthorizedIP = (values: any) => {
    if (validator()) {
      if (!values.id) {
        AddAuthorizedIP(values);
      } else {
        UpdateAuthorizedIP(values);
      }
    }
  };
  const onFinishFailedAddAuthorizedIP = (errorInfo: any) => {
  };
  const onValueFormUserchangeAuthorizedIP = async (val: any) => {
    const key = Object.keys(val);
    await dispatch(
      setAddAuthorizedIPActions({
        addAuthorizedIPConstraintsDataNameChange: key[0],
        addAuthorizedIPConstraintsDataValueChange: val[key[0]],
      })
    );
    resetErrors(key[0]);

  };

  const resetErrors = (name: string) => {
    if (values.errors) {
      let err = values.errors;
      delete err[name];
      dispatch(
        setAddAuthorizedIPActions({
          addAuthorizedIPConstraintsDataNameChange: "errors",
          addAuthorizedIPConstraintsDataValueChange: err,
        })
      );
    }
  };

    /// validation of the Form
    const validator = () => {
      let errors = {};
      if (!values.ip || values.ip.trim() === "")
        errors = {
          ...errors,
          ip:
            t("boitier.messageerror") +
            " '" +
            t("boitier.AuthorizedIP.Header.ip") +
            "'!",
        };
      dispatch(
        setAddAuthorizedIPActions({
          addAuthorizedIPConstraintsDataNameChange: "errors",
          addAuthorizedIPConstraintsDataValueChange: errors,
        })
      );
      if (JSON.stringify(errors) === "{}") {
        return true;
      } else {
        return false;
      }
    };

   ///******** delete many IP adress ********///
   const handleDeleteAuthorizedIP = (ids: any) => {
    let list = "";
    // eslint-disable-next-line array-callback-return
    ids.map((v: any) => {
      list += v + ",";
    });
    // deleteMultiAuthorizedIP(list.slice(0, -1));
  };

  /******* handle Api *******/

  //*  Get List
  const getListAuthorizedIP = async (currentPage:any,pageSize:any,searchWord:any) => {
    const offset = (currentPage - 1) * pageSize;
    dispatch({
      type: AuthorizedIPTypes.LOADING,
    });
    await GetData(
      `/dataTable/autorise-ips?columns%5B0%5D%5Borderable%5D=true&draw=${currentPage}&length=${pageSize}&order%5B0%5D%5Bcolumn%5D=0&order%5B0%5D%5Bdir%5D=asc&search%5Bvalue%5D=&start=${offset}&search[value]=${searchWord} `
    )
      .then((res: any) => {
        if (res.status === 200) {
          dispatch(
            setshowDivsConditionsAuthorizedIP({
                showDivsConditionsName: "loader",
                showDivsConditionsValue: false,
             
            })
          )
          dispatch(
            setAuthorizedIPDataActions({
              List: res?.data?.data,
              msg: "",
              error: false,
            })
           
          );
          dispatch(setRecord(res?.data?.recordsFiltered))
        } else {
          const msg = HandleFaildApiMessage(res?.data?.code, res.data?.message);
          dispatch(setAuthorizedIPDataActions({ msg, err: true, list: [] }));
        }
      })
      .catch((err) => {
        const msg = HandleFaildApiMessage(
          err?.response?.data?.code,
          err?.response?.data?.message
        );
        dispatch(setAuthorizedIPDataActions({ msg, err: true, list: [] }));
      });
  };

  // Get One AuthorizedIP

  const getOneAuthorizedIP = async (id: number) => {
    dispatch({
      type: AuthorizedIPTypes.LOADING,
    });
    await GetData(`/autorise_ips/details/${id}/ips`)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch(
            setAuthorizedIPDataActions({
              AuthorizedIP: res.data,
              msg: "",
              error: false,
            })
          );
        } else {
          const msg = HandleFaildApiMessage(res.data.code, res.data?.message);
          dispatch(
            setAuthorizedIPDataActions({ msg, err: true, AuthorizedIP: null })
          );
        }
      })
      .catch((err) => {
        const msg = HandleFaildApiMessage(
          err.response.data.code,
          err.response.data?.message
        );
        dispatch(
          setAuthorizedIPDataActions({ msg, err: true, AuthorizedIP: null })
        );
      });
  };
  
  // add
  const AddAuthorizedIP = async (data: any) => {
    dispatch({
      type: AuthorizedIPTypes.LOADING,
    });
    await PostData("/autorise_ips/add", { empAutoriseIpForm :{...data}})
      .then(({ data }: any) => {
        if (data.code === 200) {
          dispatch(setAuthorizedIPDataActions({ msg: "", error: false }));
          getListAuthorizedIP(currentPage,pageSize,searchWord);
          onResetAuthorizedIP();
          message.success(`${t("boitier.AuthorizedIP.success_ADD")}`);
        } else {
          handleResponseError(data);
          const msg = HandleFaildApiMessage(data.code, data?.message);
          dispatch(
            setAuthorizedIPDataActions({ msg, error: true, errors: data.errors })
          );
          message.error(`${t("boitier.AuthorizedIP.failed_ADD")}`);
        }

       
      })
      .catch((err) => {
        const msg = HandleFaildApiMessage(
          err.response.data.code,
          err.response.data?.message
        );
        message.error(`${t("boitier.AuthorizedIP.failed_ADD")}`);
        dispatch(
          setAuthorizedIPDataActions({
            msg,
            error: true,
            list: [],
            errors: data.errors,
          })
        );
      });
  };
  // update

  const UpdateAuthorizedIP = async (data: any) => {
    dispatch({
      type: AuthorizedIPTypes.LOADING,
    });
    const empAutoriseIpForm = {
      ip: data.ip,
      description: data.description,
    };
    await PostData("/autorise_ips/edit/" + data.id, {
      id: data.id,
      empAutoriseIpForm,
    })
      .then(({data}: any) => {
        if (data.code === 200) {
          dispatch(setAuthorizedIPDataActions({ msg: "", error: false }));
          formAddAuthorizedIP.resetFields();
          getListAuthorizedIP(currentPage,pageSize,searchWord);
          onResetAuthorizedIP();
          message.success(` ${t("boitier.AuthorizedIP.success_Edit")}`);
        } else {

          handleResponseError(data);
          const msg = data
            ? HandleFaildApiMessage(data.code, data.message)
            : "serveur erreur";
          dispatch(setAuthorizedIPDataActions({ msg, error: true }));
          message.error(` ${t("boitier.AuthorizedIP.failed_Edit")}`);
        }
      })
      .catch((err) => {
        const msg = err.response?.data
          ? HandleFaildApiMessage(
              err.response.data.code,
              err.response.data?.message
            )
          : "serveur erreur";
        dispatch(setAuthorizedIPDataActions({ msg, error: true }));
        message.error(` ${t("boitier.AuthorizedIP.failed_Edit")}`);
      });
  };
  const deleteAuthorizedIP = async (id: number) => {
    dispatch({
      type: AuthorizedIPTypes.LOADING,
    });

    await DeleteData("/autorise_ips/delete/" + id)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch(setAuthorizedIPDataActions({ msg: "", error: false }));
          getListAuthorizedIP(currentPage,pageSize,searchWord);
          message.success(`${t("boitier.AuthorizedIP.success_Delete")}`);
        } else {
          const msg = HandleFaildApiMessage(res.data.code, res.data?.message);
          dispatch(setAuthorizedIPDataActions({ msg, error: true }));
          message.error(` ${t("boitier.AuthorizedIP.failed_Delete")}`);
        }
      })
      .catch((err) => {
        const msg = HandleFaildApiMessage(
          err.response.data.code,
          err.response.data?.message
        );
        dispatch(setAuthorizedIPDataActions({ msg, error: true, list: [] }));
        message.error(` ${t("boitier.AuthorizedIP.failed_Delete")}`);
      });
  };


  // delete multiple
  
  const deleteMultiAuthorizedIP = async () => {
    const ids = [selectedItems.join()];

    dispatch({
      type: AuthorizedIPTypes.LOADING,
    });
    await DeleteData("/multiDelete/autorise-ips/" + ids)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch(setAuthorizedIPDataActions({ msg: "", error: false }));
          dispatch(
            setPaginationPropsAuthorizedIP({
              PaginationPropsNameChange: "id",
              PaginationPropsValueChange: [],
            })
          );
          dispatch(
            setshowDivsConditionsAuthorizedIP({
              showDivsConditionsName: "elementSelected",
              showDivsConditionsValue: 0,
            })
          );
          getListAuthorizedIP(currentPage,pageSize,searchWord);
          message.success(`${t("boitier.AuthorizedIP.success_Delete")}`);
        } else {
          dispatch(setAuthorizedIPDataActions({ msg: "error", error: true }));
          message.error(` ${t("boitier.AuthorizedIP.failed_Delete")}`);
        }
      })
      .catch((err) => {
        const msg =
          err.response && err.response.status === 404 ? "server  down" : err;
        dispatch(setAuthorizedIPDataActions({ msg, error: true }));
        message.error(` ${t("boitier.AuthorizedIP.failed_Delete")}`);
      });
  };

  /// Faild message

  const HandleFaildApiMessage = (code: any, msg: string) => {
    switch (code) {
      case 404:
        return "page not found";
      case 401:
        return "you are not authorized";
      case 500:
        return "serveur error";

      default:
        return msg;
    }
  };
  function handleResponseError(data:any) {
    if (data.errors?.length > 0) {
      let errors = {};
      // eslint-disable-next-line array-callback-return
      data.errors.map((v: any) => {
        errors = {
          ...errors,
          [v.for]: t(`boitier.AuthorizedIP.Header.${v.for}`) +
            " " +
            t("boitier.AuthorizedIP.responseError") +
            "!",
        };
      });

      dispatch(
        setAddAuthorizedIPActions({
          addAuthorizedIPConstraintsDataNameChange: "errors",
          addAuthorizedIPConstraintsDataValueChange: errors,
        })
      );
    }
  }
  ///******* end handle Api *******/

  const handelFilter = (type: any, tab: any) => {
    function smallval(item: any) {
      if (type === "All") {
        return item;
      } else if (type !== "") {
        return item.type === type;
      } else {
        return item;
      }
    }

    const res = tab.filter(smallval);

    return res;
  };

  return {
    handleAddAuthorizedIP,
    onResetAuthorizedIP,
    formAddAuthorizedIP,
    handleSearchRow,
    handleShowModal,
    visible,
    handleCancel,
    onFinishAddAuthorizedIP,
    onFinishFailedAddAuthorizedIP,
    onValueFormUserchangeAuthorizedIP,
    handleEditAuthorizedIP,
    values,
    handelSearch,
    handelFilter,
    getListAuthorizedIP,
    // getOneAuthorizedIP,
    deleteMultiAuthorizedIP,
    deleteAuthorizedIP,
    handleDeleteAuthorizedIP,
    requiredFields
  };
};
export default Hooks;
