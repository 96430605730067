import { useEffect, useState } from "react";
import { Form, Input, Select } from "antd";
import '../../rmbox.scss';
import { useTranslation } from "react-i18next";
import Hooks from "../../utils/hooks";


import { DataRmbox } from "../../utils/Data";
import { useAppSelector } from "../../../../Redux/hooks";
const { TextArea } = Input;




const AddEditRmbox = () => {
    const { t } = useTranslation();

    const { Option } = Select;
    const { values, disabledsubmitbutton } = Hooks();
    const { listtype } = DataRmbox();
    const listgroup = useAppSelector((state) => state.RmboxReducer.listgrouprmbox)
    const Rmboxcondition = useAppSelector((state) => state.RmboxReducer.showDivsConditionsRmbox)

    const [datafiledsrequired, setDatafiledsrequired] = useState<any>({
        name: false,
        type: false,
        ip: false,
        mac: false,
        groupermbox: false
    })

    const testFields = () => {
        let completed = false

        // eslint-disable-next-line array-callback-return
        Object.values(datafiledsrequired).map((item: any) => {
            if (item === false) {
                completed = true
            }
        })
        if (completed === false) {
            disabledsubmitbutton("disabled")
        } else {
            disabledsubmitbutton("notdisabled")
        }
    }

    const testFieldsmodif = () => {
        let completed = false
        // eslint-disable-next-line array-callback-return
        Object.values(datafiledsrequired).map((item: any) => {
            if (item === true) {
                completed = true
            }
        })
        if (completed === false) {
            disabledsubmitbutton("notdisabled")

        } else {
            disabledsubmitbutton("disabled")
        }
    }

    useEffect(() => {
       
        testFields()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Rmboxcondition.addRmbox, Rmboxcondition.editRmbox])
    const onChangename = (e: any) => {
        if (e.target.value !== "") {
            datafiledsrequired.name = true
            setDatafiledsrequired(datafiledsrequired)
        } else {
            datafiledsrequired.name = false
            setDatafiledsrequired(datafiledsrequired)
        }
        if (Rmboxcondition.addRmbox) {
            testFields()
        } else if (Rmboxcondition.editRmbox) {
            testFieldsmodif()
        }

    }
    const onChangetype = (e: any) => {
        if (e !== "") {
            datafiledsrequired.type = true
            setDatafiledsrequired(datafiledsrequired)
        } else {
            datafiledsrequired.type = false
            setDatafiledsrequired(datafiledsrequired)
        }
        if (Rmboxcondition.addRmbox) {
            testFields()
        } else if (Rmboxcondition.editRmbox) {
            testFieldsmodif()
        }

    }
    const onChangeip = (e: any) => {
      
        if (e.target.value !== "") {
            datafiledsrequired.ip = true
            setDatafiledsrequired(datafiledsrequired)
        } else {
            datafiledsrequired.ip = false
            setDatafiledsrequired(datafiledsrequired)
        }
        if (Rmboxcondition.addRmbox) {
            testFields()
        } else if (Rmboxcondition.editRmbox) {
            testFieldsmodif()
        }

    }
    const onChangemac = (e: any) => {
      
        if (e.target.value !== "") {
            datafiledsrequired.mac = true
            setDatafiledsrequired(datafiledsrequired)
        } else {
            datafiledsrequired.mac = false
            setDatafiledsrequired(datafiledsrequired)
        }
        if (Rmboxcondition.addRmbox) {
            testFields()
        } else if (Rmboxcondition.editRmbox) {
            testFieldsmodif()
        }
    }
    const onChangegroupermbox = (e: any) => {

        if (e !== "") {
            datafiledsrequired.groupermbox = true
            setDatafiledsrequired(datafiledsrequired)
        } else {
            datafiledsrequired.groupermbox = false
            setDatafiledsrequired(datafiledsrequired)
        }
        if (Rmboxcondition.addRmbox) {
            testFields()
        } else if (Rmboxcondition.editRmbox) {
            testFieldsmodif()
        }
    }
    return (
        <div className={"formItem1"}>

            <div className={"InputLablDiv"}>
                <Form.Item
                    label={<>{t('Rmbox.name')}<div className="required">*</div></>}
                    name="name"
                    className={"formItem3"}
                    rules={[{ required: true, message: t('Rmbox.msgerrorkeyname') }]}
                >
                    <Input value={values.name}
                        name={"name"} id={"name"}
                        placeholder={t('Rmbox.placeholdername')}
                        onChange={onChangename} />
                </Form.Item>
            </div>

            <div className={"InputLablDiv"}>
                <Form.Item
                    label={<>{t('Rmbox.type')}<div className="required">*</div></>}
                    name="type"
                    className={"formItem3"}
                    rules={[{ required: true, message: t('Rmbox.msgerrorkeytype') }]}
                >

                    <Select
                        showSearch
                        className="selectstyle"
                        placeholder={t('Rmbox.placeholdertype')}
                        optionFilterProp="children"
                        onChange={onChangetype}
                    >
                        {listtype.map((item: any, key: any) => {
                            return <Option value={item} key={key}>{item}</Option>
                        })}
                    </Select>
                </Form.Item>

            </div>

            <div className={"InputLablDiv"}>
                <Form.Item
                    label={<>{t('Rmbox.ip')}<div className="required">*</div></>}
                    name="ip"
                    className={"formItem3"}
                    rules={[{
                        required: true,
                        message: t('Rmbox.msgerrorkeyip'),
                    }, 
                    {
                    validator: (_, value) => {
                        if (!value) {
                        return Promise.resolve();
                        } else if (/^(?!.*\.\.)(?!.*\.$)((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/gi.test(value)) {
                        return Promise.resolve();
                        } else {   
                        disabledsubmitbutton("notdisabled")
                        return Promise.reject(new Error(t('Rmbox.msgerrorkeyip')));
                        }
                    },
                    },

                    ]}
                >
                    <Input value={values.ip} name={"ip"} id={"ip"}
                        placeholder={t('Rmbox.placeholderip')}
                        onChange={onChangeip} />
                </Form.Item>

            </div>
            <div className={"InputLablDiv"}>
                <Form.Item
                    label={<>{t('Rmbox.mac')}<div className="required">*</div></>}
                    name="mac"
                    className={"formItem3"}
                    rules={[{ required: true, message: t('Rmbox.msgerrorkeymac') },

                      {
                        validator: (_, value) => {
                        if (!value) {
                        return Promise.resolve();
                        } else if (/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/gi.test(value)) {
                        return Promise.resolve();
                        } else {   
                        disabledsubmitbutton("notdisabled")
                        return Promise.reject(new Error(t('Rmbox.msgerrorkeymac')));
                        }
                      }
                    }                    
                    ]}
                >
                    <Input value={values.mac} name={"mac"} id={"mac"}
                        placeholder={t('Rmbox.placeholdermac')}
                        onChange={onChangemac} />
                </Form.Item>
            </div>

            <div className={"InputLablDiv"}>
                <Form.Item
                    label={t('Rmbox.adresspostal')}
                    name="postal"
                    className={"formItem3"}
                >


                    <TextArea rows={3} value={values.postal} name={"postal"} id={"postal"}
                        placeholder={t('Rmbox.placeholderadresss')} />
                </Form.Item>

            </div>

            <div className={"InputLablDiv"}>
                <Form.Item
                    label={<>{t('Rmbox.grouprmbox')}<div className="required">*</div></>}
                    name="group"
                    className={"formItem3"}
                    rules={[{ required: true, message: t('Rmbox.msgerrorkey') }]}
                >
                    <Select
                        showSearch
                        placeholder={t('Rmbox.placeholdergrouprmbox')}
                        optionFilterProp="children"
                        className="selectstyle"
                        onChange={onChangegroupermbox}
                    >
                        {listgroup.map((item: any, key: any) => {
                            return <Option value={item.id} key={key}>{item.name}</Option>
                        })}
                    </Select></Form.Item>

            </div>





        </div>
    )
}
export default AddEditRmbox