import {  useEffect, useState } from 'react';
import { Table, ConfigProvider, } from 'antd';


// redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../Redux/hooks";
import { setPaginationPropsIntervention, setshowDivsConditionsIntervention,setFilterInterventionActions,setDataInterventionActions } from "../../store/interventionActions";
import UseActionMenu from "./actionUserTable";
import frFR from 'antd/lib/locale/fr_FR';

import { useTranslation } from "react-i18next";

import moment from 'moment';
import 'moment/locale/zh-cn';
import { Datagrid } from '@empreinte/components.datagrid';
import {GridNorows} from '@empreinte/components.grid-norows';
import Hooks from "../../utils/hooks";
import { useMediaQuery } from '@mui/material';
moment.locale('en');


const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 1;

function useDataTableMedia({ columns, dataSource, updateEntityPath }: any) {
    const { t } = useTranslation();

    const { handelSearch,getAllData } = Hooks()
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

    const [selectedRow] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState<any>(DEFAULT_PAGE_NUMBER);
    const [pageSize,setPageSize] = useState<any>(DEFAULT_PAGE_SIZE);
    const totalength =useAppSelector((state)=>state.InterventionReducer.totalRecord)
    const [locale,] = useState<any>(frFR);
    const tabletColumn = useMediaQuery('(min-width:780px)');
    const mobileColumn = useMediaQuery('(min-width:550px)');
    //const [actionColumnView] = useActionMenuTable({ selectedRow, updateEntityPath , record });
    // use Selector redux
    const valuePagination = useAppSelector((state) => state.InterventionReducer.paginationPropsIntervention)
    /// i add values 
    const values = useAppSelector((state) => state.InterventionReducer.filterIntervention)
    const searchWord = values.search
    //const history = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            setshowDivsConditionsIntervention({
                showDivsConditionsName: "loader",
                showDivsConditionsValue: true,
            }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
      return () => { 
        dispatch(setFilterInterventionActions({
            FilterInterventionNameChange: "search",
            FilterInterventionValueChange: ""
    }));
 dispatch(setDataInterventionActions([]))
}
    }, [])

    useEffect(()=>{
        getAllData(currentPage,pageSize,searchWord)
    },[currentPage,pageSize,totalength])

    useEffect(()=>{
    if(searchWord)
    setCurrentPage(1)

    },[searchWord])


    const onSelectChange = (selectedRowKeys: any, record: any) => {
     
        //uncheck checkbox
        let filter: Array<any>
        filter = [];
        filter = dataSource
            .filter((item: any) => {
                return !selectedRowKeys.includes(item.id);
            })
            .map((ele: any) => ele.id);
        //concatenate state and selectedcheckboxes
        selectedRowKeys = [...valuePagination.id, ...selectedRowKeys];

        //remove some items
        let uniqItems: Array<any>
        let uniqItemsFilter: Array<any>
        uniqItems = Array.from(new Set(selectedRowKeys))
        uniqItemsFilter = uniqItems.filter((item) => {
            return !filter.includes(item);
        });

        dispatch(
            setPaginationPropsIntervention({
                PaginationPropsNameChange: "id",
                PaginationPropsValueChange: uniqItemsFilter,
            })
        );
        dispatch(
            setshowDivsConditionsIntervention({
                showDivsConditionsName: "elementSelected",
                showDivsConditionsValue: uniqItemsFilter.length,
            })
        );

        setSelectedRowKeys(uniqItemsFilter);
    };

    const rowSelection = {
        selectedRowKeys: valuePagination.id,
        getCheckboxProps: (record: any) => ({
            disabled: record.owner === false, // Column configuration not to be checked
        }),
        onChange: onSelectChange,
    };

    const updatedColumns = [
        ...columns,
        {
            title: t('Intervention.actions'),
            field:t('Intervention.actions'),
            // key: 'action',
            key:5,
            flex:1,
            renderCell: (action: any, record: any) => {
                return (
                    <UseActionMenu record={action} />
                )
            }

        },
    ];
    const filteredevents = handelSearch(values.search, dataSource)


    const resetPagination = () => {
        setCurrentPage(DEFAULT_PAGE_NUMBER);
    };

    const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        setCurrentPage(pagination.current - 1);
    };

    const DataTable = () => (
        <div className="DataTable">
            <ConfigProvider locale={locale}>
                {!totalength || !filteredevents.length?
                 <GridNorows content={t('Zone.noRowsFound')} />
                :
                <Datagrid
                className={"DataTable__custom-row"}
                height={"calc(90vh - 100px)"}
                selectionModel={selectedRowKeys}
                totalEntities={totalength}
                checkboxSelect={onSelectChange}
                // columns={updatedColumns}
                columns={
                    tabletColumn
                      ? updatedColumns
                      : mobileColumn
                        ?  
                          updatedColumns.filter(
                            column =>
                              column.dataIndex !== 'receptionDate'
                          ):
                           updatedColumns.filter(
                            column =>
                              column.dataIndex !== 'receptionDate' &&
                              column.dataIndex !== 'sendDate'
                          ).filter(
                            column =>
                              column.dataIndex !== 'receptionDate' &&
                              column.dataIndex !== 'sendDate' &&
                              column.dataIndex !== 'firstStartedDate'
                          )
                  }
                rows={dataSource && filteredevents}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setPageSize={setPageSize}
                pageSize={pageSize}
                ShowName={t('boitier.AuthorizedIP.rows')}
                EntriesName={t('boitier.AuthorizedIP.element')}
                OfPageName={t('boitier.AuthorizedIP.of')}
                />
                }
  

            </ConfigProvider>
        </div>
    );

    return {
        DataTable,

        selectedRow,
        selectedRowKeys,
        currentPage,
        pageSize,
        resetPagination,
    };
}

export default useDataTableMedia;
