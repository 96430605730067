
export const ZoneList = () => {

    const FilterZone = () => {
        return (
            {
                search: "",
                type: "",
               
            }
        )
    }

    const paginationPropsZone = () => {
        return (
            {
                order: 'descend',
                pageSize: 10,
                columnKey: 0,
                current: 1,
                id: [],
                idLive: 0,
            }
        )
    }

    const showDivsConditionsZone = () => {
        return ({
            Authorized:false,
            Loading:true,
            loader:false,
            elementSelected: 0,
            addZone: false,
            editZone: false,
            disablesubmitform: true,
        })
    }

    const AddZoneConstraintsData = () => {
        return (
            {
                id: "",
                name:"",
                path: null,
                ipStart: "",
                ipEnd: "",
                bandwidthLimit:"",
                zonelimit: "",
                usersimultane: "",
                bandwidthDisponible: "",
                rmbox: {
                    id: "",
                    name: ""
                },
                rmboxselected:""
            }
        )
    }

    const DataZone= () =>{
        return []
    }
    const DetailZone= () =>{
        return  {
            id: "",
            name:"",
            path: "",
            ipStart: "",
            ipEnd: "",
            bandwidthLimit:"",
            zonelimit: "",
            usersimultane: "",
            bandwidthDisponible: "",
            rmbox: {
                id: "",
                name: ""
            }
        }
    }



    return ({
        paginationPropsZone,
        showDivsConditionsZone,
        AddZoneConstraintsData,
        FilterZone,
        DataZone,
        DetailZone
    })
}
