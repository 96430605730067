import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../Redux/hooks";
import Hooks from "../utils/hooks";
import { Header } from "@empreinte/components.header";

const HeaderGroup = () => {
  const { t } = useTranslation();
  const {
    handleAddGroupeRmBox,
    onResetGroupeRmBox,
    handleAdd,
    UpdateGroupeRmBox,
    values,
  } = Hooks();
  const showDivCondition = useAppSelector(
    (state: any) => state.GroupeRmBoxReducer.showDivsConditionsGroupeRmBox
  );
  const { GroupeRmBox } = useAppSelector(
    (state: any) => state.GroupeRmBoxReducer
  );
  const handleUpdate = () => {
    UpdateGroupeRmBox(values);
  };

  const disabled = showDivCondition.disabledButton;
  return (
    <div className={"Header"}>
      <div>
        {!showDivCondition.addGroupeRmBox &&
          !showDivCondition.editGroupeRmBox && (
            <Header
              hasPermission="false"
              title={
                <span className={"profile_title"}>
                  {t("boitier.rmboxgroup")}
                </span>
              }
              firstBtnTitle={t("boitier.btnAdd")}
              mainPageBtnAction={handleAddGroupeRmBox}
              firstBtnIcon={<AddIcon />}
              onMainPage={true}
            />
          )}
      </div>
      <div>
        {showDivCondition.addGroupeRmBox && (
          <Header
            hasPermission="false"
            title={
              <span className={"profile_title"}>
                {t("boitier.Rmboxgroup.Add")}
              </span>
            }
            secondBtnTitle={t("boitier.btnCancel")}
            thirdBtnTitle={t("boitier.btnValidate")}
            arrowBackAction={onResetGroupeRmBox}
            CancelBtnAction={onResetGroupeRmBox}
            addBtnAction={handleAdd}
            onMainPage={false}
            isDisabled={disabled}
          />
        )}
      </div>

      <div>
        {showDivCondition.editGroupeRmBox && (
          <Header
            hasPermission="false"
            title={
              <span className={"profile_title"}>
                {" "}
                {t("boitier.Rmboxgroup.Edit")}
              </span>
            }
            onMainPage={false}
            secondBtnTitle={t("boitier.btnCancel")}
            thirdBtnTitle={t("boitier.btnValidate")}
            arrowBackAction={onResetGroupeRmBox}
            CancelBtnAction={onResetGroupeRmBox}
            addBtnAction={handleUpdate}
            isDisabled={disabled}
          />
        )}
      </div>
    </div>
  );
};

export default HeaderGroup;
