
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { PlusSquareOutlined, CheckOutlined, CloseOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../Redux/hooks";
import Hooks from "../utils/hooks";
const InterventionHeader = () => {
    // const navigate=useNavigate()
    const { t } = useTranslation();
    const { handleAddIntervention, onResetIntervention } = Hooks()
    const showDivCondition = useAppSelector((state) => state.InterventionReducer.showDivsConditionsIntervention)
    const disablesubmitform = useAppSelector((state) => state.InterventionReducer.showDivsConditionsIntervention.disablesubmitform)

    return (
        <div>
            <div className={"add_div"}>

                <div>
                    {
                        !showDivCondition.addIntervention && !showDivCondition.editIntervention
                            ?
                            <span className={"profile_title"}>{t('Intervention.intervention')}</span>
                            : showDivCondition.addIntervention ?
                                <> <ArrowLeftOutlined className='arrowleft' onClick={onResetIntervention} />  <span className={"profile_title"}>{t('Intervention.addintervention')}</span></> :
                                showDivCondition.editIntervention ?
                                    <> <ArrowLeftOutlined className='arrowleft' onClick={onResetIntervention} /><span className={"profile_title"}>{t('Intervention.modifintervention')}</span></> : ""

                    }

                </div>{/*DIVTITLEHEADER*/}

                <div className={"div_btn_add_cancel"}>

                    {
                        !showDivCondition.addIntervention && !showDivCondition.editIntervention
                            ?
                            <Button className="btnSubmit" variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddIntervention}>
                            <span className="spn_add">{t('Intervention.add')}</span>
                            </Button>
                            :
                            <div className={"div_btn_add_cancel"}>
                         
                                <Button className="btnCancel" variant="contained" color="inherit" startIcon={<CloseIcon />} onClick={onResetIntervention}>
                                        <span className="spn_cancel">{t('Intervention.cancel')}</span>
                                    </Button>
                            
                                <Button className="btnSubmit" variant="contained" color="primary" startIcon={<CheckIcon />} type="submit" name="submit"
                              disabled={disablesubmitform}>
                            <span className="spn_add">{t('Intervention.validate')}</span>
                        </Button>
                            </div>
                    }
                </div>

            </div>
        </div>
    )
}

export default InterventionHeader
