import React from 'react'
import { Breadcrumb} from "antd";
import { HomeOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../Redux/hooks";
import Hooks from "../utils/hooks";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const RmboxHeader = () => {
    // const navigate=useNavigate()
    const { t } = useTranslation();
    const { handleAddRmbox, onResetRmbox} = Hooks()
    const showDivCondition = useAppSelector((state) => state.RmboxReducer.showDivsConditionsRmbox)
    const disablesubmitform =  useAppSelector((state) => state.RmboxReducer.showDivsConditionsRmbox.disablesubmitform)
    return (
        <div>
            {!showDivCondition.interventionRmbox ?
                <div className={"add_div"}>

                    <div>
                        {
                            !showDivCondition.addRmbox && !showDivCondition.editRmbox
                                ?
                                <span className={"profile_title"}>{t('Rmbox.rmbox')}</span>
                                : showDivCondition.addRmbox ?
                                   
                                    <> <ArrowLeftOutlined className='arrowleft' onClick={onResetRmbox} /><span className={"profile_title"}>{t('Rmbox.addrmbox')}</span></>  
                                    : showDivCondition.editRmbox ? 
                                        <> <ArrowLeftOutlined className='arrowleft' onClick={onResetRmbox} /><span className={"profile_title"}>{t('Rmbox.modifrmbox')}</span></>  : ""

                        }

                    </div>{/*DIVTITLEHEADER*/}

                    <div className={"div_btn_add_cancel"}>
                        {
                            !showDivCondition.addRmbox && !showDivCondition.editRmbox
                                ?
                                <Button className="btnSubmit" variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddRmbox}>
                                <span className="spn_add">{t('Rmbox.add')}</span>
                                </Button>
                                :
                                <div className={"div_btn_add_cancel"}>
                                    <Button className="btnCancel" variant="contained" color="inherit" startIcon={<CloseIcon />} onClick={onResetRmbox}>
                                        <span className="spn_cancel">{t('Rmbox.cancel')}</span>
                                    </Button>

                                    <Button  className="btnSubmit"  variant="contained" color="primary" startIcon={<CheckIcon />}  type="submit"
                                    name="submit"
                                    disabled={disablesubmitform}
                        >
                            <span className="spn_add">{t('Rmbox.validate')}</span></Button>
                                </div>
                        }
                    </div>

                </div>
                : ""}

        </div>
    )
}

export default RmboxHeader
