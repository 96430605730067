
import { Tooltip} from "antd";
import { useTranslation } from "react-i18next";
import { Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  BorderColor as BorderColorIcon} from '@mui/icons-material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Hooks from "../../utils/hooks";
import { setOpenDelModal } from "../../store/GroupeRmBoxActions";
import "../../GroupeRmBox.scss";
import Detail from "../../Modal/Detail.modal";
import ProgramSchedule from "../../Modal/ProgramSchedule";
import { ModalConfirm } from "@empreinte/components.modal-confirm";
import { useState } from "react";
import { useDispatch } from "react-redux";

function useActionMenu({ record }: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleShowModal, handleEditGroupeRmBox,visible ,visiblePgm,handleCancel,handleShowProgramScheduleModal,deleteGroupeRmBox} = Hooks(); //deleteGroupeRmBox
  const [openModal,setOpenModal]=useState(false)

  return (
    <div>
      <div className="action">
        <div>
          <Tooltip placement="top" title={t("boitier.details")}>
            <VisibilityIcon className={"icon_action"} onClick={()=>handleShowModal(record?.id)} />
          </Tooltip>
        </div>
        <div>
          <Tooltip placement="top" title={t("boitier.edit")}>
            <BorderColorIcon
              className={"icon_action"}
              onClick={() => handleEditGroupeRmBox(record)}
            />
          </Tooltip>
        </div>
        <div>
          <Tooltip placement="top" title={t("boitier.delete")}>
            <DeleteIcon className={"icon_action"}  
                      onClick={() =>{
                               dispatch(setOpenDelModal(true));
                                setOpenModal(true)}}/>
          </Tooltip>
        </div>
        <div>
          <Tooltip placement="top" title={t("boitier.Rmboxgroup.pgmschedule")}>
            <CalendarTodayIcon className={"icon_action"} onClick={()=>handleShowProgramScheduleModal()}/>
          </Tooltip>
        </div>
          
        {/* Modal de confirmation avant suppression */}

       {openModal && (  
             <ModalConfirm
             className="deleteModal"
          
              open={openModal}
              onClose={()=>setOpenModal(false)}
              title={t('boitier.delete')}
              content={t('Zone.DeleteConfirmationOneDelete')}
              fullWidth={true}
              CancelBtnName={t('boitier.No')}
              ConfirmBtnName={t('boitier.Yes')}
              onCancel={()=>setOpenModal(false)}
              onConfirm={() => 
              {
                  deleteGroupeRmBox(record?.id)
                  dispatch(setOpenDelModal(false))
                  setOpenModal(false)
              }}
          />
          )}


        {/*Modal Details*/ }
        <Detail visible={visible} handleCancel={handleCancel}/>
        {/*Modal Details*/}
        {/*Modal Program schedule */}
        <ProgramSchedule visible={visiblePgm} handleCancel={handleCancel}/>
        {/*Modal Program schedule */}

      </div>
    </div>
  );
}

export default useActionMenu;
