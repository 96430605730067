import { InterventionList } from "./interventionConstants";
const { showDivsConditionsIntervention, AddInterventionConstraintsData, paginationPropsIntervention,
    FilterIntervention, DataIntervention, DetailIntervention } = InterventionList()

const InterventionListINITIALSTATE = {
    showDivsConditionsIntervention: showDivsConditionsIntervention(),
    paginationPropsIntervention: paginationPropsIntervention(),
    addInterventionConstraintsData: AddInterventionConstraintsData(),
    filterIntervention: FilterIntervention(),
    dataIntervention: DataIntervention(),
    detailIntervention: DetailIntervention(),
    RmBoxSelected: 3,
    ErrorMessage: "",
    totalRecord:0
}

export const InterventionReducer = (state = InterventionListINITIALSTATE, action: any) => {

    switch (action.type) {

        case "SET_FilterIntervention":
            const { FilterInterventionNameChange, FilterInterventionValueChange } = action.payload
            return {
                ...state,
                filterIntervention: { ...state.filterIntervention, [FilterInterventionNameChange]: FilterInterventionValueChange }
            }
        case "SET_PaginationPropsIntervention":
            const { PaginationPropsNameChange, PaginationPropsValueChange } = action.payload
            return {
                ...state,
                paginationPropsIntervention: { ...state.paginationPropsIntervention, [PaginationPropsNameChange]: PaginationPropsValueChange }
            }
        case "SET_showDivsConditionsIntervention":
            const { showDivsConditionsName, showDivsConditionsValue } = action.payload
            const showDivsConditionsObj = { ...state.showDivsConditionsIntervention, [showDivsConditionsName]: showDivsConditionsValue }
            return {
                ...state,
                showDivsConditionsIntervention: showDivsConditionsObj
            }
        case "SET_AddInterventionActions":
            const { addInterventionConstraintsDataNameChange, addInterventionConstraintsDataValueChange } = action.payload
            return {
                ...state,
                addInterventionConstraintsData: { ...state.addInterventionConstraintsData, [addInterventionConstraintsDataNameChange]: addInterventionConstraintsDataValueChange }
            }

        case "SET_DataInterventionActions":

            return {
                ...state,
                dataIntervention: action.payload
            }
        case "SET_DetailInterventionActions":

            return {
                ...state,
                detailIntervention: action.payload
            }

        case "SET_RmBoxSelectedInterventionActions":

            return {
                ...state,
                RmBoxSelected: action.payload
            }
        case "SET_ErrorrMessage":
            return {
                ...state,
                ErrorMessage: action.payload
            }
        case "SET_RECORD":
            return{
                ...state,
                totalRecord:action.payload
            }    
        default: {
            return state
        }

    }

}

