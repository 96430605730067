
import { Breadcrumb } from "antd";
import {
  HomeOutlined,
  ArrowLeftOutlined
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../Redux/hooks";
import Hooks from "../utils/hooks";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const Header = ({isDisabled}:Props) => {
  const { t } = useTranslation();
  const { handleAddAuthorizedIP, onResetAuthorizedIP,requiredFields,values } = Hooks();
  const showDivCondition = useAppSelector(
    (state) => state.AuthorizedIPReducer.showDivsConditionsAuthorizedIP
  );
  const {AuthorizedIP} = useAppSelector(
    (state) => state.AuthorizedIPReducer
  );

const isdisabled= ()=>{
  if (requiredFields()) return true
  let oldValue=  JSON.parse(JSON.stringify(AuthorizedIP))
  let newValue=  JSON.parse(JSON.stringify(values))
  delete oldValue.errors
  delete newValue.errors
  const HasErrors=values.errors ? Object.keys(values.errors).length > 0: false
  return showDivCondition.addAuthorizedIP ? isDisabled || HasErrors :JSON.stringify(oldValue)===JSON.stringify(newValue) || HasErrors
}
  return (
    <div className={"Header"}>
      
      <div className={"add_div"}>
        <div>
          {!showDivCondition.addAuthorizedIP &&
          !showDivCondition.editAuthorizedIP ? (
            <span className={"profile_title"}>{t("boitier.authorizedIp")}</span>
          ) : showDivCondition.addAuthorizedIP ? (
            <span className={"profile_title"}>
      
              <> <ArrowLeftOutlined className='arrowleft' onClick={onResetAuthorizedIP} /><span className={"profile_title"}>{t('boitier.AuthorizedIP.Add')}</span></>  
            </span>
          ) : showDivCondition.editAuthorizedIP ? (
            <span className={"profile_title"}>
              <> <ArrowLeftOutlined className='arrowleft' onClick={onResetAuthorizedIP} /><span className={"profile_title"}>{t('boitier.AuthorizedIP.Edit')}</span></>  
            </span>
          ) : (
            ""
          )}
        </div>

        <div className={"div_btn_add_cancel"}>
          {!showDivCondition.addAuthorizedIP &&
          !showDivCondition.editAuthorizedIP ? (
  
          <Button className="btnSubmit" 
          variant="contained" 
          color="primary" 
          startIcon={<AddIcon />} 
          onClick={handleAddAuthorizedIP}>
         <span className={"spn_add"}>{t("boitier.btnAdd")}</span>
          </Button>
          ) : (
            <div className={"div_btn_add_cancel"}>
             

              <Button className="btnCancel"
               variant="contained"
                color="inherit" 
                startIcon={<CloseIcon />} 
                onClick={onResetAuthorizedIP}>
                <span className={"spn_cancel"}>{t("boitier.btnCancel")}</span>
                  </Button>
            
              <Button
                            className="btnSubmit"
                            variant="contained"
                            color="primary"
                            startIcon={<CheckIcon />}
                            type="submit"
                            name="submit"
                            disabled={
                              isdisabled()
                            }
                        >
                             <span className={"spn_add"}>{t("boitier.btnValidate")}</span>
                        </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};


interface Props{
  isDisabled:boolean
}
export default Header;
