import React, {useState ,useEffect} from 'react';
import { useDispatch } from "react-redux";
import moment from 'moment';

import { Table, ConfigProvider, } from 'antd';
import 'moment/locale/zh-cn';

import { setPaginationPropsGroupeRmBox, setshowDivsConditionsGroupeRmBox,setOpenDeleteSpace,setSelected ,setGroupeRmBoxDataActions,setFilterGroupeRmBoxActions} from "../../store/GroupeRmBoxActions";
import { useAppSelector } from "../../../../Redux/hooks";
import UseActionMenu from "./actionsUserTable";
import Hooks from "../../utils/hooks"; 
import { Datagrid } from '@empreinte/components.datagrid';
import { useTranslation } from "react-i18next";
import frFR from 'antd/lib/locale/fr_FR';
import { useMediaQuery } from '@mui/material';
import {GridNorows} from '@empreinte/components.grid-norows';
moment.locale('en');
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 1;

function useDataTableMedia({ columns,  updateEntityPath }: any) {
    const { t } = useTranslation();
    const { handelFilter, handelSearch ,getListGroupeRmBox} = Hooks()
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
    const [selectedRow] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState<any>(DEFAULT_PAGE_NUMBER);
    const [pageSize,setPageSize] = useState<any>(DEFAULT_PAGE_SIZE);
    const [locale] = useState<any>(frFR);
    const totalength = useAppSelector((state)=>state.GroupeRmBoxReducer.totalRecord)
    // use Selector redux
    const valuePagination = useAppSelector((state) => state.GroupeRmBoxReducer.paginationPropsGroupeRmBox)
    const List = useAppSelector((state) => state.GroupeRmBoxReducer.List)
    /// i add values 
    const values = useAppSelector((state) => state.GroupeRmBoxReducer.filterGroupeRmBox)
    const searchWord = values.search;
    const tabletColumn = useMediaQuery('(min-width:780px)');
    const mobileColumn = useMediaQuery('(min-width:550px)');
    const dispatch = useDispatch();

  //  get data from db
 
  

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(
        setshowDivsConditionsGroupeRmBox({
          showDivsConditionsName: "loader",
          showDivsConditionsValue: true,
        })
      )
  return () => { 
    dispatch(setFilterGroupeRmBoxActions({               
    FilterGroupeRmBoxNameChange: "search",
    FilterGroupeRmBoxValueChange: ''
}));
    dispatch(setGroupeRmBoxDataActions([]))
    }
    }, [])

    useEffect(()=>{
        getListGroupeRmBox(currentPage,pageSize,searchWord)
    },[currentPage,pageSize,totalength])

    useEffect(()=>{
    if(searchWord)
    setCurrentPage(1)

    },[searchWord])



    const onSelectChange = (selectedRowKeys: any, record: any) => {

        //uncheck checkbox
        let filter: Array<any>
        filter = [];
        filter = List
            .filter((item: any) => {
                return !selectedRowKeys.includes(item.id);
            })
            .map((ele: any) => ele.id);
        //concatenate state and selectedcheckboxes
        selectedRowKeys = [...valuePagination.id, ...selectedRowKeys];

        //remove some items
        let uniqItems: Array<any>
        let uniqItemsFilter: Array<any>
        uniqItems = Array.from(new Set(selectedRowKeys))
        uniqItemsFilter = uniqItems.filter((item) => {
            return !filter.includes(item);
        });

        dispatch(
            setPaginationPropsGroupeRmBox({
                PaginationPropsNameChange: "id",
                PaginationPropsValueChange: uniqItemsFilter,
            })
        );
        dispatch(
            setshowDivsConditionsGroupeRmBox({
                showDivsConditionsName: "elementSelected",
                showDivsConditionsValue: uniqItemsFilter.length,
            })
        );

        setSelectedRowKeys(uniqItemsFilter);
    };

    const rowSelection = {
        selectedRowKeys: valuePagination.id,
        getCheckboxProps: (record: any) => ({
            disabled: record.owner === false, // Column configuration not to be checked
        }),
        onChange: onSelectChange,
    };

    const updatedColumns = [
        ...columns,
        {
            title: 'Actions',
            key: 5,
            field:'Actions',
            flex:1,
            renderCell: (action: any, record: any) => {
                return (
                    <UseActionMenu record={action} />
                )
            }

        },
    ];

    const filteredeventsfilter = handelFilter(values.type, List)
    const filteredevents = handelSearch(values.search, filteredeventsfilter)


    const resetPagination = () => {
        setCurrentPage(DEFAULT_PAGE_NUMBER);
    };

    const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        setCurrentPage(pagination.current - 1);
    };

    function getFilteredEvents(filteredEvents:any, e:any) {
        let tab = filteredEvents.filter((item:any) => {
          return e.includes(item.id);
        });
        let newArray = Array.from(tab, (item:any) => {
          return item.id;
        });
        return newArray;
      }


    const DataTable = () => (
        <div className="DataTable">
            <ConfigProvider locale={locale}>
            {!filteredevents.length || !totalength ?
                <GridNorows  content={t('Zone.noRowsFound')} />
                :
               <Datagrid
                columns={
                    tabletColumn
                      ? updatedColumns
                      : mobileColumn
                        ?  
                          updatedColumns.filter(
                            column =>
                               column.dataIndex !== 'schedule'&&
                               column.dataIndex !== 'syncTime'
                          ):
                           updatedColumns.filter(
                            column =>
                                column.dataIndex !== 'schedule' &&
                                column.dataIndex !== 'syncTime' &&
                                column.dataIndex !== 'bandwidthLimit' 
                          ).filter(
                            column =>
                                column.dataIndex !== 'schedule' &&
                                column.dataIndex !== 'syncTime' 
                          )
                  }
                className={"DataTable__custom-row"}
                height={"calc(90vh - 100px)"}
                selectionModel={selectedRowKeys}
                totalEntities={totalength}
                checkboxSelect={e => {
                    let newArray = getFilteredEvents(filteredevents, e);
                    setSelectedRowKeys(e);
                    dispatch(setSelected(newArray));
                    dispatch(setOpenDeleteSpace(newArray.length > 0));
 
                    dispatch(setSelected(newArray));
                }}
                // columns={updatedColumns}
                rows={List || filteredevents}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setPageSize={setPageSize}
                pageSize={pageSize}
                ShowName={t('boitier.AuthorizedIP.rows')}
                EntriesName={t('boitier.AuthorizedIP.element')}
                OfPageName={t('boitier.AuthorizedIP.of')}
                />}
            </ConfigProvider>
        </div>
    );

    return {
        DataTable,
        selectedRow,
        selectedRowKeys,
        currentPage,
        pageSize,
        resetPagination,
    };
}

export default useDataTableMedia;
