import { useEffect, useState } from "react";
import { Card, Form, Input, Select, Row, Col
    // , Button 
} from "antd";
import '../../zone.scss'
import { useTranslation } from "react-i18next";
import Hooks from "../../utils/hooks";
import { Upload } from 'antd';
import { FileImageTwoTone } from '@ant-design/icons';
import { useAppSelector } from "../../../../Redux/hooks";
import { DragdropUpload } from "@empreinte/components.dragdrop-upload";

const { Dragger } = Upload;

const AddEditZone = () => {
    //const { getFieldDecorator } = props.form;
    const { t } = useTranslation();
    const { Option } = Select;

    const { values, props, disabledsubmitbutton } = Hooks();
    const listrmbox = useAppSelector((state) => state.ZoneReducer.listrmbox)
    const Zonecondition = useAppSelector((state) => state.ZoneReducer.showDivsConditionsZone)
    const [RmBox, setRmBox] = useState<any>({})
 
    const [datafiledsrequired, setDatafiledsrequired] = useState<any>({
        name: false,
        ipStart: false,
        bandwidthLimit: false,
        usersimultane: false,
        ipEnd: false,
        zonelimit: false,
        bandwidthDisponible: false,
        rmbox: false,
        url: true,


    })

    const testfields = () => {
        let completed = false
        // eslint-disable-next-line array-callback-return
        Object.values(datafiledsrequired).map((item: any) => {
            if (item === false) {
                completed = true
            }
        })
        if (completed === false) {
            disabledsubmitbutton("disabled")
        } else {
            disabledsubmitbutton("notdisabled")
        }
    }
    const testfieldsmodif = () => {
        let completed = false
        // eslint-disable-next-line array-callback-return
        Object.values(datafiledsrequired).map((item: any) => {
            if (item === true) {
                completed = true
            }
        })
        if (completed === false) {
            disabledsubmitbutton("notdisabled")

        } else {
            disabledsubmitbutton("disabled")
        }
    }


    useEffect(() => {

        testfields()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Zonecondition.addZone, Zonecondition.editZone])
    const onChangename = (e: any) => {
        if (e.target.value !== "") {
            datafiledsrequired.name = true
            setDatafiledsrequired(datafiledsrequired)
        } else {
            datafiledsrequired.name = false
            setDatafiledsrequired(datafiledsrequired)
        }
        if (Zonecondition.addZone) {
            testfields()
        } else if (Zonecondition.editZone) {
            testfieldsmodif()
        }

    }
    const onChangeipStart = (e: any) => {
        if (e !== "") {
            datafiledsrequired.ipStart = true
            setDatafiledsrequired(datafiledsrequired)
        } else {
            datafiledsrequired.ipStart = false
            setDatafiledsrequired(datafiledsrequired)
        }
        if (Zonecondition.addZone) {
            testfields()
        } else if (Zonecondition.editZone) {
            testfieldsmodif()
        }

    }
    const onChangebandwidthLimit = (e: any) => {

        if (e.target.value !== "") {
            datafiledsrequired.bandwidthLimit = true
            setDatafiledsrequired(datafiledsrequired)
        } else {
            datafiledsrequired.bandwidthLimit = false
            setDatafiledsrequired(datafiledsrequired)
        }
        if (Zonecondition.addZone) {
            testfields()
        } else if (Zonecondition.editZone) {
            testfieldsmodif()
        }

    }
    const onChangeusersimultane = (e: any) => {

        if (e.target.value !== "") {
            datafiledsrequired.usersimultane = true
            setDatafiledsrequired(datafiledsrequired)
        } else {
            datafiledsrequired.usersimultane = false
            setDatafiledsrequired(datafiledsrequired)
        }
        if (Zonecondition.addZone) {
            testfields()
        } else if (Zonecondition.editZone) {
            testfieldsmodif()
        }
    }
    const onChangeipEnd = (e: any) => {

        if (e !== "") {
            datafiledsrequired.ipEnd = true
            setDatafiledsrequired(datafiledsrequired)
        } else {
            datafiledsrequired.ipEnd = false
            setDatafiledsrequired(datafiledsrequired)
        }
        if (Zonecondition.addZone) {
            testfields()
        } else if (Zonecondition.editZone) {
            testfieldsmodif()
        }
    }
    const onChangezonelimit = (e: any) => {

        if (e !== "") {
            datafiledsrequired.zonelimit = true
            setDatafiledsrequired(datafiledsrequired)
        } else {
            datafiledsrequired.zonelimit = false
            setDatafiledsrequired(datafiledsrequired)
        }
        if (Zonecondition.addZone) {
            testfields()
        } else if (Zonecondition.editZone) {
            testfieldsmodif()
        }
    }
    const onChangebandwidthDisponible = (e: any) => {

        if (e !== "") {
            datafiledsrequired.bandwidthDisponible = true
            setDatafiledsrequired(datafiledsrequired)
        } else {
            datafiledsrequired.bandwidthDisponible = false
            setDatafiledsrequired(datafiledsrequired)
        }
        if (Zonecondition.addZone) {
            testfields()
        } else if (Zonecondition.editZone) {
            testfieldsmodif()
        }
    }
    const onChangeRmBox = (e: any) => {

        if (e !== "") {
            datafiledsrequired.rmbox = true
            setDatafiledsrequired(datafiledsrequired)
        } else {
            datafiledsrequired.rmbox = false
            setDatafiledsrequired(datafiledsrequired)
        }
        if (Zonecondition.addZone) {
            testfields()
        } else if (Zonecondition.editZone) {
            testfieldsmodif()
        }
    }
    const onChangeCheminUrl = (e: any) => {

        if (e !== "") {
            datafiledsrequired.url = true
            setDatafiledsrequired(datafiledsrequired)
        } 
        else {
            datafiledsrequired.url = false
            setDatafiledsrequired(datafiledsrequired)
        }
        if (Zonecondition.addZone) {
            testfields()
        } else if (Zonecondition.editZone) {
            testfieldsmodif()
        }
    }
      
    return (
        <div className={"addedititem"}>

            <div className="zoneAddCard" style={{maxWidth:'100%'}}>
            <Col >
                    <Card >

                          <DragdropUpload 
                        onChange={()=>{}}
                        dropzoneText={t('Zone.dropZone')}
                        acceptedFiles={[".xlsx"]}
                        />  
                        <span>{t('Zone.selectFileUpload')} .xlsx</span>
                    </Card>
                </Col>

                <Col>
                    <Card >
                        <Row gutter={16} className="addZoneClass">
                            <Col span={12} className="addZoneCol">
                                 <div className={"InputLablDiv"}>
                                    <Form.Item
                                        label={<>{t('Zone.name')}<div className="required">*</div></>}
                                        name="name"
                                        className={"formItem3"}
                                        rules={[{ required: true, message: t('Zone.msgerrorname') }]}
                                    >
                                        <Input value={values.name} name={"name"} id={"name"}
                                            placeholder={t('Zone.name')} onChange={onChangename} />
                                    </Form.Item>


                                </div>


                                <div className={"InputLablDiv"}>
                                    <Form.Item
                                        label={<>{t('Zone.adressIpStart')}<div className="required">*</div></>}
                                        name="ipStart"
                                        className={"formItem3"}
                                        rules={[{
                                            required: true,
                                            message: t('Zone.msgerroripStart'),
                                        },


                                        {
                                            validator: (_, value) => {
                                                if (!value) {
                                                return Promise.resolve();
                                                } else if (/^(?!.*\.\.)(?!.*\.$)((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/gi.test(value)) {
                                                return Promise.resolve();
                                                } else {   
                                                disabledsubmitbutton("notdisabled")
                                                return Promise.reject(new Error(t('Zone.msgerroripStart')));
                                                }
                                            },
                                            },

                                        ]}

                                    >
                                        <Input value={values.ipStart} name={"ipStart"} id={"ipStart"}  pattern="^(?!.*\.\.)(?!.*\.$)((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
                                            placeholder={t('Zone.adressIpStart')} onChange={onChangeipStart} />
                                    </Form.Item>

                                </div>
                                <div className={"InputLablDiv"}>
                                <Form.Item
                                    label={<>{t('Zone.bandwidthLimit')}<div className="required">*</div></>}
                                    name="bandwidthLimit"
                                    className={"formItem3"}
                                    rules={[
                                        {
                                        required: true,
                                        message: t('Zone.msgerrorbandwidthLimit'),
                                        },
                                        {
                                        validator: (_, value) => {
                                            if (!value) {
                                            return Promise.resolve();
                                            } else if (/^[1-9]\d*$/gi.test(value)) {
                                            return Promise.resolve();
                                            } else {
                                                disabledsubmitbutton("notdisabled")
                                            return Promise.reject(new Error(t('Zone.msgerrorbandwidthLimit')));
                                            }
                                        }
                                        }
                                    ]}
                                    >

                                        <Input value={values.bandwidthLimit} name={"bandwidthLimit"} type="number"
                                            id={"bandwidthLimit"} placeholder={t('Zone.bandwidthLimit')} onChange={onChangebandwidthLimit} />
                                    </Form.Item>

                                </div>
                                <div className={"InputLablDiv"}>
                                <Form.Item
                                    label={<>{t('Zone.usersimultane')}<div className="required">*</div></>}
                                    name="usersimultane"
                                    className={"formItem3"}
                                    rules={[
                                        {
                                        required: true,
                                        message: t('Zone.msgerrorusersimultane'),
                                        },
                                        {
                                        validator: (_, value) => {
                                            if (!value) {
                                            return Promise.resolve();
                                            } else if (/^[1-9]\d*$/gi.test(value)) {
                                            return Promise.resolve();
                                            } else {   
                                                disabledsubmitbutton("notdisabled")
                                            return Promise.reject(new Error(t('Zone.msgerrorusersimultane')));
                                            }
                                        },
                                        },
                                    ]}
                                    >
                                        <Input name={"usersimultane"} id={"usersimultane"} type="number"
                                            placeholder={t('Zone.usersimultane')} onChange={onChangeusersimultane} /></Form.Item>

                                </div>
                            </Col>
                            <Col span={12} className="addZoneCol">
                                <div className={"InputLablDiv"}>
                                    <Form.Item
                                        label={t('Zone.cheminUrl')}
                                        name="path"
                                        className={"formItem3"}


                                    >
                                        <Input name={"path"} id={"path"} onChange={onChangeCheminUrl}
                                            placeholder={t('Zone.cheminUrlplaceholder')} />
                                    </Form.Item>


                                </div>

                                <div className={"InputLablDiv"}>
                                    <Form.Item
                                        label={<>{t('Zone.adressIpEnd')}<div className="required">*</div></>}
                                        name="ipEnd"
                                        className={"formItem3"}
                                        rules={[{
                                            required: true,
                                            message: t('Zone.msgerroripEnd'),
                                        },
                                        {
                                            validator: (_, value) => {
                                                if (!value) {
                                                return Promise.resolve();
                                                } else if (/^(?!.*\.\.)(?!.*\.$)((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/gi.test(value)) {
                                                return Promise.resolve();
                                                } else {    
                                                    disabledsubmitbutton("notdisabled") 
                                                return Promise.reject(new Error(t('Zone.msgerroripEnd')));
                                                }
                                            },
                                            },

                                        ]}
                                    >
                                        <Input name={"ipEnd"} id={"ipEnd"} pattern="^(?!.*\.\.)(?!.*\.$)((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
                                            placeholder={t('Zone.adressIpEnd')} onChange={onChangeipEnd} />
                                    </Form.Item>

                                </div>
                                <div className={"InputLablDiv"}>
                                    <Form.Item
                                        label={<>{t('Zone.zonelimit')}<div className="required">*</div></>}
                                        name="zonelimit"
                                        className={"formItem3"}
                                        rules={[{ 
                                            required: true,
                                             message: t('Zone.msgerrorzonelimit') 
                                            },
                                            {
                                                validator: (_, value) => {
                                                    if (!value) {
                                                    return Promise.resolve();
                                                    } else if (/^[1-9]\d*$/gi.test(value)) {
                                                    return Promise.resolve();
                                                    } else {  
                                                        disabledsubmitbutton("notdisabled")
                                                    return Promise.reject(new Error(t('Zone.msgerrorzonelimit')));
                                                    }
                                                },
                                                },
                                        ]}
                                    >
                                        <Input name={"zonelimit"} id={"zonelimit"} type="number"
                                            placeholder={t('Zone.zonelimit')} onChange={onChangezonelimit} />
                                    </Form.Item>

                                </div>
                                <div className={"InputLablDiv"}>
                                    <Form.Item
                                        label={<>{t('Zone.bandwidthDisponible')}<div className="required">*</div></>}
                                        name="bandwidthDisponible"
                                        className={"formItem3"}
                                        rules={[{ 
                                            required: true,
                                             message: t('Zone.msgerrorbandwidthDisponible')
                                             },
                                             {
                                                validator: (_, value) => {
                                                    if (!value) {
                                                    return Promise.resolve();
                                                    } else if (/^[1-9]\d*$/gi.test(value)) {
                                                    return Promise.resolve();
                                                    } else {
                                                    disabledsubmitbutton("notdisabled")
                                                    return Promise.reject(new Error(t('Zone.msgerrorbandwidthDisponible')));
                                                    }
                                                },
                                                },
                                            ]}
                                    >
                                        <Input name={"bandwidthDisponible"} id={"bandwidthDisponible"} type="number"
                                            placeholder={t('Zone.bandwidthDisponible')} onChange={onChangebandwidthDisponible} />
                                    </Form.Item>

                                </div>
                            </Col>
                        </Row>
                        <Row className="addZoneClass">
                            <div className={"InputLablDiv"}>

                                <Form.Item
                                    label={<div>{t('Zone.rmbox')}<div className="required">*</div></div>}
                                    name="rmboxselected"
                                    className={"formItem3"}>

                                    <Select
                                        className="selectstyle"
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder={t('Zone.rmbox')}
                                        optionFilterProp="children"
                                        onChange={onChangeRmBox}
                                        value={values.rmbox}
                                        defaultValue={values.rmbox !==undefined ? values.rmbox : RmBox}
                                    >

                                        {listrmbox.map((item: any, key: any) => {
                                            return <Option onChange={() => setRmBox(item.name)} value={item.id} key={key}>{item.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>

                            </div>
                        </Row>


                    </Card>

                </Col>
            
            </div>










        </div>
    )
}
export default AddEditZone