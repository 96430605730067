
export const GroupeRmBoxList = () => {

    const FilterGroupeRmBox = () => {
        return (
            {
                search: "",
                type: "",
            }
        )
    }

    const paginationPropsGroupeRmBox = () => {
        return (
            {
                order: 'descend',
                pageSize: 10,
                columnKey: 0,
                current: 1,
                id: [],
                idLive: 0,
            }
        )
    }

    const showDivsConditionsGroupeRmBox = () => {
        return ({
            disabledButton:true,
            Authorized:false,
            Loading:true,
            loader:false,
            elementSelected: 0,
            addGroupeRmBox: false,
            editGroupeRmBox: false
        })
    }

    const AddGroupeRmBoxConstraintsData = () => {
        return (
            {
                id:"",
                name:"",
                bandwidthLimit:"",
                syncTime:"",
                schedule:"",
                programs: []
            }
        )
    }
    const GroupeRmBoxTypes={
        LOADING:"LOADING",
        SET_GroupeRmBox_DATA:"SET_GroupeRmBox_DATA",
    }
    return ({
        paginationPropsGroupeRmBox,
        showDivsConditionsGroupeRmBox,
        AddGroupeRmBoxConstraintsData,
        FilterGroupeRmBox,
        GroupeRmBoxTypes
    })
}
