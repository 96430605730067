import { TYPES } from "./types";

const initState = {
  userData: {},
  unauthorizedUser: true,
  loading_screen: true,
  
};

const ReducerUserData = (state = initState, action) => {
    switch (action.type) {
      case TYPES.GET_USER_DATA:
        return {
          ...state,
          userData: action.payload.userData.user,
          unauthorizedUser: false,
          loading_screen:false
        };
      case TYPES.ON_LOADING_SCREEN:
          return {
               ...state,
               loading_screen: action.payload,
          }
      case TYPES.UNAUTHORIZED: 
          return {
              ...state,
              unauthorizedUser:true,
              loading_screen:false
          }
      default:
        return state;
    }
  };
  export default ReducerUserData;
  