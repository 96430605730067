import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    setAddZoneActions, setFilterZoneActions, setshowDivsConditionsZone,
    setDataZoneActions, setDetailZoneActions, setPaginationPropsZone,
    setListRmboxZoneActions,
    setOpenDeleteSpace
} from "../store/zoneActions"
import { Form, message } from "antd";
import { useAppSelector } from "../../../Redux/hooks";
import {
    GetData,
    PostData,
    DeleteData,
} from "../../../APIcalls";
import { useTranslation } from "react-i18next";

const Hooks = () => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState<any>(false)
    const dispatch = useDispatch()
    const [formAddZone] = Form.useForm()

    // use Selector redux
    const values = useAppSelector((state) => state.ZoneReducer.addZoneConstraintsData)
    const listitemselected = useAppSelector((state) => state.ZoneReducer.paginationPropsZone.id)
    const Zonecondition = useAppSelector((state) => state.ZoneReducer.showDivsConditionsZone);
    const selectedItems = useAppSelector((state)=>state.ZoneReducer.selectedCase)
    const [pageSize,setPageSize]=useState(10)
    const [currentPage,setCurrentPage]=useState(1);
    const [searchWord]=useState('')
    useEffect(() => {
    }, [])


    ////*************** show Modal Function ******************/////////
    const handleShowModal = (id: any) => {
        getInfoItem(id)

    }
    const handleCancel = () => {
        setVisible(false)
    }


    /////********* show add edit Zone ****************/////
    const handleAddZone = () => {
        formAddZone.setFieldsValue({
            id: "",
            name: "",
            path: "",
            ipStart: "",
            ipEnd: "",
            bandwidthLimit: "",
            zonelimit: "",
            usersimultane: "",
            bandwidthDisponible: "",
            rmbox: {
                id: "",
                name: ""
            }
        })

        dispatch(
            setshowDivsConditionsZone({
                showDivsConditionsName: "addZone",
                showDivsConditionsValue: true,
            })
        );

    }



    const onResetZone = async () => {
        /**edit item list */
        dispatch(
            setshowDivsConditionsZone({
                showDivsConditionsName: "editZone",
                showDivsConditionsValue: false,
            })
        );
        dispatch(
            setshowDivsConditionsZone({
                showDivsConditionsName: "addZone",
                showDivsConditionsValue: false,
            })
        );
        await dispatch(setAddZoneActions({
            addZoneConstraintsDataNameChange: "id",
            addZoneConstraintsDataValueChange: ""
        }));
        await dispatch(setAddZoneActions({
            addZoneConstraintsDataNameChange: "name",
            addZoneConstraintsDataValueChange: ""
        }));
        await dispatch(setAddZoneActions({
            addZoneConstraintsDataNameChange: "path",
            addZoneConstraintsDataValueChange: null
        }));
        await dispatch(setAddZoneActions({
            addZoneConstraintsDataNameChange: "ipStart",
            addZoneConstraintsDataValueChange: ""
        }));
        await dispatch(setAddZoneActions({
            addZoneConstraintsDataNameChange: "ipEnd",
            addZoneConstraintsDataValueChange: ""
        }));
        await dispatch(setAddZoneActions({
            addZoneConstraintsDataNameChange: "bandwidthLimit",
            addZoneConstraintsDataValueChange: ""
        }));
        await dispatch(setAddZoneActions({
            addZoneConstraintsDataNameChange: "zonelimit",
            addZoneConstraintsDataValueChange: ""
        }));
        await dispatch(setAddZoneActions({
            addZoneConstraintsDataNameChange: "usersimultane",
            addZoneConstraintsDataValueChange: ""
        }));
        await dispatch(setAddZoneActions({
            addZoneConstraintsDataNameChange: "bandwidthDisponible",
            addZoneConstraintsDataValueChange: ""
        }));
        await dispatch(setAddZoneActions({
            addZoneConstraintsDataNameChange: "rmboxselected",
            addZoneConstraintsDataValueChange: ""
        }));
        await dispatch(setAddZoneActions({
            addZoneConstraintsDataNameChange: "rmbox",
            addZoneConstraintsDataValueChange: {
                id: "",
                name: ""
            }
        }));

        dispatch(setOpenDeleteSpace(false))



    }

    ////****************** function Filter *****************///////
    //*********************** search header **************///////
    const handleSearchRow = async (event: any) => {
             dispatch(setFilterZoneActions({               
                FilterZoneNameChange: "search",
                FilterZoneValueChange: event
            }));
        
    }
    const onChangeFolder = async (e: any) => {

        await dispatch(setFilterZoneActions({
            FilterZoneNameChange: "type",
            FilterZoneValueChange: e
        }));
    }

    /////**************** Form Add Zone *******************/////////
    const onFinishAddZone = (values: any) => {

        if (Zonecondition.addZone === true) {
            AddData(values)

        } else if (Zonecondition.editZone === true) {

            ModifData(values, Zonecondition.elementSelected)
        }

    }
    const onFinishFailedAddZone = (errorInfo: any) => {

    }
    const onValueFormUserchangeZone = async (val: any) => {

        if (val.title) {
            await dispatch(setAddZoneActions({
                addZoneConstraintsDataNameChange: "key",
                addZoneConstraintsDataValueChange: val.key
            }));
        } else if (val.descZone) {
            await dispatch(setAddZoneActions({
                addZoneConstraintsDataNameChange: "value",
                addZoneConstraintsDataValueChange: val.value
            }));
        }

    }

    const disabledsubmitbutton = async (type: any) => {

        if (type === "disabled") {
            await dispatch(setshowDivsConditionsZone({
                showDivsConditionsName: "disablesubmitform",
                showDivsConditionsValue: false
            }));
        } else if (type === "notdisabled") {
            await dispatch(setshowDivsConditionsZone({
                showDivsConditionsName: "disablesubmitform",
                showDivsConditionsValue: true
            }));
        }

    }
    ////////****** Edit Zone ***************////
    const handleEditZone = async (record: any) => {

        try {
            const res = await GetData(`/zone/${record.id}`);


            if (res.status === 200) {


                await dispatch(setshowDivsConditionsZone({
                    showDivsConditionsName: "elementSelected",
                    showDivsConditionsValue: res.data.id
                }));
                await dispatch(setAddZoneActions({
                    addZoneConstraintsDataNameChange: "id",
                    addZoneConstraintsDataValueChange: res.data.id
                }));

                await dispatch(setAddZoneActions({
                    addZoneConstraintsDataNameChange: "name",
                    addZoneConstraintsDataValueChange: res.data.name
                }));
                await dispatch(setAddZoneActions({
                    addZoneConstraintsDataNameChange: "path",
                    addZoneConstraintsDataValueChange: res.data.path
                }));
                await dispatch(setAddZoneActions({
                    addZoneConstraintsDataNameChange: "ipStart",
                    addZoneConstraintsDataValueChange: res.data.ipStart
                }));
                await dispatch(setAddZoneActions({
                    addZoneConstraintsDataNameChange: "ipEnd",
                    addZoneConstraintsDataValueChange: res?.data?.ipEnd
                }));
                await dispatch(setAddZoneActions({
                    addZoneConstraintsDataNameChange: "bandwidthLimit",
                    addZoneConstraintsDataValueChange: res.data.bandwidthLimit
                }));
                await dispatch(setAddZoneActions({
                    addZoneConstraintsDataNameChange: "zonelimit",
                    addZoneConstraintsDataValueChange: res.data.zonelimit
                }));
                await dispatch(setAddZoneActions({
                    addZoneConstraintsDataNameChange: "usersimultane",
                    addZoneConstraintsDataValueChange: res.data.usersimultane
                }));
                await dispatch(setAddZoneActions({
                    addZoneConstraintsDataNameChange: "bandwidthDisponible",
                    addZoneConstraintsDataValueChange: res.data.bandwidthDisponible
                }));
               
                if (res.data.rmbox !== null) {
                    await dispatch(setAddZoneActions({
                        addZoneConstraintsDataNameChange: "rmbox",
                        addZoneConstraintsDataValueChange: res?.data?.rmbox?.id
                    }));
                }else{
                    await dispatch(setAddZoneActions({
                        addZoneConstraintsDataNameChange: "rmbox",
                        addZoneConstraintsDataValueChange: res?.data?.rmbox?.name
                    }));
                }
                // if (res?.data?.rmbox !== null) {
                //     await dispatch(setAddZoneActions({
                //         addZoneConstraintsDataNameChange: "rmboxselected",
                //         addZoneConstraintsDataValueChange: res?.data?.rmbox?.id
                //     }));
                // }
                await dispatch(
                    setshowDivsConditionsZone({
                        showDivsConditionsName: "editZone",
                        showDivsConditionsValue: true,
                    })
                );
            }

        } catch (e) {
            message.error({ content: t('Zone.messageerroroperation'), duration: 2 })

        }
    }
    /********************************** */
    const handelSearch = (word: string, tab: any) => {

        if (word.trim() === "") return tab;

        // eslint-disable-next-line array-callback-return
        const res = tab.filter((obj: any) => {
        
            if (obj.name.toLowerCase().includes(word.toLowerCase()) ||
                obj.ipStart.toLowerCase().includes(word.toLowerCase()) ||
                obj.ipEnd.toLowerCase().includes(word.toLowerCase()) ||
                obj.rmbox?.name.toLowerCase().includes(word.toLowerCase()) ||
                // eslint-disable-next-line eqeqeq
                obj.bandwidthLimit == word ||
                // eslint-disable-next-line eqeqeq
                obj.zonelimit == word


            ) return obj;
        });

        return res;
    };
    /*******************Crud *********************** */
    const getAllData = async (currentPage:any,pageSize:any,searchWord:any) => {
        try {
            const offset = (currentPage - 1) * pageSize;
            const res = await GetData(`/dataTable/zones?columns%5B0%5D%5Borderable%5D=true&draw=${currentPage}&length=${pageSize}&order%5B0%5D%5Bcolumn%5D=0&order%5B0%5D%5Bdir%5D=asc&start=${offset}&search[value]=${searchWord}`);
            if (res?.status === 200 && res?.data?.code === 200) {
                dispatch(
                    setshowDivsConditionsZone({
                        showDivsConditionsName:"loader",
                    showDivsConditionsValue: false,
                    }))
               
                dispatch(setDataZoneActions(res?.data?.data));
                dispatch({type:"SET_RECORDS",payload:res.data.recordsFiltered})
            } else if (res?.status === 200 && res?.data?.code !== 200) {

                message.error({ content: t('Zone.messageerroroperation'), duration: 2 })
            }

        } catch (e) {
            message.error({ content: t('Zone.messageerroroperation'), duration: 2 })
        }
    }
    const AddData = async (data: any) => {
        try {
            const body = {
                "empConfigurationZoneForm": {
                    "name": data.name, "path": data.path, "ipStart": data.ipStart,
                    "ipEnd": data?.ipEnd,
                    "bandwidthLimit": data.bandwidthLimit, "zonelimit": data.zonelimit,
                    "usersimultane": data.usersimultane, "bandwidthDisponible": data.bandwidthDisponible,
                    "rmbox": data.rmboxselected
                }

            }
            const res = await PostData(`/zone/add`, body);


            if (res.status === 200 && res.data.code === 200) {
                await dispatch(
                    setshowDivsConditionsZone({
                        showDivsConditionsName: "addZone",
                        showDivsConditionsValue: false,
                    })
                );
                onResetZone()
                message.success({ content: t('Zone.successadd'), duration: 2 })


            } else if (res.status === 200 && res.data.code !== 200) {
                message.error({ content: t('Zone.messageerroroperation'), duration: 2 })


            }
        } catch (e) {
            if(data.ipStart > data.ipEnd){
                message.error({ content: t('Zone.messageErrorIp'), duration: 2 })
            }else{
            message.error({ content: t('Zone.messageerroroperation'), duration: 2 })
            }

        }

    }
    const ModifData = async (data: any, id: any) => {
        try {

            const body = {
                "id": id, "empConfigurationZoneForm": {
                    "name": data.name, "path": data.path,
                    "ipStart": data.ipStart, "ipEnd": data.ipEnd, "bandwidthLimit": data.bandwidthLimit,
                    "zonelimit": data.zonelimit, "usersimultane": data.usersimultane,
                    "bandwidthDisponible": data.bandwidthDisponible,
                    "rmbox": data?.rmboxselected
                }
            }
            const res = await PostData(`/zone/edit/${id}`, body);
            if (res?.status === 200 && res?.data?.code === 200) {
                await dispatch(
                    setshowDivsConditionsZone({
                        showDivsConditionsName: "editZone",
                        showDivsConditionsValue: false,
                    })
                );
                //getAllData() 
                onResetZone()
                message.success({ content: t('Zone.successedit'), duration: 2 })

            } else if (res?.status === 200 && res?.data?.code !== 200) {

                message.error({ content: t('Zone.messageerroroperation'), duration: 2 })
            }
        } catch (e) {
            if(data.ipStart > data.ipEnd){
                message.error({ content: t('Zone.messageerrorip'), duration: 2 })
            }else{
                 message.error({ content: t('Zone.messageerroroperation'), duration: 2 })
            }
             
        }
    }
    const Deleteselecteditem = async (id: any) => {
        try {
            const res = await DeleteData(`/zone/delete/${id}`);

            if (res?.status === 200 && res?.data?.code === 200) {
                
                getAllData(currentPage,pageSize,searchWord)
                message.success({ content: `${t('Zone.successdelete')}`, duration: 2 })
            } else {
                message.error({ content: t('Zone.messageerroroperation'), duration: 2 })

            }
        } catch (e) {
            message.error({ content: t('Zone.messageerroroperation'), duration: 2 })
        }
    }

    const Deletemultipleselecteditem = async () => {

        try {

            const ids = [selectedItems.join()];


            const res = await DeleteData(`/multiDelete/zones/${ids}`);

            if (res.status === 200 && res.data.code === 200) {
                dispatch(
                    setPaginationPropsZone({
                        PaginationPropsNameChange: "id",
                        PaginationPropsValueChange: [],
                    })
                );
                getAllData(currentPage,pageSize,searchWord)
                message.success({ content: t('Zone.successdelete'), duration: 2 })
            } else {
                message.error({ content: t('Zone.messageerroroperation'), duration: 2 })

            }
        } catch (e) {
            message.error({ content: t('Zone.messageerroroperation'), duration: 2 })
        }
    }


    const getInfoItem = async (id: any) => {
        try {
            const res = await GetData(`/zone/${id}`);
            if (res.status === 200 && res.data.code === 200) {

                await dispatch(setDetailZoneActions(res.data));
                setVisible(true)
            } else if (res.status === 200 && res.data.code !== 200) {
                message.error({ content: t('Zone.messageerroroperation'), duration: 2 })

            }
        } catch (e) {
            message.error({ content: t('Zone.messageerroroperation'), duration: 2 })
        }
    }

    const getlistrmbox = async () => {
        try {
            const res = await GetData(`/dataTable/rmboxs?columns[0][orderable]=true&draw=1&order[0][column]=0&order[0][dir]=desc&start=0`);
            if (res?.status === 200 && res?.data?.code === 200) {
                await dispatch(setListRmboxZoneActions(res?.data?.data));
            } else if (res?.status === 200 && res?.data?.code !== 200) {

                message.error({ content: t('Zone.messageerroroperation'), duration: 2 })
            }

        } catch (e) {
            message.error({ content: t('Zone.messageerroroperation'), duration: 2 })
        }
    }

    const props = {

        multiple: false,
        headers: {
            "Content-Type": "multipart/form-data;boundary=----WebKitFormBoundaryyiEoJn3ioUgAEb9N"
        },
        customRequest: async (options: any) => {
            const { onSuccess, onError, } = options;
            const data = new FormData()

            data.append('flowChunkNumber', "1")
            data.append('flowChunkSize', options.file.size)
            data.append('flowCurrentChunkSize', options.file.size)
            data.append('flowTotalSize', options.file.size)
            data.append('flowIdentifier', options.file.name)
            data.append('flowFilename', options.file.name)
            data.append('flowRelativePath', options.file.name)
            data.append('flowTotalChunks', "1")
            data.append('empApiFileForm[file]', options.file)
            //data.append('file', options.file)
            const res = await PostData(`/subscription_group/import`, data);
            if (res.status === 200) {
                onSuccess("Ok");
            } else {
                onError("failed")
            }
        },

        onChange(info: any) {
            const { status } = info.file;

            if (status !== 'uploading') {

            }
            if (status === 'done') {
                //file uploaded successfully.
                message.success(`${info.file.name} ${t('Zone.successuploadfile')} `);
            } else if (status === 'error') {
                //file upload failed.
                message.error(`${info.file.name} ${t('Zone.faileduploadfile')}`);
            }
        },
        onDrop(e: any) {

        },
    };

    const handelFilter = (type: any, tab: any) => {
        function smallval(item: any) {
            if (type === "All") {
                return item;
            } else if (type !== "") {
                return item.type === type;
            } else {
                return item;
            }
        }

        const res = tab.filter(smallval);

        return res;
    };


    return ({
        handleAddZone,
        onResetZone,
        formAddZone,
        handleSearchRow,
        onChangeFolder,
        handleShowModal,
        visible,
        handleCancel,




        onFinishAddZone,
        onFinishFailedAddZone,
        onValueFormUserchangeZone,
        handleEditZone,
        values,

        handelSearch,
        getAllData,
        Deleteselecteditem,
        Deletemultipleselecteditem,
        props,
        disabledsubmitbutton,
        getlistrmbox,
        handelFilter


    })
}
export default Hooks