import {AuthorizedIPList} from "./AuthorizedIP"
const {AuthorizedIPTypes}=AuthorizedIPList()
export function setPaginationPropsAuthorizedIP(e:any){
    return{
        type:"SET_PaginationPropsAuthorizedIP",
        payload:e,
    }
}

export function setshowDivsConditionsAuthorizedIP(e:any){
    return {
        type : "SET_showDivsConditionsAuthorizedIP",
        payload:e
    }
}

export function setAddAuthorizedIPActions(e:any){
    return{
        type:"SET_AddAuthorizedIPActions",
        payload:e,
    }
}

export function setFilterAuthorizedIPActions(e:any){

    return{
        type:"SET_FilterAuthorizedIP",
        payload:e,
    }

}

export function setEditAuthorizedIPActions(e:any){
    return{
        type:"SET_EditAuthorizedIPActions",
        payload:e,
    }
}


export function setAuthorizedIPDataActions(e:any){
    return{
        type:AuthorizedIPTypes.SET_AuthorizedIP_DATA,
        payload:e,
    }
}
export function setRecord(e:any){
    return{
        type:"SET_RECORDS",
        payload:e
    }
}
export function setOpenDelete(e:any){
    return{
        type:"SET_OPENDEL",
        payload:e
    }
}
export function setSelected(e:any){
    const listSelected = e.map((e:any)=>e);
    return {
        type:"SET_IDSELECTED",
        payload:listSelected,
    }
  }