import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    setAddRmboxActions, setFilterRmboxActions, setshowDivsConditionsRmbox,
    setDataRmboxActions, setDetailRmboxActions, setPaginationPropsRmbox,
    setOpenDeleteSpace,
    setReportsRmboxActions, setListGroupRmboxActions,
    deleteStatus
} from "../store/rmboxActions"
import { Form, message } from "antd";
import { useAppSelector } from "../../../Redux/hooks";
import {
    GetData,
    PostData,

    DeleteData,
} from "../../../APIcalls";
import { useTranslation } from "react-i18next";

const Hooks = () => {
    const dispatch = useDispatch()
    const [formAddRmbox] = Form.useForm()
    const { t } = useTranslation();
    const [visible, setVisible] = useState<any>(false)
    const [visibleExport] = useState<any>(false)

    const [visibleHistory, setVisibleHistory] = useState<any>(false)
    const [visibleGrille, setVisibleGrille] = useState<any>(false)
    const [visibleActivatePlaylist, setVisibleActivatePlaylist] = useState<any>(false)

    // use Selector redux
    const values = useAppSelector((state) => state.RmboxReducer.addRmboxConstraintsData)
    const listitemselected = useAppSelector((state) => state.RmboxReducer.paginationPropsRmbox.id)
    const Rmboxcondition = useAppSelector((state) => state.RmboxReducer.showDivsConditionsRmbox)
    const [pageSize]=useState(10)
    const [currentPage]=useState(1);
    const checkedCases = useAppSelector((state)=>state.RmboxReducer.selectedCase)
    useEffect(() => {
        formAddRmbox.setFieldsValue({
            id: values.id,
            name: values.name,
            type: values.type,
            ip: values.ip,
            mac: values.mac,
            postal: values.postal,
            created_at: values.created_at,
            updated_at: values.updated_at,
            group: values.group,
            schedule: values.schedule,
            statut: values.statut,
            espace_rest: values.espace_rest,
            date_debut_synch: values.date_debut_synch,
            duration_synch: values.duration_synch,
            statut_updated: values.statut_updated,
            count_videos: values.count_videos,
            count_zones: values.count_zones,
            count_playlists: values.count_playlists,
            programs: values.programs
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    ////*************** show Modal Function ******************/////////
    const handleShowModal = (id: any) => {
        getInfoItem(id)

    }
    const handleCancel = () => {
        setVisible(false)
    }
    ///////*************show Modal Historiques ****************////////////
    const handleShowModalHistory = (id: any) => {
        getHistoryReports(id)

    }
    const handleCancelHistory = () => {
        setVisibleHistory(false)
    }

    ///////*************show Modal Grille ****************////////////
    const handleShowModalGrille = (id: any) => {
       // getHistoryReports(id)
       setVisibleGrille(true)
    }
    const handleCancelGrille = () => {
        setVisibleGrille(false)
    }


     ///////*************show Modal ActivatePlaylist ****************////////////
     const handleShowModalActivatePlaylist = (id: any) => {
        // getHistoryReports(id)
        getlistplaylist(id)
       // setVisibleActivatePlaylist(true)
     }
     const handleCancelActivatePlaylist = () => {
         setVisibleActivatePlaylist(false)
     }

/************ display intervention */
const displayinterventions=async (id: any)=>{
    await dispatch(setshowDivsConditionsRmbox({
        showDivsConditionsName: "elementSelected",
        showDivsConditionsValue: id
    }));
   await dispatch(
        setshowDivsConditionsRmbox({
            showDivsConditionsName: "interventionRmbox",
            showDivsConditionsValue: true,
        })
    );
}
const removedintervention = async()=>{
    await dispatch(
        setshowDivsConditionsRmbox({
            showDivsConditionsName: "interventionRmbox",
            showDivsConditionsValue: false,
        })
    );
}

    /////********* show add edit Rmbox ****************/////
    const handleAddRmbox = () => {
        getgroupRmox()
        formAddRmbox.setFieldsValue({
            id: "",
            name: "",
            type: "",
            ip: "",
            mac: "",
            postal: "",
            created_at: "",
            updated_at: "",
            group: "",
            schedule: "",
            statut: "",
            espace_rest: "",
            date_debut_synch: "",
            duration_synch: "",
            statut_updated: "",
            count_videos: "",
            count_zones: "",
            count_playlists: "",
            programs: []

        })

        dispatch(
            setshowDivsConditionsRmbox({
                showDivsConditionsName: "addRmbox",
                showDivsConditionsValue: true,
            })
        );

        dispatch(
            setshowDivsConditionsRmbox({
                showDivsConditionsName: "disablesubmitform",
                showDivsConditionsValue: true,
            })
        );


    }



    const onResetRmbox = async () => {
        /**edit item list */
        dispatch(
            setshowDivsConditionsRmbox({
                showDivsConditionsName: "editRmbox",
                showDivsConditionsValue: false,
            })
        );
        dispatch(
            setshowDivsConditionsRmbox({
                showDivsConditionsName: "addRmbox",
                showDivsConditionsValue: false,
            })
        );
        dispatch(
            setshowDivsConditionsRmbox({
                showDivsConditionsName: "disablesubmitform",
                showDivsConditionsValue: true,
            })
        );

        await dispatch(setAddRmboxActions({
            addRmboxConstraintsDataNameChange: "key",
            addRmboxConstraintsDataValueChange: ""
        }));
        await dispatch(setAddRmboxActions({
            addRmboxConstraintsDataNameChange: "value",
            addRmboxConstraintsDataValueChange: ""
        }));
        await dispatch(setAddRmboxActions({
            addRmboxConstraintsDataNameChange: "identifiant",
            addRmboxConstraintsDataValueChange: ""
        }));
        await dispatch(setAddRmboxActions({
            addRmboxConstraintsDataNameChange: "createddate",
            addRmboxConstraintsDataValueChange: ""
        }));
        await dispatch(setAddRmboxActions({
            addRmboxConstraintsDataNameChange: "publishdate",
            addRmboxConstraintsDataValueChange: ""
        }));

        dispatch(setOpenDeleteSpace(false))



    }

    ////****************** function Filter *****************///////
    //*********************** search header **************///////
    const handleSearchRow = async (event: any) => {
             dispatch(setFilterRmboxActions({
                FilterRmboxNameChange: "search",
                FilterRmboxValueChange: event
            }));
       
    }
    const onChangeFolder = async (e: any) => {
       
        await dispatch(setFilterRmboxActions({
            FilterRmboxNameChange: "type",
            FilterRmboxValueChange: e
        }));
    }

    /////**************** Form Add Rmbox *******************/////////
    const onFinishAddRmbox = (values: any) => {
       
        if (Rmboxcondition.addRmbox === true) {
            AddData(values)
        } else if (Rmboxcondition.editRmbox === true) {
            ModifData(values, Rmboxcondition.elementSelected)
        }

    }
    const onFinishFailedAddRmbox = (errorInfo: any) => {
        
    }
    const onValueFormUserchangeRmbox = async (val: any) => {
     
  

    }

    const disabledsubmitbutton= async(type:any) =>{
      
        
        
        if(type === "disabled"){
            await dispatch(setshowDivsConditionsRmbox({
                showDivsConditionsName: "disablesubmitform",
                showDivsConditionsValue: false
            }));
        }else if(type === "notdisabled"){
            await dispatch(setshowDivsConditionsRmbox({
                showDivsConditionsName: "disablesubmitform",
                showDivsConditionsValue: true
            }));
        }
        
    }
    ////////****** Edit Rmbox ***************////
    const handleEditRmbox = async (record: any) => {
     
        getgroupRmox()
        try {
            const res = await GetData(`/rmbox/${record.id}`);
            if (res.status === 200) {
          

                await dispatch(setshowDivsConditionsRmbox({
                    showDivsConditionsName: "elementSelected",
                    showDivsConditionsValue: res.data.id
                }));

                await dispatch(setAddRmboxActions({
                    addRmboxConstraintsDataNameChange: "id",
                    addRmboxConstraintsDataValueChange: res.data.id
                }));

                await dispatch(setAddRmboxActions({
                    addRmboxConstraintsDataNameChange: "name",
                    addRmboxConstraintsDataValueChange: res.data.name
                }));
                await dispatch(setAddRmboxActions({
                    addRmboxConstraintsDataNameChange: "type",
                    addRmboxConstraintsDataValueChange: res.data.type
                }));
                await dispatch(setAddRmboxActions({
                    addRmboxConstraintsDataNameChange: "ip",
                    addRmboxConstraintsDataValueChange: res.data.ip
                }));
                await dispatch(setAddRmboxActions({
                    addRmboxConstraintsDataNameChange: "mac",
                    addRmboxConstraintsDataValueChange: res.data.mac
                }));
                await dispatch(setAddRmboxActions({
                    addRmboxConstraintsDataNameChange: "postal",
                    addRmboxConstraintsDataValueChange: res.data.postal
                }));
                await dispatch(setAddRmboxActions({
                    addRmboxConstraintsDataNameChange: "group",
                    addRmboxConstraintsDataValueChange: res.data.group.id
                }));

                await dispatch(
                    setshowDivsConditionsRmbox({
                        showDivsConditionsName: "editRmbox",
                        showDivsConditionsValue: true,
                    })
                );
                await formAddRmbox.setFieldsValue({
                    id: res.data.id,
                    name: res.data.name,
                    type: res.data.type,
                    ip: res.data.ip,
                    postal: res.data.postal,
                    group: res.data.group,
                })

            }

        } catch (e) {
            message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })

        }
    }
    /********************************** */
    const handelSearch = (word: string, tab: any) => {
        if (word.trim() === "") return tab;

        // eslint-disable-next-line array-callback-return
        const res = tab.filter((obj: any) => {
            if (obj?.name?.toLowerCase().includes(word.toLowerCase()) || 
            obj?.ip?.toLowerCase().includes(word.toLowerCase()) || 
            obj?.mac?.toLowerCase().includes(word.toLowerCase()) || 
            obj?.group?.name.toLowerCase().includes(word.toLowerCase()
             ) 
            ) return obj;
        });

        return res;
    };
    /*******************Crud *********************** */
    const getAllData = async (currentPage:any,pageSize:any,searchWord:any) => {
        dispatch(deleteStatus(false))
        try {
            const offset = (currentPage - 1) * pageSize;
            const res = await GetData(`/dataTable/rmboxs?columns[0][orderable]=true&draw=${currentPage}&length=${pageSize}&order[0][column]=0&order[0][dir]=asc&start=${offset}&search[value]=${searchWord}`);
            if (res.status === 200 && res.data.code === 200) {
                dispatch(
                    setshowDivsConditionsRmbox({
                        showDivsConditionsName: "loader",
                        showDivsConditionsValue: false,
                    })
                )
                dispatch(setDataRmboxActions(res.data.data));
                dispatch({type:"SET_RECORDS",payload:res.data.recordsFiltered})
            } else if (res.status === 200 && res.data.code !== 200) {
                message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })
            }

        } catch (e) {
            message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })
        }
    }
    const AddData = async (data: any) => {
        try {
            const body = {
                "empConfigurationRmboxForm": data

            }
            const res = await PostData(`/rmbox/add`, body);
          
            if (res.status === 200 && res.data.code === 200) {
                await dispatch(
                    setshowDivsConditionsRmbox({
                        showDivsConditionsName: "addRmbox",
                        showDivsConditionsValue: false,
                    })
                );
                message.success({ content: t('Rmbox.successadd'), duration: 2 })


            } else if (res.status === 200 && res.data.code !== 200) {
                message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })
            }
        } catch (e) {
           
            message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })
        }
    }
    const ModifData = async (data: any, id: any) => {
        try {
         
            const body = {
                "id": id, "empConfigurationRmboxForm": data
            }
            const res = await PostData(`/rmbox/edit/${id}`, body);
           
            if (res.status === 200 && res.data.code === 200) {
                await dispatch(
                    setshowDivsConditionsRmbox({
                        showDivsConditionsName: "editRmbox",
                        showDivsConditionsValue: false,
                    })
                );
                message.success({ content: t('Rmbox.successedit'), duration: 2 })

                //getAllData() 
            } else if (res.status === 200 && res.data.code !== 200) {
                message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })
            }
        } catch (e) {
            message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })
        }
    }
    const Deleteselecteditem = async (id: any) => {
        dispatch(
            setshowDivsConditionsRmbox({
                showDivsConditionsName: "loader",
                showDivsConditionsValue: true,
            })
        )
        try {
            const res = await DeleteData(`/multiDelete/rmboxs/${id}`);
           
            if (res.status === 200 && res.data.code === 200) {
                    dispatch(deleteStatus(true))
                  getAllData(currentPage,pageSize,"")
                message.success({ content: t('Rmbox.successdelete'), duration: 2 })

            } else if (res.status === 200 && res.data.code !== 200) {
                message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })
            }
        } catch (e) {
            message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })
        }
    }

    const Deletemultipleselecteditem = async () => {
        dispatch(
            setshowDivsConditionsRmbox({
                showDivsConditionsName: "loader",
                showDivsConditionsValue: true,
            })
        )
        try {
          
            const listid = checkedCases.join()
         

            const res = await DeleteData(`/multiDelete/rmboxs/${listid}`);
            
            if (res.status === 200 && res.data.code === 200) {

                dispatch(
                    setPaginationPropsRmbox({
                        PaginationPropsNameChange: "id",
                        PaginationPropsValueChange: [],
                    })
                );
                dispatch(deleteStatus(true))
                getAllData(currentPage,pageSize,"")
                message.success({ content: t('Rmbox.successdelete'), duration: 2 })

            } else if (res.status === 200 && res.data.code !== 200) {
                message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })
            }
        } catch (e) {
            message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })
        }
    }


    const getInfoItem = async (id: any) => {
        try {
            const res = await GetData(`/rmbox/${id}`);
            if (res.status === 200 && res.data.code === 200) {
            
                await dispatch(setDetailRmboxActions(res.data));
                setVisible(true)
            } else if (res.status === 200 && res.data.code !== 200) {
                message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })

            }
        } catch (e) {
            message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })
        }
    }

    const getHistoryReports = async (id: any) => {
        try {
            const res = await GetData(`/rmbox/reports/${id}`);
            if (res.status === 200 && res.data.code === 200) {
             
                await dispatch(setReportsRmboxActions(res.data));

                setVisibleHistory(true)
            } else if (res.status === 200 && res.data.code !== 200) {
                message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })
            }

        } catch (e) {
            message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })
        }
    }

    // const getListofprograms = async (id: any) => {
    //     try {
    //         const res = await GetData(`/programDisplay/list-programs`);
    //         if (res.status === 200 && res.data.code === 200) {
    //             await dispatch(setReportsRmboxActions(res.data));

    //            // setVisibleGrille(true)
    //         } else if (res.status === 200 && res.data.code !== 200) {
    //             message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })
    //         }

    //     } catch (e) {
    //         message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })
    //     }
    // }
    const refreshRmbox=async()=>{
        dispatch({type:"SET_STATUS_DELETE",payload:false})
        const offset = (currentPage - 1) * pageSize;
        try{
            const res = await GetData(`/dataTable/rmboxs?columns[0][orderable]=true&draw=${currentPage}&length=${pageSize}&order[0][column]=0&order[0][dir]=asc&start=${offset}`);
       
        if (res.status === 200 && res.data.code === 200) {
            dispatch(
                setshowDivsConditionsRmbox({
                    showDivsConditionsName: "loader",
                    showDivsConditionsValue: false,
                })
            )
            dispatch(deleteStatus(false))
            dispatch(setDataRmboxActions(res.data.data));
            dispatch({type:"SET_RECORDS",payload:res.data.recordsFiltered})
        } else if (res.status === 200 && res.data.code !== 200) {
            message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })
        }

    } catch (e) {
        message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })
    }
}
    
    const refreshboitier = async (id: any) => {
        try {
            const res = await GetData(`/rmbox/reload_rmbox/${id}`);
            if (res.status === 200 && res.data.code === 200) {
               // await dispatch(setSuccessRmboxActions(res.data.message));
                message.success({ content: t('Rmbox.messagerefreshrmbox'), duration: 2 })
                //setVisible(true)
            } else if (res.status === 200 && res.data.code !== 200) {
                message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })
            }

        } catch (e) {
            message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })
        }
    }
    const getgroupRmox = async () => {
        try {
            const res = await GetData(`/rmboxgroup`);
            if (res.status === 200) {
           
                await dispatch(setListGroupRmboxActions(res.data.data.rmboxgroups));

            } else if (res.status === 200 && res.data.code !== 200) {
                message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })

            }
        } catch (e) {
            message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })
        }
    }

    
    const handleAssociateprogram = async () => {
        try {
        
            const body = {
                "idRmbox":5,"ids":[6]
            }
            const res = await PostData(`/rmbox/associate-program-to-rmbox`, body);
          
            if (res.status === 200) {
                setVisibleGrille(false)
            } else if (res.status === 200 && res.data.code !== 200) {
                message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })
            }
        } catch (e) {

            message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })
        }
    }
    const getlistplaylist = async (id:any) => {
        try {
            const res = await GetData(`/playlist/list-playlist-rmbox/?id=${id}`);
            if (res.status === 200) {
              setVisibleActivatePlaylist(true)
            } else if (res.status === 200 && res.data.code !== 200) {
                message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })

            }
        } catch (e) {
            message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })
        }
    }
    const handleActivatePlaylist = async () => {
        try {
        
            const body = {"idRmbox":3,"idPlaylist":25}
            const res = await PostData(`/rmbox/activate-playlist-to-rmbox`, body);
           
            if (res.status === 200) {
                setVisibleActivatePlaylist(false)
                
            } else if (res.status === 200 && res.data.code !== 200) {
                message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })
            }
        } catch (e) {
            message.error({ content: t('Rmbox.messageerroroperation'), duration: 2 })
        }
    }
    return ({
        handleAddRmbox,
        onResetRmbox,
        formAddRmbox,
        handleSearchRow,
        onChangeFolder,
        handleShowModal,
        visible,
        handleCancel,

        handleShowModalHistory,
        visibleHistory,
        handleCancelHistory,

        handleShowModalGrille,
        handleCancelGrille,
        visibleGrille,


        handleShowModalActivatePlaylist,
        handleCancelActivatePlaylist,
        visibleActivatePlaylist,

        visibleExport,


        onFinishAddRmbox,
        onFinishFailedAddRmbox,
        onValueFormUserchangeRmbox,
        handleEditRmbox,
        values,

        handelSearch,
        getAllData,
        Deleteselecteditem,
        Deletemultipleselecteditem,
        refreshboitier,
        handleAssociateprogram,
        handleActivatePlaylist,
        refreshRmbox,
        displayinterventions,
        removedintervention,
        disabledsubmitbutton

    })
}
export default Hooks
