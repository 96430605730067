

const LoadingScreen=()=>{
    const style = {
        height: '80vh',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems:'center',
        width:'100%'
      };
    return (
        <div className="loadingConatiner" style={style}>
            <img src="/lp-loader.png" alt="LoadingScreen"/>
        </div>
        
    )

}
export default LoadingScreen