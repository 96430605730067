
export function setPaginationPropsIntervention(e:any){
    return{
        type:"SET_PaginationPropsIntervention",
        payload:e,
    }
}

export function setshowDivsConditionsIntervention(e:any){
    return {
        type : "SET_showDivsConditionsIntervention",
        payload:e
    }
}

export function setAddInterventionActions(e:any){
    return{
        type:"SET_AddInterventionActions",
        payload:e,
    }
}

export function setFilterInterventionActions(e:any){

    return{
        type:"SET_FilterIntervention",
        payload:e,
    }

}

export function setEditInterventionActions(e:any){
    return{
        type:"SET_EditInterventionActions",
        payload:e,
    }
}

export function setDataInterventionActions(e:any){
    return{
        type:"SET_DataInterventionActions",
        payload:e,
    }
}

export function setDetailInterventionActions(e:any){
    return{
        type:"SET_DetailInterventionActions",
        payload:e,
    }
}



export function setRmBoxSelectedInterventionActions(e:any){
    return{
        type:"SET_RmBoxSelectedInterventionActions",
        payload:e,
    }
}

export function setErrorrMessageInterventionActions(e:any){

    return{
        type:"SET_ErrorrMessage",
        payload:e,
    }

}
