
const NotFound = () => {
    const style = {
      height: '80vh',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    };
  
    return (
      <div  style={style}>
        <img src="/notfound.png" alt="NotFound" />
      </div>
    );
  };
  
  export default NotFound;
  