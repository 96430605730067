import { RmboxList } from "./rmboxConstants";
const { showDivsConditionsRmbox, AddRmboxConstraintsData, paginationPropsRmbox, FilterRmbox, DataRmbox, DetailRmbox } = RmboxList()

const RmboxListINITIALSTATE = {
    showDivsConditionsRmbox: showDivsConditionsRmbox(),
    paginationPropsRmbox: paginationPropsRmbox(),
    addRmboxConstraintsData: AddRmboxConstraintsData(),
    filterRmbox: FilterRmbox(),
    dataRmbox: DataRmbox(),
    detailRmbox: DetailRmbox(),
    errormessage: "",
    successmessage: "",
    historyreports: [],
    listgrouprmbox: [],
    totalRecord:0,
    showDeleteSpace:false,
    selectedCase:[],
    showDelModal:false,
    statusDel:false,

}

export const RmboxReducer = (state = RmboxListINITIALSTATE, action: any) => {

    switch (action.type) {

        case "SET_FilterRmbox":
            const { FilterRmboxNameChange, FilterRmboxValueChange } = action.payload
            return {
                ...state,
                filterRmbox: { ...state.filterRmbox, [FilterRmboxNameChange]: FilterRmboxValueChange }
            }
        case "SET_PaginationPropsRmbox":
            const { PaginationPropsNameChange, PaginationPropsValueChange } = action.payload
            return {
                ...state,
                paginationPropsRmbox: { ...state.paginationPropsRmbox, [PaginationPropsNameChange]: PaginationPropsValueChange }
            }
        case "SET_showDivsConditionsRmbox":
            const { showDivsConditionsName, showDivsConditionsValue } = action.payload
            const showDivsConditionsObj = { ...state.showDivsConditionsRmbox, [showDivsConditionsName]: showDivsConditionsValue }
            return {
                ...state,
                showDivsConditionsRmbox: showDivsConditionsObj
            }
        case "SET_AddRmboxActions":
            const { addRmboxConstraintsDataNameChange, addRmboxConstraintsDataValueChange } = action.payload
            return {
                ...state,
                addRmboxConstraintsData: { ...state.addRmboxConstraintsData, [addRmboxConstraintsDataNameChange]: addRmboxConstraintsDataValueChange }
            }

        case "SET_DataRmboxActions":

            return {
                ...state,
                dataRmbox: action.payload
            }
        case "SET_DetailRmboxActions":

            return {
                ...state,
                detailRmbox: action.payload
            }
        case "SET_ReportsRmboxActions":

            return {
                ...state,
                historyreports: action.payload
            }

        case "SET_ListGroupRmboxActions":

            return {
                ...state,
                listgrouprmbox: action.payload
            }

        case "SET_SuccessRmboxActions":

            return {
                ...state,
                successmessage: action.payload
            }
        case "SET_ErrorrMessageRmboxActions":

            return {
                ...state,
                errormessage: action.payload
            }
        case "SET_RECORDS":
            return{
                ...state,
                totalRecord:action.payload
            }    
        case "SET_DELETESPACE":
            return{
                ...state,
                showDeleteSpace:action.payload
            } 
            case "SET_IDSELECTED":
                return{
                    ...state,
                    selectedCase:action.payload
                }   
            case "SET_MODALDEL":
        return{
            ...state,
            showDelModal:action.payload
        }   
        case "SET_STATUS_DELETE":
            return{
                ...state,
                statusDel:action.payload
            } 
        default: {
            return state
        }

    }

}

