import { useEffect, useState } from "react";
import { Form, Input, Row, Col, DatePicker } from "antd";
import '../../intervention.scss'
import { useTranslation } from "react-i18next";
import Hooks from "../../utils/hooks";
import moment from 'moment';
import { useAppSelector } from "../../../../Redux/hooks";
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
const { TextArea } = Input;



const AddEditIntervention = () => {

    const { t } = useTranslation();
    const { values , disabledsubmitbutton} = Hooks();
    const Rmboxcondition = useAppSelector((state) => state.InterventionReducer.showDivsConditionsIntervention)
    const onChangeremarque = (e: any) => {
        if (e.target.value !== "") {
            datafiledsrequired.remarque = true
            setDatafiledsrequired(datafiledsrequired)
        } else {
            datafiledsrequired.remarque = false
            setDatafiledsrequired(datafiledsrequired)
        }
        if (Rmboxcondition.addIntervention) {
            testfields()
        } else if (Rmboxcondition.editIntervention) {
            testfieldsmodif()
        }

    }
    function onChangereceptionDate(date:any, dateString:any) {
      
        if (dateString !== "") {
            datafiledsrequired.receptionDate = true
            setDatafiledsrequired(datafiledsrequired)
        } else {
            datafiledsrequired.receptionDate = false
            setDatafiledsrequired(datafiledsrequired)
        }
        if (Rmboxcondition.addIntervention) {
            testfields()
        } else if (Rmboxcondition.editIntervention) {
            testfieldsmodif()
        }

      }
      function onChangesendDate(date:any, dateString:any) {
     
        if (dateString !== "") {
            datafiledsrequired.sendDate = true
            setDatafiledsrequired(datafiledsrequired)
        } else {
            datafiledsrequired.sendDate = false
            setDatafiledsrequired(datafiledsrequired)
        }
        if (Rmboxcondition.addIntervention) {
            testfields()
        } else if (Rmboxcondition.editIntervention) {
            testfieldsmodif()
        }
      }
      function onChangefirstStartedDate(date:any, dateString:any) {
      
        if (dateString !== "") {
            datafiledsrequired.firstStartedDate = true
            setDatafiledsrequired(datafiledsrequired)
        } else {
            datafiledsrequired.firstStartedDate = false
            setDatafiledsrequired(datafiledsrequired)
        }
        if (Rmboxcondition.addIntervention) {
            testfields()
        } else if (Rmboxcondition.editIntervention) {
            testfieldsmodif()
        }
      }
      const onChangenameoldMac = (e: any) => {
        if (e.target.value !== "") {
            datafiledsrequired.oldMac = true
            setDatafiledsrequired(datafiledsrequired)
        } else {
            datafiledsrequired.oldMac = false
            setDatafiledsrequired(datafiledsrequired)
        }
        if (Rmboxcondition.addIntervention) {
            testfields()
        } else if (Rmboxcondition.editIntervention) {
            testfieldsmodif()
        }

    }
    const onChangenamenewMac = (e: any) => {
        if (e.target.value !== "") {
            datafiledsrequired.newMac = true
            setDatafiledsrequired(datafiledsrequired)
        } else {
            datafiledsrequired.newMac = false
            setDatafiledsrequired(datafiledsrequired)
        }
        if (Rmboxcondition.addIntervention) {
            testfields()
        } else if (Rmboxcondition.editIntervention) {
            testfieldsmodif()
        }

    }

      const [datafiledsrequired, setDatafiledsrequired] = useState<any>({
        remarque: false,
        receptionDate: false,
        sendDate: false,
        firstStartedDate: false,
        oldMac: false,
        newMac: false,
      
    })
    const testfields = () => {
        let completed = false
        // eslint-disable-next-line array-callback-return
        Object.values(datafiledsrequired).map((item: any) => {
            if (item === false) {
                completed = true
            }
        })
        if (completed === false) {
            disabledsubmitbutton("disabled")
        } else {
            disabledsubmitbutton("notdisabled")
        }
    }

    const testfieldsmodif = () => {
        let completed = false
        // eslint-disable-next-line array-callback-return
        Object.values(datafiledsrequired).map((item: any) => {
            if (item === true) {
                completed = true
            }
        })
        
        if (completed === false) {
            disabledsubmitbutton("notdisabled")

        } else {
            disabledsubmitbutton("disabled")
        }
    }
    useEffect(() => {
        testfields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Rmboxcondition.addIntervention, Rmboxcondition.editIntervention])


    return (
        <div className={"formItem1"}>
            <Row>
                <div className={"InputLablDiv"}>
                    <Form.Item
                        label={<>{t('Intervention.remarque')}<div className="required">*</div></>}
                        name="remarque"
                        className={"formItem3"}
                        rules={[{ required: true, message: t('Intervention.msgerrorremarque') }]}
                    >
                        <TextArea rows={3} value={values.remarque} name={"remarque"} id={"remarque"} 
                        placeholder={t('Intervention.remarqueplaceholder')} onChange={onChangeremarque} />
                    </Form.Item>
                </div>
            </Row>
            <Row gutter={16}>
                <Col span={8}>

                    <Form.Item
                        label={<>{t('Intervention.receptionDate')}<div className="required">*</div></>}
                        
                        name="receptionDate"
                        className={"formItem3"}
                        rules={[{ required: true, message: t('Intervention.msgerrorreceptionDate') }]}
                    >
                         <DatePicker onChange={onChangereceptionDate} defaultValue={moment('01/01/2015', dateFormatList[0])} format={dateFormatList} />

                    </Form.Item>


                </Col>
                <Col span={8}>

                    <Form.Item
                        label={<>{t('Intervention.sendDate')}<div className="required">*</div></>}
                        name="sendDate"
                        className={"formItem3"}
                        rules={[{ required: true, message: t('Intervention.msgerrorsendDate') }]}
                    >
                        <DatePicker onChange={onChangesendDate} defaultValue={moment('01/01/2015', dateFormatList[0])} format={dateFormatList} />
                        
                    </Form.Item>


                </Col>
                <Col span={8}>

                    <Form.Item
                        label={<>{t('Intervention.firstStartedDate')}<div className="required">*</div></>}
                        name="firstStartedDate"
                        className={"formItem3"}
                        rules={[{ required: true, message: t('Intervention.msgerrorfirstStartedDate') }]}
                    >
                        <DatePicker onChange={onChangefirstStartedDate} defaultValue={moment('01/01/2015', dateFormatList[0])} format={dateFormatList} />
                        
                    </Form.Item>


                </Col>
            </Row>

            <Row>
                <Form.Item
                    label={<>{t('Intervention.oldMac')}<div className="required">*</div></>}
                    name="oldMac"
                    className={"formItem3"}
                    rules={[{ required: true, message: t('Intervention.msgerroroldMac') },
                    {
                        validator: (_, value) =>
                        /([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/gi.test(value) ? Promise.resolve() : Promise.reject(new Error(t('Intervention.msgerroroldMac'))),
                      }]}
                >
                    <Input value={values.oldMac} name={"oldMac"} id={"oldMac"}
                     placeholder={t('Intervention.oldMacplaceholder')} onChange={onChangenameoldMac} />
                </Form.Item>
            </Row>
            <Row>
                <Form.Item
                    label={<>{t('Intervention.newMac')}<div className="required">*</div></>}
                    name="newMac"
                    className={"formItem3"}
                    rules={[{ required: true, message: t('Intervention.msgerrornewMac') },
                    {
                        validator: (_, value) =>
                        /([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/gi.test(value) ? Promise.resolve() : Promise.reject(new Error(t('Intervention.msgerrornewMac'))),
                      }]}
                >
                    <Input value={values.newMac} name={"newMac"} id={"newMac"} 
                    placeholder={t('Intervention.newMacplaceholder')}
                    onChange={onChangenamenewMac} />
                </Form.Item>
            </Row>

        </div>
    )
}
export default AddEditIntervention