
import { useTranslation } from "react-i18next";

import "../rmbox.scss"
export const DataRmbox = () => {

    const { t } = useTranslation();

    const columns = [
        {
            title: <label className="titlecolumn">{t('Rmbox.name')}</label>,
            dataIndex: 'name',
            key:0,
            flex:1,
            field:t('Rmbox.name'),
            sorter: (a: any, b: any) => {
                let fa = a.name.toLowerCase();
                let fb = b.name.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
            },
            renderCell:(record:any)=>{
                return(
                    <span>{record?.row?.name}</span>
                )
            },
            sortDirections: ['descend', 'ascend'],
            className:"contentcolumn",
            align: "left",
            
        },

        {
            title: <label className="titlecolumn">{t('Rmbox.adress')}</label>,
            dataIndex: 'ip',
            key:1,
            flex:1,
            field:t('Rmbox.adress'),
            sorter: (a: any, b: any) => {
                let fa = a.ip.toLowerCase();
                let fb = b.ip.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
            },
            sortDirections: ['descend', 'ascend'],
            className:"contentcolumn",
            renderCell: (text: any, record:any) =>{
                return(
                      <div className="adressClass">
                        <label>IP: {text?.row?.ip}</label>
                        <label>Mac: {text?.row?.mac}</label>
                    </div>
                )
            },
         align: "left", 
           
        },
        {
            title: <label className="titlecolumn">{t('Rmbox.grouprmbox')}</label>,
            dataIndex: 'group',
            key:2,
            flex:1,
            field:t('Rmbox.grouprmbox'),
            sorter: (a: any, b: any) => {
                let fa = a.group.name.toLowerCase();
                let fb = b.group.name.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
            },
            sortDirections: ['descend', 'ascend'],
            className:"contentcolumn",
            renderCell: (text: any, record:any) => 
            <div>
                <label>{text?.row?.group?.name}</label>
                
            </div>,
            align: "left",
           
        },
        {
            title: <label className="titlecolumn">{t('Rmbox.programschedule')}</label>,
            dataIndex: 'programs',
            key:3,
            flex:1,
            field:t('Rmbox.programschedule'),
            sorter: (a: any, b: any) => {
                let fa = a.ip.toLowerCase();
                let fb = b.ip.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
            },
            sortDirections: ['descend', 'ascend'],
            className:"contentcolumn",
            renderCell: (text: any) => 
            <div>
              {text?.row.programs.map((item: any, key:any)=>{
                    return (<label>{item?.title}</label>)
                })}
            </div>
            ,
            align: "left",
        },
    ];

const listtype=['Proxy cache', 'Display']

    return ({
        columns,
        listtype,

    })

}
