import { GroupeRmBoxList } from "./GroupeRmBox";
const {
  showDivsConditionsGroupeRmBox,
  AddGroupeRmBoxConstraintsData,
  paginationPropsGroupeRmBox,
  FilterGroupeRmBox,
  GroupeRmBoxTypes
} = GroupeRmBoxList();

const GroupeRmBoxListINITIALSTATE = {
  showDivsConditionsGroupeRmBox: showDivsConditionsGroupeRmBox(),
  paginationPropsGroupeRmBox: paginationPropsGroupeRmBox(),
  addGroupeRmBoxConstraintsData: AddGroupeRmBoxConstraintsData(),
  filterGroupeRmBox: FilterGroupeRmBox(),
  List: [],
  programs: [],
  loading: false,
  msg: "",
  error: false,
  erros:[],
  GroupeRmBox:{},
  totalRecord:0,
  modalOpen:false,
  openSpaceDel:false,
  selectedCase:[],
};

export const GroupeRmBoxReducer = (state = GroupeRmBoxListINITIALSTATE, action: any) => {
  switch (action.type) {
    case "SET_FilterGroupeRmBox":
      const { FilterGroupeRmBoxNameChange, FilterGroupeRmBoxValueChange } =
        action.payload;
      return {
        ...state,
        filterGroupeRmBox: {
          ...state.filterGroupeRmBox,
          [FilterGroupeRmBoxNameChange]: FilterGroupeRmBoxValueChange,
        },
      };
    case "SET_PaginationPropsGroupeRmBox":
      const { PaginationPropsNameChange, PaginationPropsValueChange } =
        action.payload;
      return {
        ...state,
        paginationPropsGroupeRmBox: {
          ...state.paginationPropsGroupeRmBox,
          [PaginationPropsNameChange]: PaginationPropsValueChange,
        },
      };
    case "SET_showDivsConditionsGroupeRmBox":
      const { showDivsConditionsName, showDivsConditionsValue } =
        action.payload;
      const showDivsConditionsObj = {
        ...state.showDivsConditionsGroupeRmBox,
        [showDivsConditionsName]: showDivsConditionsValue,
      };
      return {
        ...state,
        showDivsConditionsGroupeRmBox: showDivsConditionsObj,
      };
    case "SET_AddGroupeRmBoxActions":
      const {
        addGroupeRmBoxConstraintsDataNameChange,
        addGroupeRmBoxConstraintsDataValueChange,
      } = action.payload;
      return {
        ...state,
        addGroupeRmBoxConstraintsData: {
          ...state.addGroupeRmBoxConstraintsData,
          [addGroupeRmBoxConstraintsDataNameChange]:
            addGroupeRmBoxConstraintsDataValueChange,
        },
      };
    case GroupeRmBoxTypes.LOADING:
      return {
        ...state,
        loading: true,
      };
    case GroupeRmBoxTypes.SET_GroupeRmBox_DATA:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
      case "SET_RECORD":
        return{
          ...state,
          totalRecord:action.payload
        }

      case "SET_OPEN_MODAL":
        return{
          ...state,
          modalOpen:action.payload
        }  
      case "SET_OPENSPACE":
        return{
          ...state,
          openSpaceDel:action.payload
        }   
      case "SET_IDSELECTED":
        return{
            ...state,
            selectedCase:action.payload
        }        
    default: {
      return state;
    }
  }
};
