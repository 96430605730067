import React,{useState} from "react";
import {Tooltip, Input} from "antd";
import { DeleteOutlined} from '@ant-design/icons';
import useDataTableMedia from "./DataTable";
// import * as DataUser from '../../utils/Data';
import {useAppSelector} from "../../../../Redux/hooks";
import {setFilterRmboxActions,setOpenModalDel} from "../../store/rmboxActions";
import {useDispatch} from "react-redux";
import {DataRmbox} from "../../utils/Data";
import {useTranslation} from "react-i18next";
import Hooks from "../../utils/hooks";
import '../../rmbox.scss'
import { Button } from "@empreinte/components.button";
import DeleteIcon from '@mui/icons-material/Delete';
import { ModalConfirm } from "@empreinte/components.modal-confirm";
import { SearchBar } from "@empreinte/components.search-bar";

const { Search } = Input;


const RmboxTable = () =>{

    const dispatch = useDispatch()
    const {t} = useTranslation();
    // use Selector redux
    const valuePagination = useAppSelector((state) => state.RmboxReducer.paginationPropsRmbox)
    const {columns} = DataRmbox()
    const data = useAppSelector((state) => state.RmboxReducer.dataRmbox)
    const {getAllData,handleSearchRow, Deletemultipleselecteditem} = Hooks()
    const [openModal,setOpenModal] = useState(false);
    const checkedCase = useAppSelector((state)=>state.RmboxReducer.selectedCase)
    const openDel = useAppSelector((state)=>state.RmboxReducer.showDeleteSpace);
    const numberOfselectedRows = checkedCase.length
    const [searchText,setSearchText]=useState('')
    const [currentPage,setCurrentPage]=useState(1);
    const [pageSize,setPageSize]=useState(10)
    // fontion pour afficher le tableau de n'interface
    const {
        DataTable,
    } = useDataTableMedia({
        columns: columns,
        dataSource: data,
        updateEntityPath: 'update-product',
    });


    const onSearch = (e:any) => {
        setSearchText(e.target.value);
        handleSearchRow(e.target.value)
        getAllData(currentPage,pageSize,e.target.value)
        setCurrentPage(1)
    }

    return(
        <div >

            <div className="Filter">

                {!openDel
                        ?
                        null
                        :
                        <div className="delete_number">
                            <Tooltip title={t('Rmbox.delete')}>
                                <Button style={{border: "1px solid rgba(0 , 0 , 0 , 0.15)"}} 
                                color="inherit" 
                                startIcon={<DeleteIcon/>} 
                                onClick={() =>{
                                    dispatch(setOpenModalDel(true));
                                    setOpenModal(true)
                                }
                                
                                }/>
                            </Tooltip>
                            <p><span>{numberOfselectedRows || ""}</span> <span id={"text_selection"}>{t('Rmbox.selectedItem')}</span></p>
                        </div>
                }
                 {openModal && (    
                    <ModalConfirm
                        open={openModal}
                        onClose={()=>setOpenModal(false)}
                        title={t('Zone.Delete')}
                        content={numberOfselectedRows === 1 ?t('Zone.DeleteConfirmationOneDelete'):t('Zone.DeleteConfirmationMultiDelete')}
                        fullWidth={true}
                        CancelBtnName={t('boitier.No')}
                        ConfirmBtnName={t('boitier.Yes')}
                        onCancel={()=>setOpenModal(false)}
                        onConfirm={() => 
                        {
                            Deletemultipleselecteditem()
                            dispatch(setOpenModalDel(false))
                            setOpenModal(false)
                        }}
                    />)}
                <div className={"div_rect"}>
                    <div className="rect_filter">

                    <SearchBar name={"search"}
                       className="rect_filter_search"
                           placeholder={t('Rmbox.search')}
                            setSearchText={onSearch}
                            value={searchText}
                           />

                    </div>
                </div>
                
            </div>{/*./Filter*/}

            <div className={"dataTable"}>
                <DataTable/>
            </div>
            {/*./dataTable*/}


        </div>
    )
}

export default RmboxTable
