
export const AuthorizedIPList = () => {

    const FilterAuthorizedIP = () => {
        return (
            {
                search: "",
                type: "",
            }
        )
    }

    const paginationPropsAuthorizedIP = () => {
        return (
            {
                order: 'descend',
                pageSize: 10,
                columnKey: 0,
                current: 1,
                id: [],
                idLive: 0,
            }
        )
    }

    const showDivsConditionsAuthorizedIP = () => {
        return ({
            Authorized:false,
            Loading:true,
            loader:false,
            elementSelected: 0,
            addAuthorizedIP: false,
            editAuthorizedIP: false
        })
    }

    const AddAuthorizedIPConstraintsData = () => {
        return (
            {
                id:"",
                ip:"",
                description:"",
            }
        )
    }
    const AuthorizedIPTypes={
        LOADING:"LOADING",
        SET_AuthorizedIP_DATA:"SET_AuthorizedIP_DATA",
    }
    return ({
        paginationPropsAuthorizedIP,
        showDivsConditionsAuthorizedIP,
        AddAuthorizedIPConstraintsData,
        FilterAuthorizedIP,
        AuthorizedIPTypes
    })
}
